import React, { useState, useEffect } from 'react';

function FilePreview (props) {

    let style = {
        width: '100%',
        height: '600px',
        border: 'none'
    };

    return(
        <div>
            {/* <iframe src={"https://docs.google.com/viewer?url=" + props.fileUrl + "&embedded=true"} style={style}></iframe> */}
            <iframe src={"https://docs.google.com/viewer?url=" + props.fileURL + "&embedded=true"} style={style}></iframe>
            {/* <iframe src={`http://view.officeapps.live.com/op/view.aspx?src=${props.fileURL}`} style={style}></iframe> */}
        </div>
    )
}

export default FilePreview;