import React from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import * as moment from 'moment'

class WebCam extends React.Component {
  
    setRef = webcam => {
      this.webcam = webcam;
    };

    capture = async() => {
      //console.log("Capturing!");
      let blob = '';

      try {
        const imageSrc = this.webcam.getScreenshot();
        const base64Str = imageSrc;
        const s = base64Str.split(',');
        blob = this.b64toBlob(s[1]);
      } catch (err) {
        this.props.webcamState(false); // Apaga la cámara
        // console.log(err);
      }

      const config = {
        headers: { 'content-type': 'application/octet-stream' }
      }

      try {
        if(blob) {
          const notification = new Audio('/sounds/camera_sound.mp3');
          notification.play();
          const res = await axios.put('/api/users/'+ this.props.userID + '/faceID?courseID=' + this.props.courseID , blob, config)
          if(res.status === 200){
            this.props.webcamState(false); // Apaga la cámara
          } else {
            console.log(`Error => ${res}`);
            this.props.webcamState(false); // Apaga la cámara
          }
        } else {
          // console.log(`Error => No blob file`);
          this.props.webcamState(false); // Apaga la cámara
        }
      } catch (err) {
          console.log(`Error => ${err}`);
          this.props.webcamState(false); // Apaga la cámara
      }

      // Para guardar las imagenes
      // const form=new FormData()
      // const Presente=moment(new Date()).format('h_mm_ss'); 
      // form.append('image',blob,this.props.userID+"_Hora_"+Presente.toString())
      // const config2 = {
      //   headers: {
      //       'content-type': 'multipart/form-data'
      //   }
      // } 
      // try {
      //   await axios.post('/api/users/'+ this.props.userID + '/subirFoto', form,config2)
      //   // console.log(res2);
      // } catch (err) {
      //   console.log(err);
      // }
      // this.props.webcamState(false);
    }

    b64toBlob = (b64DataStr, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64DataStr);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    componentDidMount(){
     
        setTimeout(
          async function() {
          //  console.log("capturing")
            this.capture();
          }.bind(this),
          10000
        );
      
    }
    
    render() {
        const videoConstraints = {
          width: 720,
          height: 720,
          facingMode: "user"
        };
        return (
          <>
            <Webcam
              audio={false}
              height={720}
              ref={this.setRef}
              screenshotFormat="image/jpeg"
              width={720}
              videoConstraints={videoConstraints}
              style={{position: 'absolute', left: 4000, top:60}}
            />
            {/* <button className='btn btn-info' onClick={this.capture}>Check Current Photo</button> */}
          </>
        );
    }
}

export default WebCam;