import React, { Component,Suspense } from 'react';
import axios from 'axios';
import { Container, Row, Col, Accordion, Card, Button,Spinner } from 'react-bootstrap';
import * as moment from 'moment';
import * as locale from 'moment/locale/es-us'

class InfoCurso extends Component {
    constructor(props){
        super(props)
        this.state = {
            courseInfo: {},
            modalShow: false,
            fecha: '',
            loading: false
        }
    }    

    getCourse = async () => {
        try {
            const res = await axios.get('/api/courses/' + this.props.location.state.courseID);
            this.setState({courseInfo: res.data});
        } catch (err) {
            alert('Ocurrió un error al obtener el curso, favor de intentarlo más tarde');
            console.log(err);
        }
        this.getDate();
    }

    getDate = () => {
        const localLocale = moment(this.state.courseInfo.dateRelease);
        moment.locale('es-us');
        localLocale.locale(false);
        const fecha = localLocale.format('LLLL');
        this.setState({fecha: fecha});
    }

    componentDidMount = async () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        if(this.props.location.state) {
            this.getCourse();
        } else {
            this.props.history.push('/home');
        }
    }

    downloadFile = async () => {
        console.log(this.state.courseInfo.archivoAdjunto)
        try {
            this.setState({ loading: true }, () => {
            axios({
                url: '/api/courses/downloadFile',
                method: 'GET',
                responseType: 'blob', // important
                params: {
                    fileName: this.state.courseInfo.archivoAdjunto
                }
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.classList.add('d-none', 'p-0', 'm-0');
                link.setAttribute('download', this.state.courseInfo.archivoAdjunto);
                document.body.appendChild(link);
                link.click();
                this.setState({ loading: false })                
              });
            })
        } catch (err) {
            alert('Ocurrió un error al descargar el archivo del curso, inténtelo más tarde');
        }
    }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }

    comexcolAmount = (amount) => {
        let result = Math.round((amount + 3.48)/0.95824 * 100) / 100
        if(Number(amount) === 0) {
            return 0;
        } else  {
            return result
        }
    }

    render() {
        let live = <></>
        if(this.state.courseInfo.liveStream){
            live = <>
                    <p>El día: <span className='text-bold'>{this.state.fecha}</span></p>
                    <p>Esperamos contar con su apreciable asistencia.</p>
                </>
        }

        let requisitos = <></>
        if(this.state.courseInfo.liveStream){
            requisitos = <div>
                <p>Requisitos:</p>
                    <ol>
                        <li className="tab small text-bold">Es necesario subir una foto de perfil con el rostro descubierto para la identificación biométrica.</li><br/>
                        <li className="tab small text-bold">Se requiere un dispositivo con acceso a internet y cámara web (Laptop, Tablet o PC con WebCam).</li><br/>
                        <li className="tab small text-bold">Se tomarán 6 fotos a lo largo del curso con la finalidad de comprobar su asistencia.</li><br/>
                        <li className="tab small text-bold">Se requiere estar presente en al menos 4 fotos para acreditar el curso.</li>
                    </ol>
            </div>
        }
        let spin = this.state.loading ? <Spinner animation="border" size="sm" /> : <></>

        let actualFile = <></>
        if(this.state.courseInfo.archivoAdjunto !== '') {
            actualFile = <>
                            <div className='d-flex'>
                                <p className='m-0'>Descargar documentación del curso: <a style={{textDecorationLine: 'underline', cursor: 'pointer', color: 'blue'}} onClick={this.downloadFile}>{this.state.courseInfo.archivoAdjunto}</a></p>
                                {spin}
                            </div></>
        }
        return (    
            <Container style={{marginTop: '60px'}}>
                <button className='btn btn-info' onClick={() => this.props.history.goBack()}> Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i></button>
                <hr></hr>
                <Row>
                    <Col xs={12} md={6}>
                        <img src={`/images/${this.state.courseInfo.image}`} className='img img-fluid' alt='Caratula del curso'></img>
                    </Col>
                    <Col xs={12} md={6}>
                    <div>
                        <p className='text-justify'>Estimados Asociados y público en general, por parte del
                            <span className='text-bold'> Colegio Mexicano de Contadores Públicos y Licenciados en Contaduría, A.C., "COMEXCOL" </span>
                            les hacemos la cordial invitación a nuestro curso
                        </p>
                        <hr></hr>
                        <h4 className='text-bold'>{this.state.courseInfo.title}</h4>

                        <h5>Por el {this.state.courseInfo.professor}</h5>

                        <p>Descripción: {this.state.courseInfo.description}</p>

                        {this.props.location.state ? ((this.props.location.state.userType === 'Asociado' || this.props.location.state.userType === 'Apoyo Mutuo') ? <p className='text-bold'>{this.state.courseInfo.puntosNec} puntos NEC</p> : <></>) : <></>}

                        {live}
                        {requisitos}
                        {this.props.location.state ? <p className='text-bold'>Precio: {`$ ${this.formatMoney(this.comexcolAmount(Number(this.props.location.state.precio)))}`} MXN</p> : <></>}
                        <h5 className='text-success'>Este curso ya es tuyo</h5>
                        <hr></hr>
                        {actualFile}
                        <hr></hr>
                        <Accordion>
                            <Card style={{borderRadius:'10px'}}>
                                <Card.Header style={{marginBottom: '0px'}}>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Temario del curso
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <p>{this.state.courseInfo.temario}</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <hr></hr>
                        <p className='mb-1'>Comentarios:</p>
                        <p>{this.state.courseInfo.comentario}</p>
                        <hr></hr>
                        <p>Mayores informes</p>
                        <p className='text-justify'> 
                            Por medio de un correo electrónico
                            <span className='text-bold'> logistica@comexcol.org.mx </span> y
                            <span className='text-bold'> colegio@comexcol.org.mx; </span>
                            
                            o bien a los números del Colegio.
                            
                            <i>55-55-15-36-68, </i>
                            <i>55-55-16-81-58</i>
                        </p>
                    </div>
                    </Col>
                </Row>
                <br></br>
            </Container>
        );
    }
}

export default InfoCurso;