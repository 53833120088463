import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import {Link} from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap'

import Miniature from './Miniature.js';

class Dashboard extends Component {
      constructor(props){
        super(props)
        this.state = {
          courses: [],
          user:'',
          userCourses: [],
          userCompleteCourses: [],
          status: true,
          success: false,
        }
  }
  
  getCourses = async () => {
    try {
      const resp = await axios.get(`/api/users/${this.props.auth.user.id}/status`)
      if(resp.status === 200){
        this.setState({status: resp.data.status});
        if(resp.data.status){
          const res = await axios.get('/api/courses');
          if(res.status === 200) {
            let enableCourses = [];
            res.data.forEach(course => {
                if(course.status) { 
                  if(course.exclusivo === 'Si' && this.props.auth.user.userType.type === 'Asociado') {
                    enableCourses.push(course);
                  } else if (course.exclusivo === 'No') {
                    enableCourses.push(course);
                  }
                }
            })
            this.setState({courses: enableCourses, success: true});
            }
        } else { this.setState({success: true}); }
      }
      
    } catch (err) {
      alert('Ocurrió un error al obtener los cursos, favor de intentarlo más tarde');
      console.log(err);
    }
  }

  updateFaceID = async() => {
    try {
      await axios.post(`/api/users/${this.props.auth.user.id}/faceID`);
    } catch (err) {
      console.log(err);
    }
  }

  getUserCourses = async (id) => {
    try {
      const res = await axios.get('/api/users/'+id);
      this.setState({userCourses:res.data.courses, userCompleteCourses: res.data.completeCourses});
    } catch (err) {
      alert('Ocurrió un error al obtener los cursos del usuario, favor de intentarlo más tarde');
      console.log(err);
    }
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  async componentDidMount(){
    this.getCourses();
    this.setState({user:this.props.auth.user});
    this.getUserCourses(this.props.auth.user.id);
    this.updateFaceID();

  }

  render() {
    let btnAdmin;
    if(this.state.user.rol === 'Admin'){
      btnAdmin = <div>
        <div className='d-flex'>
          <Link className="btn btn-comp" to="/admin" > Panel de Administrador <i className='fas fa-user-shield'></i> </Link> &nbsp;&nbsp;
          <Link className="btn btn-sec" to="/professorChat" > Panel de expositor <i className='fas fa-chalkboard-teacher'></i> </Link>
      </div>
      <hr></hr>
      </div>
    }

    if(this.state.user.rol === 'Professor'){
      btnAdmin = <div>
        <Link className="btn btn-sec" to="/professorChat" > Panel de expositor <i className='fas fa-chalkboard-teacher'></i> </Link>
        <hr></hr>
      </div>
    }

    document.body.style.backgroundColor = "rgba(247, 247, 255)";

    let content = <>
      {this.state.courses.slice(0).reverse().map(course => (
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 p-4 p-sm-2" key={course._id}>
                  <Miniature
                    course={course}
                    user={this.state.user}
                    duration={course.duration}
                    replays={this.state.userCourses.find(x => x.id === course._id && x.replays >= 100)}
                    bought={this.state.userCourses.find(x => x.id===course._id)}
                    completed={this.state.userCourses.find(x => x.id===course._id && x.completed)}
                  ></Miniature>
                </div>
              ))}
    </>

    if(!this.state.status) {
      content = <>
        <p className='h4 text-danger'>Su cuenta está temporalmente suspendida o está sujeta a validación, contacte a un administrador para obtener más información.</p>
        <p className='h5'> 
                            Por medio de un correo electrónico
                            <span className='text-bold'> logistica@comexcol.org.mx </span> y
                            <span className='text-bold'> colegio@comexcol.org.mx; </span>
                            
                            o bien a los números del Colegio.
                            
                            <i>55-55-15-36-68, </i>
                            <i>55-55-16-81-58</i>
                        </p>
      </>
    }
    let loadIco = this.state.success ? <></> : <Spinner animation="grow" variant='prim' />
    return (
      <div className="container" style={{ marginTop: "60px" }}>
            {loadIco}
            {btnAdmin}
            <div className='row'>
              {content}
            </div>
            <br></br>
      </div>
    )
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Dashboard);
