import React, { Component } from 'react';
import axios from 'axios';
import Paywrap from '../Payment/Paywrap';
import { Container, Row, Col, Button, Accordion, Card, Modal, Spinner } from 'react-bootstrap';
import * as moment from 'moment';
import * as locale from 'moment/locale/es-us'

class CompraCurso extends Component {
    constructor(props){
        super(props)
        this.state = {
            courseInfo: {},
            modalShow: false,
            fecha: '',
            freeCourses: 0,
            showModalFree: false,
            freeCourseMessage: false,
            diff2: false,
            zeroCourse: false,
            zeroCourseTitle: '',
            cursoGratuito: false,
            clickComprar: false,
        }
    }    

    getCourse = async () => {
        try {
            const res = await axios.get('/api/courses/' + this.props.location.state.courseID);
            if(res.status === 200) {
                this.setState({courseInfo: res.data});
                this.setState({cursoGratuito: res.data.cursoGratuito});
            }
        } catch (err) {
            alert('Ocurrió un error al obtener el curso, favor de intentarlo más tarde');
            console.log(err);
        }
        this.getDate();
        this.getDiff2();
    }

    getFreeCourses = async () => {
        try {
            const res = await axios.get('/api/users/' + this.props.location.state.userID + '/freeCourse');
            this.setState({freeCourses: res.data.freeCourses});
        } catch (err) {
            alert('Ocurrió un error al obtener el curso, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    getDate = async () => {
        const localLocale = moment(this.state.courseInfo.dateRelease);
        moment.locale('es-us');
        localLocale.locale(false);
        const fecha = localLocale.format('LLLL');
        this.setState({fecha: fecha});
    }

    getDiff2 = () => {
        const now = moment(Date.now());
        const localLocale = moment(this.state.courseInfo.dateRelease).add(this.state.courseInfo.duration/1000,'seconds');
        const diff2 = localLocale.diff(now);
        if(diff2 <= -1000) {
          this.setState({diff2: true});
        } 
    }

    getZeroCourse = async() => {
        try {
            const res = await axios.post(`/api/users/${this.props.location.state.userID}/zeroPrice`, {'courseID': this.props.location.state.courseID})
            if(res.status === 200) {
                console.log(res.data.pass);
                this.setState({zeroCourse: res.data.pass, zeroCourseTitle: res.data.course});
            }
        } catch (err) {
            console.log(err);
        }
    } 

    componentDidMount = async () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        if(this.props.location.state) {
            this.getCourse();
            if(Number(this.props.location.state.precio) === 0) {
                this.getZeroCourse();
            }
            if(this.props.location.state.userType === 'Asociado') {
                this.getFreeCourses();
            }
        } else {
            this.props.history.push("/home");
        }
    }

    handleFreeCourse =  async () => {
        this.setState({clickComprar: true});
        try {
            const res = await axios.post(`/api/users/${this.props.location.state.userID}`, {'userID': this.props.location.state.userID, 'courseID': this.props.location.state.courseID, 'freeCourse': true});
            if(res.status === 200) {
                const re = await axios.post('/api/payment/buyNewCourse', {'userID': this.props.location.state.userID, 'courseID': this.props.location.state.courseID, 'freeCourse': true, 'precio': this.props.location.state.precio, rfc: '...', 'userType': this.props.location.state.userType, 'formaDePago': 'No Aplica'});
                if(re.status === 200) {
                    if(!this.state.zeroCourse) {
                        const r = await axios.put(`/api/users/${this.props.location.state.userID}/freeCourse`);
                        if(r.status === 200){
                            this.setState({freeCourseMessage: true});
                            setTimeout(() => {
                                window.location.href='/home';
                            }, 2000);
                        }
                    } else {
                        this.setState({freeCourseMessage: true});
                            setTimeout(() => {
                                window.location.href='/home';
                            }, 2000);
                    }
                }
            }
        } catch (err) {
            alert('Ocurrió un error, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    handleCursoGratuito =  async () => {
        this.setState({clickComprar: true});
        try {
            const res = await axios.post(`/api/users/${this.props.location.state.userID}`, {'userID': this.props.location.state.userID, 'courseID': this.props.location.state.courseID, 'freeCourse': true});
            if(res.status === 200) {
                const re = await axios.post('/api/payment/buyNewCourse', {'userID': this.props.location.state.userID, 'courseID': this.props.location.state.courseID, 'freeCourse': true, 'precio': this.props.location.state.precio, rfc: '...', 'userType': this.props.location.state.userType, 'formaDePago': 'No Aplica'})
                if(re.status === 200) {
                    this.setState({freeCourseMessage: true});
                        setTimeout(() => {
                            window.location.href='/home';
                        }, 2000);
                }
            }
        } catch (err) {
            alert('Ocurrió un error, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }

    comexcolAmount = (amount) => {
        let result = Math.round((amount + 3.48)/0.95824 * 100) / 100
        if(Number(amount) === 0) {
            return 0;
        } else  {
            return result
        }
    }

    render() {
        let info;
        if(this.props.location.state) {
            info = {
                courseID: this.props.location.state.courseID,
                userID: this.props.location.state.userID,
                costo: `$ ${this.formatMoney(this.comexcolAmount(Number(this.props.location.state.precio)))}`,
                userType: this.props.location.state.userType
            };
        }

        let live = <></>
        if(this.state.courseInfo.liveStream){
            live = <>
                    <p>El día: <span className='text-bold'>{this.state.fecha}</span></p>
                    <p>Esperamos contar con su apreciable asistencia.</p>
                </>
        }

        let requisitos = <></>
        if(this.state.courseInfo.liveStream){
            requisitos = <div>
                <p>Requisitos:</p>
                    <ol>
                        <li className="tab small text-bold">Es necesario subir una foto de perfil reciente con el rostro descubierto para la identificación biométrica.</li><br/>
                        <li className="tab small text-bold">Se requiere un dispositivo con acceso a internet y cámara web (Laptop, Tablet o PC con WebCam).</li><br/>
                        <li className="tab small text-bold">Se tomarán 6 fotos a lo largo del curso con la finalidad de comprobar su asistencia.</li><br/>
                        <li className="tab small text-bold">Se requiere estar presente en al menos 4 fotos para acreditar el curso.</li>
                    </ol>
            </div>
        }

        let buyButton = <Button className='px-3' variant='comp' onClick={() => this.setState({modalShow: true})}>Comprar</Button>
        if(this.props.location.state) {
            if(!this.state.zeroCourse && Number(this.props.location.state.precio) === 0 && !this.state.cursoGratuito) {
                buyButton =     <div>
                                    <h4 className='text-bold text-danger'>No puedes comprar este curso</h4>
                                    <h5 className='text-bold text-success'>Debes comprar la sesión 1 primero</h5>
                                </div>
            }else if(this.state.courseInfo.liveStream && this.props.location.state.roomSize >= 150) {
                buyButton =     <div>
                                    <h4 className='text-bold text-danger'>El curso ha superado el cupo límite de asistentes</h4>
                                    <h5 className='text-bold text-success'>Muy pronto estará disponible para compra en video</h5>
                                </div>
            } else if(this.state.courseInfo.liveStream && this.state.diff2) {
                buyButton = <div>
                                <h4 className='text-bold text-danger'>El curso en vivo ha finalizado, no es posible comprarlo en este momento</h4>
                                <h5 className='text-bold text-success'>Muy pronto estará disponible para compra en video</h5>
                            </div>
            } else if(this.props.location.state.userType === 'Asociado' && this.state.freeCourses > 0 && Number(this.props.location.state.precio) !== 0) {
                buyButton = <Button className='px-3' variant='comp' onClick={() => this.setState({showModalFree: true})}>Añadir a mis cursos</Button>
            } else if(this.state.cursoGratuito) {
                buyButton = <Button className='px-3' variant='comp' onClick={() => this.setState({showModalFree: true})}>Añadir a mis cursos</Button>
            } else if(this.state.zeroCourse) {
                buyButton = <Button className='px-3' variant='comp' onClick={() => this.setState({showModalFree: true})}>Añadir a mis cursos</Button>
            } 
        }

        let freeMsg =   <Row className='d-flex justify-content-center'>
                            <Col sm='12' className='d-flex justify-content-center'>
                                <p className='text-center'>
                                    Como asociado al <b>COLEGIO MEXICANO DE CONTADORES PUBLICOS Y LICENCIADOS EN CONTADURIA, A.C. </b> 
                                    aún tiene <b> {this.state.freeCourses} </b> {this.state.freeCourses === 1 ? 'curso' : 'cursos'} gratis, ¿Desea agregar este curso a la lista?
                                </p>
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                {this.state.clickComprar ? 
                                    <Button onClick={this.handleFreeCourse} variant='comp' disabled>Agregar Curso <i className='fas fa-plus-circle'></i> &nbsp; <Spinner animation="border" size="sm" /></Button> :
                                    <Button onClick={this.handleFreeCourse} variant='comp'>Agregar Curso <i className='fas fa-plus-circle'></i></Button>}
                            </Col>
                        </Row>
        if(this.state.cursoGratuito) {
            freeMsg =   <Row className='d-flex justify-content-center'>
                            <Col sm='12' className='d-flex justify-content-center'>
                                <p className='text-center'>
                                    Este curso es gratuito cortesía de COMEXCOL <br></br>¿Desea agregar el curso a la lista?
                                </p>
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                {this.state.clickComprar ? 
                                    <Button onClick={this.handleCursoGratuito} variant='comp' disabled>Agregar Curso <i className='fas fa-plus-circle'></i> &nbsp; <Spinner animation="border" size="sm" /></Button> :
                                    <Button onClick={this.handleCursoGratuito} variant='comp'>Agregar Curso <i className='fas fa-plus-circle'></i></Button>}
                            </Col>
                        </Row>
        } else if(this.state.zeroCourse) {
            freeMsg =   <Row className='d-flex justify-content-center'>
                            <Col sm='12' className='d-flex justify-content-center'>
                                <p className='text-center'>
                                    Este curso es continuación de: <b>{this.state.zeroCourseTitle}</b> el cuál ya has comprado, ¿Desea agregar este curso a la lista?
                                </p>
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                {this.state.clickComprar ? 
                                    <Button onClick={this.handleFreeCourse} variant='comp' disabled>Agregar Curso <i className='fas fa-plus-circle'></i> &nbsp; <Spinner animation="border" size="sm" /></Button> :
                                    <Button onClick={this.handleFreeCourse} variant='comp'>Agregar Curso <i className='fas fa-plus-circle'></i></Button>}
                            </Col>
                        </Row>
        } 
        if(this.state.freeCourseMessage) {
            freeMsg =   <Row className='d-flex justify-content-center text-center'>
                            <Col sm='12' className='text-center'>
                                <div className="Result">
                                    <div className="ResultTitle color-comp-light my-4" role="alert">
                                        <h3 className='text-bold'>¡Curso agregado exitosamente!</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>
        }

        return (  
            <>  
            {this.props.location.state ? <Container style={{marginTop: '60px'}}>
                <button className='btn btn-info' onClick={() => this.props.history.goBack()}> Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i></button>
                <hr></hr>
                <Row>
                    <Col xs={12} md={6}>
                        <img src={`/images/${this.state.courseInfo.image}`} className='img img-fluid' alt='Caratula del curso'></img>
                    </Col>
                    <Col xs={12} md={6}>
                    <div>
                        <p className='text-justify'>Estimados Asociados y público en general, por parte del
                            <span className='text-bold'> Colegio Mexicano de Contadores Públicos y Licenciados en Contaduría, A.C., "COMEXCOL" </span>
                            les hacemos la cordial invitación a nuestro curso
                        </p>
                        <hr></hr>
                        <h4 className='text-bold'>{this.state.courseInfo.title}</h4>

                        <h5>Por el {this.state.courseInfo.professor}</h5>

                        <p>Descripción: {this.state.courseInfo.description}</p>

                        {(this.props.location.state.userType === 'Asociado' || this.props.location.state.userType === 'Apoyo Mutuo') ? <p className='text-bold'>{this.state.courseInfo.puntosNec} puntos NEC</p> : <></>}

                        {live}
                        {requisitos}
                        {this.state.cursoGratuito ? <p className='text-bold text-success'>{this.state.courseInfo.etiqueta}</p> : <p className='text-bold'>Precio: {info.costo} MXN</p>}
                        {buyButton}
                        <hr></hr>
                        <Accordion>
                            <Card style={{borderRadius:'10px'}}>
                                <Card.Header style={{marginBottom: '0px'}}>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Temario del curso
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <p>{this.state.courseInfo.temario}</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <hr></hr>
                        <p className='mb-1'>Comentarios:</p>
                        <p>{this.state.courseInfo.comentario}</p>
                        <hr></hr>
                        <p>Mayores informes</p>
                        <p className='text-justify'> 
                            Por medio de un correo electrónico
                            <span className='text-bold'> logistica@comexcol.org.mx </span> y
                            <span className='text-bold'> colegio@comexcol.org.mx; </span>
                            
                            o bien a los números del Colegio.
                            
                            <i>55-55-15-36-68, </i>
                            <i>55-55-16-81-58</i>
                        </p>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Paywrap 
                            info={info}
                            curso={this.state.courseInfo.title} 
                            show={this.state.modalShow} 
                            onHide={() => this.setState({modalShow: false})}/>
                    </Col>
                </Row>
                <Modal
                    show={this.state.showModalFree}
                    onHide={() => this.setState({showModalFree: false})}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onExit={() => this.setState({btn: false})}
                    style={{padding: '0px 0px 0px 0px'}}
                    >
                    <Modal.Header closeButton style={{background: '#263238'}}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4 className='text-bold px-1' style={{color: '#f7f7ff'}}>Comprar curso - COMEXCOL</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0' style={{background: '#37474F'}}>
                        <div className='p-2' style={{borderRadius:' 8px', padding: '8px', color: '#f7f7ff'}}>
                                <Row className='d-flex justify-content-center'>
                                    <Col className='d-flex align-items-center text-center' xs={6} lg={4}>
                                        <img alt='comexcol-curso' className='img img-fluid' src='/images/comexcol-curso-template.svg' ></img>
                                    </Col>
                                    <Col xs={12} lg={8} className='d-flex align-items-center justify-content-center'>
                                        {freeMsg}
                                    </Col>
                                </Row>
                            </div>
                    </Modal.Body>
                    <Modal.Footer style={{background: '#263238'}}>
                        <Button className='px-3' variant='err' onClick={() => this.setState({showModalFree: false})}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
                <br></br>
            </Container> : <></>}
            </>
        );
    }
}

export default CompraCurso;