import React, { Component } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { Modal, Toast, Spinner } from 'react-bootstrap';
import Pagination from 'rc-pagination/es';
import 'rc-pagination/assets/index.css';
import * as pagLocale from 'rc-pagination/es/locale/es_ES';
import * as moment from 'moment'
import locale from 'moment/locale/es-us';

class listaCursos extends Component {
    state={
        courses:[],
        newCourse:{},
        userName:'',
        courseUpdate:'',
        editing:false,
        show: false,
        currentCourse: '',
        toastShow:'',
        success: false,
        index: 1,
        slice: 0,
        details: false,
    }

    async componentDidMount(){
        this.getCourses();
    }
    
     getCourses =async () => {
        try {
            const res = await axios.get('/api/courses');
            if(res.status === 200) {
                this.setState({courses:res.data.slice(0).reverse(), success: true});
            }
        } catch (err) {
            alert('Ocurrió un error al obtener los cursos, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    // deleteCourse= async () => { 
    //     try {
    //         await axios.delete('/api/courses/'+ this.state.currentCourse._id);
    //         this.setState({toastShow: true, show: false})
    //     } catch (err) {
    //         alert('Ocurrio un error al eliminar el curso, favor de intentarlo mas tarde');
    //         console.log(err);
    //     }
        
    //     this.getCourses();
    // }

    disableCourse= async () => { 
        try {
            const res = await axios.post(`/api/courses/${this.state.currentCourse._id}/status`, {'status': !this.state.currentCourse.status});
            if(res.status === 200) {
                this.getCourses();
                this.setState({toastShow: true, show: false})
            }
            
        } catch (err) {
            alert('Ocurrió un error al deshabilitar el curso, favor de intentarlo más tarde');
            console.log(err);
        }
        
        this.getCourses();
    }

    handleChangePage = (page) => {
        this.setState({index: page});
        this.setState({slice: page*10 - 10});
    }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            alert('Los precios deben contener sólo valores numéricos, sin letras ni símbolos');
            console.log(e)
        }
    }

    comexcolAmount = (amount) => {
        const result = Math.round((amount + 3.48)/0.95824 * 100) / 100
        return result
    }

    render() {
        let modalBody =     <>
                                <Modal.Header>
                                    <Modal.Title>Deshabilitar curso</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Realmente quiere deshabilitar el curso: <br></br> 
                                    <span className='text-bold' style={{fontSize: '1.2em'}}>{this.state.currentCourse.title}</span> 
                                    <br></br><br></br>
                                    Podrá habilitarlo más tarde
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='btn btn-sec' onClick={() => this.setState({show: false})}>
                                        Cancelar
                                    </button>
                                    <button className='btn btn-err' onClick={this.disableCourse}>
                                        Deshabilitar
                                    </button>
                                </Modal.Footer>
                            </> 
        if(!this.state.currentCourse.status) {
            modalBody =     <>
                                <Modal.Header>
                                    <Modal.Title>Habilitar curso</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Realmente quiere habilitar el curso: <br></br> 
                                    <span className='text-bold' style={{fontSize: '1.2em'}}>{this.state.currentCourse.title}</span> 
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='btn btn-sec' onClick={() => this.setState({show: false})}>
                                        Cancelar
                                    </button>
                                    <button className='btn btn-success' onClick={this.disableCourse}>
                                        Habilitar
                                    </button>
                                </Modal.Footer>
                            </> 
        }
        let detailsBody = <></>
        if(this.state.currentCourse) {
            detailsBody =     <>
                                <Modal.Header>
                                    <Modal.Title>Detalles del curso</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <span className='text-bold' style={{fontSize: '1.2em'}}>{this.state.currentCourse.title}</span> 
                                    <ul>
                                        <li>Título: <span className='text-bold'>{this.state.currentCourse.title}</span></li>
                                        <li>Descripción: <span className='text-bold'>{this.state.currentCourse.description}</span></li>
                                        <li>Impartido por: <span className='text-bold'>{this.state.currentCourse.professor}</span></li>
                                        <li>Puntos NEC: <span className='text-bold'>{this.state.currentCourse.puntosNec}</span></li>
                                        <li>Usuarios inscritos: <span className='text-bold'>{this.state.currentCourse.roomSize}</span></li>
                                        <li>Comentario: <span className='text-bold'>{this.state.currentCourse.comentario}</span></li>
                                        <li>Temario: <span className='text-bold'>{this.state.currentCourse.temario}</span></li>
                                        <li>Costo: </li>
                                        <ul>
                                            <li>
                                                Asociados: <span className='text-bold'> {`$ ${this.formatMoney(this.state.currentCourse.costo.asociados)} MXN `} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.state.currentCourse.costo.asociados))))} MXN`})</span>
                                            </li>
                                            <li>
                                                Apoyo Mutuo: <span className='text-bold'>{`$ ${this.formatMoney(this.state.currentCourse.costo.apoyoMutuo)} MXN`} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.state.currentCourse.costo.apoyoMutuo))))} MXN`})</span>
                                            </li>
                                            <li>
                                                Estudiantes: <span className='text-bold'>{`$ ${this.formatMoney(this.state.currentCourse.costo.estudiantes)} MXN`} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.state.currentCourse.costo.estudiantes))))} MXN`})</span>
                                            </li>
                                            <li>
                                                Público en General: <span className='text-bold'>{`$ ${this.formatMoney(this.state.currentCourse.costo.publicoEnGeneral)} MXN`} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.state.currentCourse.costo.publicoEnGeneral))))} MXN`})</span>
                                            </li>
                                        </ul>
                                        <li>En vivo?: <span className='text-bold'>{this.state.currentCourse.liveStream ? 'Curso en vivo' : 'No es en vivo'}</span></li>
                                        {this.state.currentCourse.liveStream ? <li>Fecha de transmisión: <span className='text-bold'>{moment(this.state.currentCourse.dateRelease).format('LLLL')}</span></li> : <></>}
                                        <li>URL del vídeo: <span className='text-bold'> {this.state.currentCourse.videoURL}</span></li>
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='btn btn-sec' onClick={() => this.setState({details: false})}>
                                        Cerrar
                                    </button>
                                </Modal.Footer>
                            </> 
        } 
        let loadIco = this.state.success ? <></> : <Spinner animation="grow" variant='prim' />
        return (
            <div className="container px-0">
                <h4 className='text-bold mt-2 pb-2'>Lista de cursos {loadIco}</h4>
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={this.state.courses.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={this.handleChangePage}
                    defaultCurrent={1}
                    current={this.state.index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                <div className="list-group">
                    {
                        this.state.courses.slice(this.state.slice, this.state.slice + 10).map(course =>(
                            <li 
                            style={{background: !course.status ? '#f8523e55' : ''}}
                            className="list-group-item list-group-item-action" 
                            key={course._id}
                            onDoubleClick={()=> this.setState({courseUpdate:course,editing:true})}
                            >
                                <div className="row">
                                    <div className="col-md-6 col-lg-5">
                                        <h5 className='text-bold' style={{color: '#000'}}>{course.title}</h5>
                                        <h6 style={{color: 'black'}}>{course.status ? '' : 'Curso deshabilitado'}</h6>
                                    </div>
                                    <div className="col-md-6 col-lg-7 d-flex align-items-center justify-content-end">
                                        <div className='d-flex text-center' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                            <button className="btn btn-prim mx-1 my-2"
                                                onClick={() => this.setState({details: true, currentCourse: course})}
                                            > Detalles &nbsp;<i className="fas fa-receipt"></i></button>
                                            <Link className="btn btn-comp mx-1 my-2"
                                                to={{
                                                    pathname: '/admin/crearCursos',
                                                    state: 
                                                        {courseUpdate:course,
                                                        editing:true}
                                                    }}
                                            > Editar &nbsp;<i className="fas fa-pen"></i></Link>
                                            <Link className="btn btn-sec mx-1 my-2"
                                                to={{
                                                    pathname: '/admin/listaAlumnos',
                                                    state: 
                                                        {course:course}
                                                    }}
                                            > Inscritos &nbsp;<i className="fas fa-list-ul"></i></Link>
                                            <button className='btn btn-secondary mx-1 my-2'
                                                onClick={() => this.setState({show: true, currentCourse: course})}>
                                                {course.status ? 'Deshabilitar' : 'Habilitar'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>))
                    }
                </div>
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={this.state.courses.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={this.handleChangePage}
                    defaultCurrent={1}
                    current={this.state.index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                <Modal show={this.state.show} onHide={() => this.setState({show: false})}>
                    {modalBody}
                </Modal>
                <Modal centered size="lg" show={this.state.details} onHide={() => this.setState({details: false})}>
                    {detailsBody}
                </Modal>
                <Toast  onClose={() => this.setState({toastShow: false})} 
                    show={this.state.toastShow} 
                    animation={true}
                    delay={3000} 
                    style={{
                        position: 'absolute',
                        top: '80px',
                        left: '40%',
                        zIndex: 100
                    }}
                    autohide>
                <Toast.Header>
                    <strong className="mr-auto">Administrador</strong>
                    <small>Ahora</small>
                    </Toast.Header>
                    <Toast.Body>
                        <span className='text-info'>&nbsp;<i className="fas fa-exclamation-circle"></i></span> 
                        &nbsp;El curso ha sido {!this.state.currentCourse.status ? 'habilitado' : 'deshabilitado'} &nbsp;&nbsp;</Toast.Body>
                </Toast>
            </div>
        );
    }
}

export default listaCursos;