import React, { Component } from 'react';
import { Container, Form, Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from 'axios';

class ResetPass extends Component {
    constructor(props){
        super(props)
        this.state = {
            email: '',
            noRegistro: '',
            emailSent: false,
            message:'Revisa tu bandeja de entrada y sigue las instrucciones'
        }
    }

    onChange = e => {
        this.setState({[e.target.id]: e.target.value});
    }

    onSubmit = async (e) => {
        e.preventDefault();

        await axios.post('/api/users/resetPass', {email: this.state.email, noRegistro: this.state.noRegistro})
            .then(res => {
                if(res.status === 200){
                    this.setState({emailSent: true});
                }
            }).catch(err => {
                this.setState({emailSent: true, message: err.response.data.err});
            })
      };

    render() {
        let content = <></>;
        if(this.state.emailSent){
            content =   <>
                            <h4 className='text-bold'>...</h4>
                            <h5>{this.state.message}</h5>
                        </>
        } else {
            content =   <>
                            <h4 className='text-bold'>¿Olvidaste tu contraseña?</h4>
                            <h5>Te enviaremos un enlace a tu correo para que puedas cambiar la contraseña</h5>
                            <hr></hr>
                            <Form onSubmit={this.onSubmit}>
                                <Form.Row>
                                    <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
                                        <Form.Label>Correo electrónico</Form.Label>
                                        <Form.Control
                                            type="email" 
                                            placeholder="ejemplo@gmail.com"
                                            onChange={this.onChange}
                                            value={this.state.email}
                                            id="email"
                                            required 
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="comp" type="submit">
                                    Cambiar mi contraseña &nbsp; <i className="fas fa-lock"></i>
                                </Button>
                            </Form>
            
                        </>
        }
        return(
            <Container style={{marginTop: '60px'}}>
                <Link to="/login">
                    <p><i className="fas fa-arrow-left"></i>  Regresar</p>
                </Link>
                {content}
            </Container>
        )
    }
}

export default ResetPass;