import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

import { Container, Form, Button, Row, Col } from 'react-bootstrap';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}, 
      show: ''
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/home");
    }
    this.setState({show: false});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/home");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;
    var emailerr = '';
    var passerr = ''
    if(errors.email || errors.password || errors.emailnotfound || errors.passwordincorrect){
      emailerr = errors.email ? 'is-invalid' : errors.emailnotfound ? 'is-invalid' : '';
      passerr = errors.password ? 'is-invalid' : errors.passwordincorrect ? 'is-invalid' : '';
    }
    document.body.style.backgroundColor = "rgba(247, 247, 255)";
    return (

      <Container style={{marginTop: '60px'}}>
        <Row xl={1}>
          <Col xl={12} className='d-flex'>
            <Link to="/">
               <p><i className="fas fa-arrow-left"></i>  Regresar</p>
            </Link>
          </Col>
          <Col xl={12}>
            <h3 style={{color: '#0f006b', fontWeight: '700'}} className='mb-3'>
              Iniciar Sesión
            </h3>
          </Col>
          <Col xl={12} className='d-flex'>
            <p>
              ¿No tienes una cuenta? <Link to="/register"> Regístrate aquí</Link>
            </p>
          </Col>
        </Row>
        <Form noValidate onSubmit={this.onSubmit}>
          <Form.Row>
            <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
              <Form.Label className='text-bold'>Correo Electrónico</Form.Label>
              <Form.Control
                type="email" 
                placeholder="ejemplo@gmail.com"
                onChange={this.onChange}
                value={this.state.email}
                error={errors.email || errors.emailnotfound}
                id="email"
                required 
                className={emailerr} 
              />
              <Form.Control.Feedback type={'invalid'}>{errors.email}
                {errors.emailnotfound}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
              <Form.Label className='text-bold'>Contraseña</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="******" 
                onChange={this.onChange}
                value={this.state.password}
                error={errors.password || errors.passwordincorrect}
                id="password"
                required 
                className={passerr} 
              />
              <Form.Control.Feedback type={'invalid'}>{errors.password}
                {errors.passwordincorrect}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <div className='d-flex'>
            <Link to="/resetPassword">
              <p>¿Olvidaste tu contraseña?  <i className="fas fa-lock"></i></p>
            </Link>
          </div>
          <Button variant="comp" type="submit">
            Iniciar Sesión &nbsp; <i className="fas fa-sign-in-alt"></i>
          </Button>
        </Form>
        <br></br>
      </Container>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
