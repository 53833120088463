import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import NavBar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Footer from './components/layout/Footer';
import AvisoDePrivacidad from './components/layout/AvisoDePrivacidad';
import TerminosYCondiciones from './components/layout/TerminosYCondiciones';

import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Admin from "./components/Admin/index";
import ResetPass from './components/auth/ResetPass';
import newPass from './components/auth/newPass';
import Verification from './components/auth/Verification';

import Course from "./components/course/course";
import CompraCurso from "./components/dashboard/CompraCurso";
import InfoCurso from "./components/dashboard/InfoCurso";
// import UserProfile from "./components/dashboard/UserProfile";
import UserProfile from "./components/dashboard/UserProfilev2";
import "./App.scss";
import crearCursos from "./components/Admin/crearCursos";
import ListaAlumnos from "./components/Admin/listaAlumnos";
import AdminChat from "./components/Admin/AdminChat";
import ProfessorChat from "./components/Admin/ProfessorChat";
import AdminCursos from "./components/Admin/AdminCursos";
import AdminUsuarios from "./components/Admin/AdminUsuarios";
import UserInfo from "./components/Admin/UserInfo";
import Professor from "./components/Admin/Professor";
import Resumen from "./components/Admin/resumen/Resumen";
import Estadistica from "./components/Admin/Estadistica";

//axios-progressbar
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
loadProgressBar();
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}

// console.log("%c██╗  ██╗███████╗██╗██████╗  █████╗ ███████╗████████╗███████╗ ██████╗██╗  ██╗\n" + 
//             "██║ ██╔╝██╔════╝██║██╔══██╗██╔══██╗██╔════╝╚══██╔══╝██╔════╝██╔════╝██║  ██║\n" +
//             "█████╔╝ █████╗  ██║██║  ██║███████║███████╗   ██║   █████╗  ██║     ███████║\n" +
//             "██╔═██╗ ██╔══╝  ██║██║  ██║██╔══██║╚════██║   ██║   ██╔══╝  ██║     ██╔══██║\n" +
//             "██║  ██╗███████╗██║██████╔╝██║  ██║███████║   ██║   ███████╗╚██████╗██║  ██║\n" +
//             "╚═╝  ╚═╝╚══════╝╚═╝╚═════╝ ╚═╝  ╚═╝╚══════╝   ╚═╝   ╚══════╝ ╚═════╝╚═╝  ╚═╝\n https://keidastechnology.com", "color: black")

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <div className='content'>
              <NavBar />
              <Route exact path="/" component={Landing} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/avisoDePrivacidad" component={AvisoDePrivacidad} />
              <Route exact path="/terminosycondiciones" component={TerminosYCondiciones} />
              <Route exact path='/resetPassword' component={ResetPass} />
              <Route exact path='/newPassword' component={newPass} />
              {/* <Route exact path='/emailVerification' component={Verification} /> */}
              
              <Switch>
                <PrivateRoute exact path="/admin" component={Admin} />
                <PrivateRoute exact path="/admin/crearCursos" component={crearCursos} />
                <PrivateRoute exact path="/admin/listaAlumnos" component={ListaAlumnos} />
                <PrivateRoute exact path="/home" component={Dashboard} />
                <PrivateRoute exact path="/course" component={Course} />
                <PrivateRoute exact path="/compraCurso" component={CompraCurso} />
                <PrivateRoute exact path="/infoCurso" component={InfoCurso} />
                <PrivateRoute exact path="/perfilUsuario" component={UserProfile} />
                <PrivateRoute exact path="/admin/chat" component={AdminChat} />
                <PrivateRoute exact path="/professorChat" component={ProfessorChat} />
                <PrivateRoute exact path="/admin/cursos" component={AdminCursos} />
                <PrivateRoute exact path="/admin/usuarios" component={AdminUsuarios} />
                <PrivateRoute exact path="/admin/usuarios/detalles" component={UserInfo} />
                <PrivateRoute exact path="/admin/resumen" component={Resumen} />
                <PrivateRoute exact path="/admin/profesor" component={Professor} />
                <PrivateRoute exact path="/admin/estadisticas" component={Estadistica} />
              </Switch>
            </div>
              <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
