import React from 'react';
import { Link } from 'react-router-dom';

export default function TerminosYCondiciones() {
    return(
        <div className='container' style={{marginTop: '70px'}}>
            <Link to='/'><button className='btn btn-comp'>Inicio <i className='fas fa-home'></i></button></Link>
            <h2 className='color-prim text-bold text-center my-3'>Términos y Condiciones</h2>
            <hr></hr>
            <div className='mx-xl-5 mx-md-3 mx-sm-0' style={{backgroundImage: 'url(./images/comexcol-complete.svg)', backgroundSize: 'contain'}}>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 mb-0' style={{background: '#f7f7ffdd'}}>
                    <b>TERMINOS Y CONDICIONES.</b><br></br>
                    Estos términos y condiciones crean un contrato entre usted y COMEXCOL (el "Acuerdo"). 
                    <br></br>
                    Lea el Acuerdo detenidamente. 
                    Al registrarse en esta página confirma que entiende y acepta el Acuerdo.
                    <br></br>
                    A. INTRODUCCIÓN A NUESTROS SERVICIOS
                    Este Acuerdo establece el adecuado  uso de los servicios de la plataforma COMEXCOL, mediante los cuales puede comprar, 
                    visualizar, y tomar capacitación de acuerdo con nuestras capacitaciones ofertadas (por al menos 2 ocasiones) las cuales 
                    serán 1 en vivo y 1 más cuando el curso haya finalizado, tendrán acceso al contenido proporcionado por los expositores 
                    y otros servicios. El Contenido puede visualizarse a través de los Servicios ofrecidos por COMEXCOL o por un tercero 
                    (EXPOSITORES). Nuestros Servicios están disponibles para su uso a través de dispositivos electrónicos que NECESARIAMENTE 
                    cuenten con CAMARA WEB ya que durante el curso la plataforma cuenta con un sistema de reconocimiento facial el cual nos 
                    proporcionara la toma de 6 fotografías durante el curso las cuales serán utilizadas  (para la debida acreditación del 
                    curso y otorgamiento de los puntos válidos para la NEC) en caso de los apoyos mutuos será necesario completar la fase 
                    de verificación la cual se encuentra al momento del registro para poderse inscribir de manera adecuada. Para hacer uso 
                    de nuestros Servicios, necesitará la creación de una cuenta en nuestra plataforma, en la cual deberá proporcionar los 
                    datos reales y personales del usuario, tales como nombre completo, edad, ocupación, correo electrónico y una fotografía 
                    actual y personal, entre otros. Cabe mencionar que las cuentas son exclusivas e intransferibles, para realizar la compra 
                    de algún curso el pago será únicamente con tarjetas de crédito o débito (los datos proporcionados no serán legibles para 
                    la plataforma ya que es un pago seguro) El precio de los Contenidos puede estar sujeta a cambios. Los asociados que cuenten 
                    con la modalidad de pago mensual y que no cuenten con un adeudo mayor a 1 mensualidad tendrán acceso a la toma de 13 cursos 
                    durante el año teniendo en cuenta que una vez completos el curso numero 14 tendrá costo, en caso de los asociados que 
                    cuenten con el pago de la anualidad la cual debe ser cubierta durante los 3 primeros meses del año deberán realizar la 
                    compra del curso a tomar, se tendrá un máximo de 30 minutos una vez iniciado el curso en línea para tener acceso a este. 
                    En caso de haber realizado el pago y no acceder al curso en línea se tendrá como máximo 2 oportunidades para visualizarlo 
                    después, la edad mínima para el registro ante nuestra plataforma será de 16 años, la plataforma esta creada para brindar 
                    servicio a nuestros asociados y todo el público en general que deseen obtener una capacitación. 
                    <br></br>
                    La plataforma cuenta con una capacidad máxima de 100 personas para la visualización capacitaciones impartidas en vivo.
                    Es necesario contar con una conexión a internet estable de al menos 6 Mbps para garantizar una reproducción de video continua.

                </p>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 text-bold color-prim text-right mb-0' style={{background: '#f7f7ffdd'}}>55-52-72-72-09 g.admiva@comexcol.com.mx</p>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 text-bold color-prim text-right mb-0' style={{background: '#f7f7ffdd'}}>55-55-16-81-58 recepcion@comexcol.com.mx</p>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 text-bold color-prim text-right' style={{background: '#f7f7ffdd'}}>55-55-15-36-68  colegio@comexcol.com.mx</p>
            </div>
            <br></br>
        </div>
    )
}