import React, { Component } from 'react';
import { Container, Table, Accordion, Card, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as moment from 'moment'
import locale from 'moment/locale/es-us';

class ListaAlumnos extends Component {
    constructor(props){
        super(props);
        this.state = {
            alumnos: [],
            success: false
        }
    }

    getList = async() => {
        await axios.post('/api/users/'+ this.props.location.state.course._id + '/listaAlumnos')
            .then(res => {
                if(res.status === 200){
                    this.setState({alumnos: res.data, success: true});
                }
            }).catch(err => console.log(err))
    }

    async componentDidMount() {
        if(this.props.location.state){
            this.getList();
        } else {
            this.props.history.push('/admin/cursos');
        }
    }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }

    comexcolAmount = (amount) => {
        const result = Math.round((amount + 3.48)/0.95824 * 100) / 100
        return result
    }

    render() {
        let completed = <><div className='text-center text-success'><i className='fas fa-circle' style={{transform: 'scale(1.4)'}}></i></div></>;
        let notComplete = <><div className='text-center text-danger'><i className='fas fa-circle' style={{transform: 'scale(1.4)'}}></i></div></>;
        let loadIco = this.state.success ? <></> : <Spinner animation="grow" variant='prim' />
        return(
            <>
                {this.props.location.state ? <Container style={{marginTop: '60px'}}>
                    <Link className='btn btn-info mb-2' to={{ pathname: '/admin/cursos' }}>
                        Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
                    </Link>
                    <h3 className='text-bold mb-3'>{this.props.location.state.course.title}{loadIco}</h3>
                    <h3>Lista de Usuarios inscritos al curso ({this.state.alumnos.length})</h3>
                    <Accordion>
                        <Card style={{borderRadius:'10px'}}>
                            <Card.Header style={{marginBottom: '0px'}}>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Detalles del curso
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <ul>
                                        <li>Título: <span className='text-bold'>{this.props.location.state.course.title}</span></li>
                                        <li>Descripción: <span className='text-bold'>{this.props.location.state.course.description}</span></li>
                                        <li>Impartido por: <span className='text-bold'>{this.props.location.state.course.professor}</span></li>
                                        <li>Usuarios inscritos: <span className='text-bold'>{this.state.alumnos.length}</span></li>
                                        <li>Costo: </li>
                                        <ul>
                                            <li>
                                                Asociados: <span className='text-bold'>{`$ ${this.formatMoney(this.props.location.state.course.costo.asociados)} MXN`} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.props.location.state.course.costo.asociados))))} MXN`})</span>
                                            </li>
                                            <li>
                                                Apoyo Mutuo: <span className='text-bold'>{`$ ${this.formatMoney(this.props.location.state.course.costo.apoyoMutuo)} MXN`} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.props.location.state.course.costo.apoyoMutuo))))} MXN`})</span>
                                            </li>
                                            <li>
                                                Estudiantes: <span className='text-bold'>{`$ ${this.formatMoney(this.props.location.state.course.costo.estudiantes)} MXN`} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.props.location.state.course.costo.estudiantes))))} MXN`})</span>
                                            </li>
                                            <li>
                                                Público en General: <span className='text-bold'>{`$ ${this.formatMoney(this.props.location.state.course.costo.publicoEnGeneral)} MXN`} (Precio al público {`$ ${this.formatMoney(Number(this.comexcolAmount(Number(this.props.location.state.course.costo.publicoEnGeneral))))} MXN`})</span>
                                            </li>
                                        </ul>
                                        <li>En vivo?: <span className='text-bold'>{this.props.location.state.course.liveStream ? 'Curso en vivo' : 'No es en vivo'}</span></li>
                                        {this.props.location.state.course.liveStream ? <li>Fecha de transmisión: <span className='text-bold'>{moment(this.props.location.state.course.dateRelease).format('LLLL')}</span></li> : <></>}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Table responsive striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>No. Registro</th>
                                <th style={{maxWidth: '170px'}}>Asistencia</th>
                                <th style={{maxWidth: '150px'}}>Cumplimiento de participación</th>
                                <th>Tipo de Usuario</th>
                                <th>No. Asociado</th>
                                <th>Detalles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.alumnos.map(alumno => (
                                <tr key={alumno._id}>
                                    <th>{alumno.name}</th>
                                    <th>{alumno.noRegistro}</th>
                                    <th style={{maxWidth: '170px'}}>{alumno.courses.find(x => x.id === this.props.location.state.course._id).cursoEnLinea ? (alumno.courses.find(x => x.id === this.props.location.state.course._id).cursoEnLinea.asistencia ? completed : notComplete) : 'No hay asistencia en vivo'}</th>
                                    <th>{alumno.courses.find(x => x.id === this.props.location.state.course._id && x.completed) ? completed : notComplete}</th>
                                    <th>{alumno.userType.type}</th>
                                    <th>{alumno.userType.noIdentity}</th>
                                    <th><Link className='btn btn-comp' to={{ pathname: '/admin/usuarios/detalles', state: {id: alumno._id} }} >
                                        Detalles &nbsp; <i className="far fa-id-card"></i>
                                    </Link> &nbsp;</th>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container> : <></>}
            </>
        )
    }
}

export default ListaAlumnos;