import React, { Component } from 'react'
import ListaCursos from './listaCursos.js';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

class Admin extends Component {

    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.auth.user.rol !=='Admin') {
          this.props.history.push("/home");
        }
    }

    render() {
        return (
            <div className='container' style={{marginTop: '60px'}}>
                <Link className='btn btn-info' to={{ pathname: '/home' }}>
                    Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
                </Link>
                <h2 className='text-bold color-prim text-center my-3'>Admininistrador</h2>
                <hr></hr>
                <Row>
                    <Col md='6' lg='4' className='p-4 p-sm-2'>
                        <Card className='card h-100 card-block d-flex border-primary hover-card'>
                            <Card.Header className='text-center color-prim'><i className='fas fa-list-ul fa-3x'></i></Card.Header>
                            <Card.Body>
                                <Card.Title className='text-bold text-center'>Administrar cursos</Card.Title>
                                <Card.Text className='text-center'>
                                    Aquí puedes crear cursos, modificar los cursos existentes, deshabilitar, etc.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className='text-center'>
                                <Link className='btn btn-comp' to={{ pathname: '/admin/cursos' }}>
                                    Cursos &nbsp; <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md='6' lg='4' className='p-4 p-sm-2'>
                        <Card className='card h-100 card-block d-flex border-primary hover-card'>
                            <Card.Header className='text-center color-prim'><i className='fas fa-users-cog fa-3x'></i></Card.Header>
                            <Card.Body>
                                <Card.Title className='text-bold text-center'>Administrar Usuarios</Card.Title>
                                <Card.Text className='text-center'>
                                    Aquí puedes monitorear a los usuarios inscritos, suspenderlos, revisar datos biométricos y a que cursos asistieron.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className='text-center'>
                                <Link className='btn btn-comp' to={{ pathname: '/admin/usuarios' }}>
                                    Usuarios &nbsp; <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md='6' lg='4' className='p-4 p-sm-2'>
                        <Card className='card h-100 card-block d-flex border-primary hover-card'>
                            <Card.Header className='text-center color-prim'><i className='fas fa-comments fa-3x'></i></Card.Header>
                            <Card.Body>
                                <Card.Title className='text-bold text-center'>Chat y servidor en vivo</Card.Title>
                                <Card.Text className='text-center'>
                                    Administra el chat del curso que se está transmitiendo en vivo así como el inicio y el fin de la transmisión.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className='text-center'>
                                <Link className='btn btn-comp' to={{ pathname: '/admin/chat' }}>
                                    Chat y servidor en vivo &nbsp; <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md='6' lg='4' className='p-4 p-sm-2'>
                        <Card className='card h-100 card-block d-flex border-primary hover-card'>
                            <Card.Header className='text-center color-prim'><i className='fas fa-file-invoice fa-3x'></i></Card.Header>
                            <Card.Body>
                                <Card.Title className='text-bold text-center'>Reportes</Card.Title>
                                <Card.Text className='text-center'>
                                    Genera reportes por mes por usuario.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className='text-center'>
                                <Link className='btn btn-comp' to={{ pathname: '/admin/resumen' }}>
                                    Resumen &nbsp; <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md='6' lg='4' className='p-4 p-sm-2'>
                        <Card className='card h-100 card-block d-flex border-primary hover-card'>
                            <Card.Header className='text-center color-prim'><i className='far fa-chart-bar fa-3x'></i></Card.Header>
                            <Card.Body>
                                <Card.Title className='text-bold text-center'>Estadísticas</Card.Title>
                                <Card.Text className='text-center'>
                                    Consulta las estadísticas de los cursos y los usuarios.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className='text-center'>
                                <Link className='btn btn-comp' to={{ pathname: '/admin/estadisticas' }}>
                                    Estadísticas &nbsp; <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md='6' lg='4' className='p-4 p-sm-2'>
                        <Card className='card h-100 card-block d-flex border-primary hover-card'>
                            <Card.Header className='text-center color-prim'><i className='fas fa-chalkboard-teacher fa-3x'></i></Card.Header>
                            <Card.Body>
                                <Card.Title className='text-bold text-center'>Cuenta de Profesor</Card.Title>
                                <Card.Text className='text-center'>
                                    Genere nuevas contraseñas para una cuenta de tipo 'Profesor'
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className='text-center'>
                                <Link className='btn btn-comp' to={{ pathname: '/admin/profesor' }}>
                                    Profesor &nbsp; <i className="fas fa-arrow-circle-right"></i>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <br></br>
            </div>
        )}

}

Admin.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(Admin);