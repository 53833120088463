import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import { Navbar, Dropdown, Container, Nav, NavDropdown } from 'react-bootstrap';

class NavBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: {
        name: '...'
      },
      title:'',
      expanded: false
    }
  }

  onLogoutClick = e => {
    // e.preventDefault();
    this.props.logoutUser();
  };

  componentDidMount = async () => {
    this.setState({user: this.props.auth.user});
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.user !== prevProps.auth.user) {
      this.setState({user: this.props.auth.user});
    }
  }

  render() {
    let ico =<></>
    if(this.state.user.name){
      ico = <div className='d-flex align-items-center'>
              <i className="fas fa-user-circle d-none d-md-block" style={{opacity: '0.5'}}></i>
            </div>
    }
    return (
      <>
        <Navbar collapseOnSelect='true' expand="md" className='bg-sec-dark my-0 py-0' fixed='top' variant="dark" style={{boxShadow: '0px 1px 15px 0px #555577'}}>
          <Container className='my-0 py-1 py-md-0'>
          <Navbar.Brand className='m-0 pt-1'>
            <Link to="/home">
              {/* <img src='./images/comexcol-letras-navbar.svg' alt='' style={{height: '40px', width: 'auto'}}></img> */}
              <svg
                viewBox="0 0 235.588 40"
                height="40"
                width="235.588">
                <g
                  transform="translate(0,-268.09611)"
                  id="layer1">
                  <g
                    id="flowRoot4695"
                    className='g-path-comexcol-1'
                    transform="matrix(0.29875175,0,0,0.29875175,291.96764,175.28063)"
                    aria-label="Comexcol">
                    <path
                      id="path819"
                      className='letras-comexcol'
                      d="m -918.56771,346.76302 2.50651,7.10937 h -0.63802 q -9.7526,-9.70703 -22.96875,-9.70703 -13.71745,0 -22.19401,8.15756 -8.43099,8.15755 -8.43099,19.73307 0,7.15495 3.73698,16.45182 3.78255,9.29688 11.89453,15.3125 8.11198,5.97005 19.64193,5.97005 7.5651,0 14.8112,-2.41536 7.29167,-2.46094 10.84635,-6.5625 l 0.59245,0.18229 -4.69401,7.20052 q -11.39323,2.46094 -14.8112,2.91667 -3.37239,0.45573 -9.06901,0.45573 -21.10026,0 -30.21484,-9.75261 -9.06901,-9.79817 -9.06901,-23.42448 0,-9.20573 4.64843,-17.68229 4.69401,-8.52213 13.48959,-13.39844 8.79557,-4.92187 20.09765,-4.92187 5.65105,0 9.75261,1.04818 4.14713,1.0026 6.88151,2.09635 l 3.00781,1.13932 q 0.13672,0.0456 0.18229,0.0911 z" />
                    <path
                      id="path821"
                      className='letras-comexcol'
                      d="m -868.46028,342.38802 q 14.1276,0 23.10547,8.61328 8.97786,8.56771 8.97786,21.41927 0,16.54297 -10.39062,27.84505 -10.39063,11.30209 -27.57162,11.30209 -13.94531,0 -22.83203,-9.11459 -8.88672,-9.11458 -8.88672,-21.875 0,-8.61328 4.28385,-17.68229 4.28386,-9.06901 12.89714,-14.76562 8.61328,-5.74219 20.41667,-5.74219 z m -0.27344,65.625 q 6.92708,0 13.67188,-3.69141 6.74479,-3.73697 10.61848,-10.2539 3.8737,-6.51693 3.8737,-13.39844 0,-9.7526 -5.24088,-17.72787 -5.19532,-7.97526 -13.53516,-12.48697 -8.29427,-4.51172 -15.35807,-4.51172 -9.98047,0 -18.13802,7.51953 -8.11198,7.51953 -8.11198,18.68489 0,14.26433 9.93489,25.06511 9.9349,10.80078 22.28516,10.80078 z" />
                    <path
                      id="path823"
                      className='letras-comexcol'
                      d="m -768.22265,343.39062 h 10.57292 v 0.6836 q -5.33203,0.27343 -5.33203,5.60547 0,1.13932 0.27343,3.14453 l 7.01823,50.85937 q 0.63802,3.82813 1.41276,4.92188 0.82032,1.04818 3.32683,1.27604 v 0.63802 h -15.08464 v -0.63802 q 2.96224,0.0912 2.96224,-2.91667 0,-0.68359 -0.27344,-2.6888 l -6.83593,-49.3099 -24.06251,55.55339 h -0.45572 l -24.19922,-55.46224 -6.79037,49.58333 q -0.18229,1.13933 -0.18229,2.59766 0,2.64323 3.05338,2.64323 v 0.63802 h -10.2539 v -0.63802 q 2.32422,0 2.96224,-0.59245 0.68359,-0.59245 1.04818,-1.82291 0.36458,-1.27605 0.54687,-2.82553 l 7.65625,-54.23177 q -1.86849,-4.51172 -3.73698,-5.42317 -1.86849,-0.91146 -3.60026,-0.91146 v -0.6836 h 12.66927 l 23.60677,54.91537 z" />
                    <path
                      id="path825"
                      className='letras-comexcol'
                      d="m -747.03124,344.16536 v -0.77474 h 42.20052 v 9.25131 h -0.6836 q -1.36718,-5.14974 -2.32422,-6.0612 -0.91145,-0.91146 -4.55729,-0.91146 h -22.14844 v 30.1237 h 20.55339 q 3.05339,0 3.8737,-0.54688 0.82031,-0.59245 0.86588,-1.6862 h 0.6836 v 6.33464 h -0.6836 q -0.22786,-1.32161 -1.09375,-1.77734 -0.82031,-0.45573 -3.09895,-0.45573 h -21.10027 v 20.73568 q 0,4.69401 0.86589,6.79036 0.86589,2.05078 2.73438,2.59766 1.91406,0.5013 5.74218,0.5013 h 11.84896 q 5.65104,0 7.20052,-0.36459 1.59505,-0.41015 2.6888,-1.54947 1.09375,-1.1849 1.6862,-5.10417 h 0.63802 v 9.2513 h -45.89192 v -0.63802 q 2.32421,-0.13672 3.50911,-0.86589 1.23047,-0.77473 1.23047,-3.91927 v -54.23177 q 0,-3.6914 -0.63802,-5.19531 -0.63802,-1.50391 -4.10156,-1.50391 z" />
                    <path
                      id="path827"
                      className='letras-comexcol'
                      d="m -642.89713,343.39062 v 0.6836 q -2.82552,-0.31901 -5.01302,2.77994 -0.0456,0 -0.13672,0.13672 -0.0456,0.13672 -0.13672,0.22787 l -0.36459,0.5013 q -0.22786,0.27344 -0.22786,0.36458 l -0.45573,0.45573 -17.08984,24.10808 20.46224,29.48567 q 3.37239,5.10417 5.14974,6.51693 1.77734,1.36719 3.41797,1.23047 v 0.63802 h -16.67969 v -0.63802 q 2.14192,0.13672 2.14192,-1.50391 0,-1.32161 -1.41276,-3.37239 l -17.81901,-25.65755 -18.0013,25.11067 q 0,0.0456 -1.04818,1.59506 -1.23047,1.45833 -1.23047,2.59765 0,1.36719 2.27865,1.23047 v 0.63802 h -10.02604 v -0.63802 q 2.1875,0 3.82812,-1.6862 l 0.77474,-0.82031 21.73828,-30.44271 -20.05208,-28.9388 q 0,-0.63802 -1.73177,-2.36979 -1.73177,-1.73177 -3.8737,-1.54948 v -0.6836 h 16.90755 v 0.6836 q -2.73437,0 -2.73437,1.5039 0,1.09375 1.6862,3.50912 l 14.67448,21.10026 15.76823,-22.10287 q 0.0456,-0.0456 0.68359,-1.27604 0.68359,-1.23047 0.68359,-1.54948 0,-0.77474 -0.5013,-0.95703 -0.45573,-0.22786 -1.82291,-0.22786 v -0.6836 z" />
                    <path
                      id="path829"
                      className='letras-comexcol'
                      d="m -578.91274,346.76302 2.50651,7.10937 h -0.63802 q -9.7526,-9.70703 -22.96875,-9.70703 -13.71745,0 -22.19401,8.15756 -8.43099,8.15755 -8.43099,19.73307 0,7.15495 3.73698,16.45182 3.78255,9.29688 11.89453,15.3125 8.11198,5.97005 19.64193,5.97005 7.5651,0 14.8112,-2.41536 7.29166,-2.46094 10.84635,-6.5625 l 0.59245,0.18229 -4.69401,7.20052 q -11.39323,2.46094 -14.8112,2.91667 -3.3724,0.45573 -9.06901,0.45573 -21.10026,0 -30.21485,-9.75261 -9.06901,-9.79817 -9.06901,-23.42448 0,-9.20573 4.64844,-17.68229 4.69401,-8.52213 13.48959,-13.39844 8.79557,-4.92187 20.09765,-4.92187 5.65104,0 9.75261,1.04818 4.14713,1.0026 6.88151,2.09635 l 3.00781,1.13932 q 0.13672,0.0456 0.18229,0.0911 z" />
                    <path
                      id="path831"
                      className='letras-comexcol'
                      d="m -528.8509,342.38802 q 14.1276,0 23.10547,8.61328 8.97786,8.56771 8.97786,21.41927 0,16.54297 -10.39062,27.84505 -10.39063,11.30209 -27.57162,11.30209 -13.94531,0 -22.83203,-9.11459 -8.88672,-9.11458 -8.88672,-21.875 0,-8.61328 4.28386,-17.68229 4.28385,-9.06901 12.89713,-14.76562 8.61328,-5.74219 20.41667,-5.74219 z m -0.27344,65.625 q 6.92709,0 13.67188,-3.69141 6.74479,-3.73697 10.61849,-10.2539 3.8737,-6.51693 3.8737,-13.39844 0,-9.7526 -5.24089,-17.72787 -5.19531,-7.97526 -13.53516,-12.48697 -8.29427,-4.51172 -15.35807,-4.51172 -9.98047,0 -18.13802,7.51953 -8.11198,7.51953 -8.11198,18.68489 0,14.26433 9.9349,25.06511 9.93489,10.80078 22.28515,10.80078 z" />
                    <path
                      id="path833"
                      className='letras-comexcol'
                      d="m -492.50649,410.51953 v -0.63802 q 3.14453,0 4.05599,-1.1849 0.95703,-1.18489 0.95703,-4.05599 v -54.96093 q 0,-2.91667 -0.72917,-4.19271 -0.72916,-1.32162 -4.375,-1.41276 v -0.6836 h 18.00131 v 0.6836 q -2.50651,0 -3.50912,0.63802 -1.0026,0.63802 -1.32161,1.5039 -0.31901,0.86589 -0.31901,3.91928 v 47.12239 q 0,6.0612 2.91666,8.56771 2.91667,2.46094 9.98047,2.46094 h 8.20313 q 5.92448,0 8.65885,-2.91667 2.77995,-2.91667 3.09896,-10.98307 h 0.63802 v 16.13281 z" />
                  </g>
                  <g
                  className='g-path-comexcol-2'
                    transform="matrix(0.22831059,0,0,0.22831059,160.18944,244.31075)"
                    id="g4612">
                    <path
                      className='logo-comexcol'
                      d="m 59.613932,250.49948 c 3.485941,3.33116 7.420827,6.69333 12.08815,7.77213 5.148033,1.18991 9.451518,1.59797 15.742709,-1.85208 2.381206,-1.80694 4.654061,-3.32166 5.572786,-6.96185 0.504782,-2.00005 0.483849,-4.65656 -0.677995,-6.49883 -1.9032,-3.01779 -5.222354,-4.86389 -9.128125,-5.58932 -3.800834,-0.50948 -7.73062,-0.49906 -11.724348,-0.21496 -2.010711,0.19784 -4.848014,0.3106 -6.649749,0.50714 -0.781388,0.0852 -1.377576,0.20469 -1.635017,0.13779 -0.334663,-0.0649 -0.709735,-0.33644 -0.995219,-0.59767 -0.482249,-0.44127 -1.154521,-1.58514 -1.154521,-1.58514 -0.586278,-0.8627 -0.02431,-0.89129 0.529167,-0.92605 0,0 22.270383,-1.02458 33.370573,-0.29765 3.554875,0.2328 7.122497,0.64334 10.583337,1.48828 1.29136,0.31528 2.61419,0.65411 3.76063,1.32693 1.30624,0.7666 2.21882,1.93531 2.43936,3.27989 0.38775,2.36398 -0.30973,4.87656 -1.26078,7.07525 -1.47536,3.41085 -3.80849,6.55744 -6.65935,8.94139 -3.05181,2.55199 -6.774244,4.45821 -10.682208,5.32224 -3.929009,0.8687 -8.007581,1.40822 -12.071614,1.4056 -3.837502,-0.002 -7.958652,-1.04611 -11.641666,-2.57969 -2.673448,-1.1132 -4.875071,-3.30053 -6.813022,-5.39088 -1.206274,-1.37893 -2.084964,-3.08545 -2.993098,-4.76252 z"
                      id="path4571" />
                    <path
                      className='logo-comexcol'
                      d="m 117.15637,188.60921 c 2.19077,2.34934 4.66368,4.72051 7.5969,5.48134 3.23533,0.8392 5.9399,1.12698 9.89365,-1.30619 1.4965,-1.27435 2.92488,-2.34261 3.50226,-4.90988 0.31724,-1.41055 0.30409,-3.28405 -0.42608,-4.58332 -1.19609,-2.1283 -3.28203,-3.43028 -5.73666,-3.9419 -2.38866,-0.35932 -4.85837,-0.35196 -7.36826,-0.1516 -1.26366,0.13954 -3.04678,0.21905 -4.1791,0.35767 -0.49107,0.06 -0.86576,0.14435 -1.02754,0.0972 -0.21032,-0.0457 -0.44604,-0.23728 -0.62546,-0.42152 -0.30307,-0.31121 -0.72556,-1.11791 -0.72556,-1.11791 -0.36846,-0.60844 -0.0154,-0.62861 0.33255,-0.65311 0,0 13.99602,-0.72258 20.97203,-0.20992 2.2341,0.1642 4.47621,0.45372 6.6512,1.04961 0.81157,0.22236 1.64292,0.46132 2.36341,0.93582 0.82091,0.54067 1.39442,1.3649 1.53304,2.31316 0.24368,1.66721 -0.19465,3.43923 -0.79236,4.98985 -0.92719,2.40553 -2.39349,4.62468 -4.18512,6.30598 -1.91795,1.79979 -4.25733,3.14417 -6.71333,3.75351 -2.46923,0.61266 -5.03244,0.99317 -7.58652,0.99132 -2.4117,-10e-4 -5.00168,-0.73777 -7.31631,-1.81933 -1.68014,-0.78509 -3.06377,-2.32773 -4.2817,-3.80194 -0.75811,-0.9725 -1.31031,-2.17602 -1.88104,-3.35881 z"
                      id="path4571-2" />
                    <path
                      className='logo-comexcol'
                      d="m 59.068228,219.14635 c 3.29369,-4.14795 6.000852,-8.25009 8.400521,-12.73307 5.721399,-10.68853 14.155208,-33.50286 14.155208,-33.50286 2.193225,-6.95654 3.150191,-14.32818 4.334214,-21.56355 0.466601,-2.85132 0.391047,-5.84184 1.089745,-8.59895 0.967391,-3.81739 3.002745,-2.41892 3.96875,0 1.67144,4.18536 2.927506,8.5096 3.671093,12.96458 3.124082,18.717 6.881581,37.99585 12.071611,57.41458 0,0 2.34834,6.14697 3.96875,8.99584 0.44163,0.77643 1.36325,1.31027 1.55443,2.18281 0.13929,0.63573 0.23601,1.66569 -0.3638,1.91823 -0.95883,0.4037 -2.02281,0.66199 -3.02618,0.7276 -1.37743,0.0901 -3.23624,0.18884 -4.05143,-0.59531 -1.45746,-1.40196 -2.5222,-4.3024 -2.91041,-6.7138 -2.028527,-12.60032 -3.314901,-26.47537 -5.509909,-39.64303 -1.123162,-6.73776 -2.050921,-13.51695 -3.718387,-20.13542 -0.448095,-1.77857 -1.683797,-5.23848 -1.683797,-5.23848 -1.21901,-3.06765 -2.191344,-3.97004 -2.619243,-0.0935 -0.104629,0.0697 -1.975532,13.07108 -3.788545,19.17659 -3.227664,10.86948 -6.81439,21.61213 -10.694775,32.1405 -1.611308,4.37184 -3.469646,8.83199 -3.617509,13.93008 -0.211982,2.26899 -0.08017,4.53801 -0.08552,6.83607 0.05114,2.50469 0.07088,2.51004 -1.67917,2.66486 -1.760959,0.15577 -4.504649,0.4725 -6.552732,0.005 -1.757241,-0.40097 -3.542522,-1.68634 -4.919101,-2.91122 -1.150319,-1.02356 -0.824121,-2.55546 -0.374179,-3.64824 0.545544,-1.32497 1.586909,-2.54762 2.380363,-3.57944 z"
                      id="path4588" />
                    <path
                      className='logo-comexcol'
                      d="m 115.29199,165.5588 c 1.89757,-2.41432 3.45724,-4.80198 4.83974,-7.41131 3.29624,-6.22128 8.15513,-19.50043 8.15513,-19.50043 1.26356,-4.04908 1.81489,-8.33976 2.49705,-12.55115 0.2688,-1.65961 0.22527,-3.40024 0.62782,-5.00503 0.55732,-2.22192 1.72994,-1.40795 2.2865,0 0.96294,2.4361 1.68659,4.95305 2.11499,7.54607 1.79985,10.89429 3.96464,22.1156 6.95472,33.41833 0,0 1.35295,3.57785 2.2865,5.23607 0.25444,0.45191 0.7854,0.76265 0.89554,1.27049 0.0802,0.37003 0.13594,0.96952 -0.2096,1.11653 -0.55239,0.23496 -1.16538,0.38531 -1.74345,0.42349 -0.79357,0.0523 -1.86448,0.10985 -2.33411,-0.3465 -0.83969,-0.81602 -1.4531,-2.50422 -1.67676,-3.9078 -1.16869,-7.33405 -1.90978,-15.41006 -3.17439,-23.07434 -0.64708,-3.92173 -1.18158,-7.86759 -2.14224,-11.71987 -0.25816,-1.03524 -0.97008,-3.04907 -0.97008,-3.04907 -0.7023,-1.78555 -1.26248,-2.31079 -1.509,-0.0546 -0.0603,0.0406 -1.13815,7.60807 -2.18266,11.16179 -1.85953,6.32662 -3.92593,12.57939 -6.16151,18.70747 -0.92831,2.54464 -1.99894,5.14069 -2.08412,8.10804 -0.1222,1.32069 -0.0463,2.64137 -0.0492,3.97895 0.0294,1.45789 0.0408,1.46098 -0.9674,1.55111 -1.01454,0.0906 -2.59524,0.275 -3.77517,0.003 -1.01239,-0.2334 -2.04093,-0.98155 -2.83401,-1.6945 -0.66272,-0.59578 -0.4748,-1.48741 -0.21558,-2.12346 0.31431,-0.77122 0.91426,-1.48286 1.37139,-2.08344 z"
                      id="path4588-2" />
                    <path
                      className='logo-comexcol'
                      d="m 98.502183,126.9364 c -3.483651,0.48586 -6.242773,1.79286 -7.857725,4.39658 -0.941205,1.76087 -0.94375,3.25892 -0.327404,4.58368 1.300384,2.17609 2.15315,2.19201 3.531298,2.15151 1.626633,-0.0478 3.310094,-1.52542 4.700603,-2.47892 1.752825,-1.20194 4.102685,-3.75414 5.893295,-5.16832 1.48963,-1.17648 3.49005,-1.7389 5.31393,-2.41737 2.04813,-0.76188 3.6221,-1.07379 6.29067,-1.84526 3.22618,-0.93268 7.82323,-1.93919 10.1331,-2.89517 1.46364,-0.60575 2.93271,-1.67242 3.42572,-2.96838 0.53123,-1.39642 -0.77982,-2.00887 -1.91765,-1.44994 -1.65348,0.81223 -3.4649,1.4851 -5.3788,2.24507 -5.12254,2.03405 -11.27938,3.68217 -17.07185,4.91108 -2.21726,0.47041 -6.735187,0.93544 -6.735187,0.93544 z"
                      id="path4605"/>
                  </g>
                </g>
              </svg>
            </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto text-center">
              {ico}
              <p className='my-1 my-md-0 d-block d-md-none'><i className="fas fa-user-circle" style={{opacity: '0.5'}}></i>&nbsp;{this.state.user.name}</p>
              <Nav.Link className='d-block d-md-none my-1 my-md-0' href='/perfilUsuario'>Mi Perfil</Nav.Link>
              <Nav.Link className='d-block d-md-none my-1 my-md-0' href='/login' onClick={this.onLogoutClick}>Cerrar sesión</Nav.Link>
                <NavDropdown className='d-none d-md-block' title={this.state.user.name} id="collasible-nav-dropdown text-center">
                  <Dropdown.Item className='text-center' href='/perfilUsuario'>Mi Perfil</Dropdown.Item>
                  <Dropdown.Item onClick={this.onLogoutClick} className='text-center'>Cerrar sesión</Dropdown.Item>
                </NavDropdown>
              </Nav>
              <hr className='d-block d-md-none mb-2 mt-0' style={{borderColor: '#FFFFFFAA'}}></hr>
              <Nav className="ml-auto text-center">
                <Nav.Link target='blank' href='https://www.facebook.com/COMEXCOL/' className='my-2 mr-2 my-md-0 py-0'><i className="fab fa-facebook-f" style={{transform: 'scale(1.5)'}}></i></Nav.Link>
                <Nav.Link target='blank' href='https://twitter.com/ComexcolAC' className='my-2 my-md-0 py-0'><i className="fab fa-twitter" style={{transform: 'scale(1.5)'}}></i></Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

NavBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
  auth: state.auth
});
  
export default connect(
  mapStateToProps,
  { logoutUser }
)(NavBar);
