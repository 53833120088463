import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ListaCursos from './listaCursos';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

class AdminCursos extends Component {

    componentDidMount(){
        if (this.props.auth.user.rol !=='Admin') {
            this.props.history.push("/home");
        }
    }

    render() {
        return (
            <div className='container' style={{marginTop: '60px'}}>
                <Link className='btn btn-info' to={{ pathname: '/admin' }}>
                    Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
                </Link>
                <h2 className='color-prim text-bold text-center my-3'>Administrar Cursos</h2>
                <hr></hr>
                <Link className="btn btn-success my-2"
                 to={{
                    pathname: '/admin/crearCursos',
                    state:{editing:false}
                    }}
                > Crear curso nuevo &nbsp;<i className="fas fa-plus-circle"></i></Link>
                <hr></hr>
                <ListaCursos></ListaCursos>
                <br></br>
            </div>
        );
    }
}

AdminCursos.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(AdminCursos);