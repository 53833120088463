import React, { useState, useEffect } from 'react'
import io from 'socket.io-client';
import { Button, Row, Col, Accordion, Card } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import * as moment from 'moment';
let socket = '';

function ProfessorChat (props) {

    const [messages, setMessages] = useState(Array);
    const [online, setOnline] = useState(0);
    const [userList, setUserList] = useState(Array);

    useEffect(() => {
        if (props.auth.user.rol ==='Student') {
            props.history.push("/home");
        }
    })

    useEffect(() => {
        socket = io('/adminChat');
        return () => {
            socket.close();
        }
    }, [])

    useEffect(() => {
        socket.on('msgFinal', msg => {
            const notification = new Audio('/sounds/notification.mp3');
            notification.play();
            setMessages(msg)
        })

        socket.on('users', (usrs) => {
            setOnline(usrs);
        })

        socket.on('online', (users) => {
            setUserList(users);
        })

        socket.on('offline', (users) => {
            setUserList(users);
        })
    }, [socket])

    const getDate = (date) => {
        return moment(date).format('LT');
    }

    return(
        <div style={{marginTop: '60px'}} className='px-2'>
            <Link className='btn btn-info ml-2' to={{ pathname: '/home' }}>
                Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
            </Link>
            <Row className='d-flex justify-content-center mx-2 mt-2'>
                <Col md='10' lg='12' style={{backgroundColor: '#18181b', borderRadius: '10px'}}>
                    <Row>
                        <Col lg = '8'>
                            <div style={{backgroundColor: '#18181b', borderRadius: '10px'}} className='p-2 p-lg-3'>
                                <h4 className='text-light'>Preguntas para el expositor: ({online !== 1 ? `${online} personas en línea` : `${online} persona en línea`})</h4>
                                <div className='mt-3'>
                                    <Scrollbars style={{height: 500}} renderThumbVertical={({ style, ...props }) => 
                                            <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}
                                            renderThumbHorizontal={({ style, ...props }) => 
                                            <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}>
                                        <ul style={{textAlign: "left"}} className='pl-4'>
                                            { messages.slice(0).reverse().map((msg) => (
                                                <li key={msg.key}>
                                                    <Row>
                                                        <Col xs='12'>
                                                            <span className='color-secondary-light text-bold' style={{fontSize: '0.85em'}}>{msg.user}: <span className='text-bold' style={{color: '#b8bfc1'}}>({getDate(msg.key)})</span></span>
                                                        </Col>
                                                        <Col>
                                                            <span className='color-comp-light'>{msg.message}</span>
                                                        </Col>
                                                    </Row>
                                                    <hr style={{borderColor: '#FFFFFF33'}} className='m-2'></hr>
                                                </li>
                                            ))}
                                        </ul>
                                    </Scrollbars>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Accordion>
                                <Card style={{borderRadius:'10px'}} className='bg-dark text-light my-3'>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            Lista de usuarios en línea:
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <ul>
                                                {userList.map(user => (
                                                    <li key={user.id}>{user.user}</li>
                                                ))}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br></br>
        </div>
    )
}

ProfessorChat.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(ProfessorChat);