import React from 'react';
import { Link } from 'react-router-dom';

export default function AvisoDePrivacidad() {
    return(
        <div className='container' style={{marginTop: '70px'}}>
            <Link to='/'><button className='btn btn-comp'>Inicio <i className='fas fa-home'></i></button></Link>
            <h2 className='color-prim text-bold text-center my-3'>Aviso de Privacidad</h2>
            <hr></hr>
            <div className='mx-xl-5 mx-md-3 mx-sm-0' style={{backgroundImage: 'url(./images/comexcol-complete.svg)', backgroundSize: 'contain'}}>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 mb-0' style={{background: '#f7f7ffdd'}}><b>AVISO DE PRIVACIDAD:</b>  Con fundamento en los artículos 15, 16 Y 17 de la Ley Federal de 
                    Protección de Datos Personales en Posesión de Particulares (en adelante la Ley); 
                    hacemos de su conocimiento que el COLEGIO MEXICANO DE CONTADORES PÚBLICOS Y LICENCIADOS EN CONTADURÍA A.C., 
                    en adelante COMEXCOL; con domicilio en Avenida Baja California No. 311, Colonia Hipódromo, Delegación Cuauhtémoc C.P. 06100, 
                    es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección derivado de la 
                    actividad que se realiza. Su información personal será utilizada para las siguientes finalidades: <span style={{color: '#020475'}}>Control de capacitación, 
                    mediante cursos, talleres en relación con Leyes y normatividad aplicable a la membresía</span>, con la 
                    finalidad de elevar la calidad profesional de la Contaduría Pública; celebrar reuniones para mantener 
                    la unión entre los Contadores Públicos; contribuir al desarrollo integral del Contador Público para que 
                    ejerza plenamente su capacidad profesional y humana; editar publicar y difundir a través de 
                    publicaciones, estudios, criterios, trabajos o informes de interés para la contaduría Pública; agrupar 
                    el mayor número de Contadores Públicos en el Distrito Federal para realizar la difusión de ideas, estudios, 
                    proyectos o iniciativas; así como para efectos de facturación, los datos que serán necesarios para la utilización 
                    de nuestro nuevo sistema de capacitación a través de la plataforma <span style={{color: '#020475'}}>digital de</span> COMEXCOL serán: correo electrónico, 
                    RFC o número de registro, Numero de asociado, nombre, fecha de nacimiento, ocupación género, dirección, 
                    nivel de estudios así como una fotografía del actual del usuario (se hará uso de datos biométricos para 
                    dar <span style={{color: '#020475'}}>validación de la participación de contadores en curso y talleres</span>). De igual forma se puede utilizar 
                    su información de manera secundaria para los siguientes fines: • Envío de publicidad referente a capacitación y/o
                    cursos. Es necesario informarle que en la plataforma NO se almacena la siguiente información si se solicita al 
                    usuario sus datos bancarios al momento de realizar la compra de algún curso tales como son: número de tarjeta, 
                    fecha de vigencia, CVC, correo electrónico, teléfono, <span style={{color: '#020475'}}>nombre</span>, RFC, <span style={{color: '#020475'}}>etc</span>. 
                    Los correos oficiales de COMEXCOL son: <span className='email-avisoP'>colegio@comexcol.org.mx</span>; 
                    <span className='email-avisoP'>g.admiva@comexcol.org.mx</span>; <span className='email-avisoP'>logistica@comexcol.org.mx</span>; 
                    <span className='email-avisoP'>tesoreria@comexcol.org.mx</span>; <span className='email-avisoP'>presidencia@comexcol.org.mx</span>; 
                    <span className='email-avisoP'>recepcion@comexcol.org.mx</span>, por lo que desconocemos y no nos 
                    hacemos responsables por información recibida en correos electrónico diferentes a las anteriores. Para mayor información 
                    del tratamiento y de los derechos que puede hacer valer usted puede acceder al Aviso de Privacidad Integral en 
                    www.comexcol.org.mx. o bien en nuestras oficinas ubicadas en Avenida Baja California No. 311, Colonia Hipódromo, 
                    Delegación Cuauhtémoc C.P. 06100.
                </p>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 text-bold color-prim text-right mb-0' style={{background: '#f7f7ffdd'}}>55-52-72-72-09 g.admiva@comexcol.com.mx</p>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 text-bold color-prim text-right mb-0' style={{background: '#f7f7ffdd'}}>55-55-16-81-58 recepcion@comexcol.com.mx</p>
                <p className='text-justify px-xl-5 px-md-3 px-sm-0 text-bold color-prim text-right' style={{background: '#f7f7ffdd'}}>55-55-15-36-68  colegio@comexcol.com.mx</p>
            </div>
            <br></br>
        </div>
    )
}