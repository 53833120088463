import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import * as moment from 'moment';
import axios from 'axios';
import * as locale from 'moment/locale/es-us';

class Miniature extends Component {
    
    constructor(props) {
        super(props);
        this.state={
          course:{},
          user:{},
          duration:0,
          bought:true,
          diff: false,
          diff2:false,
          replays:0,
          dateRelease:'',
          precio: '',
          roomSize: 0,
        }             
      }

    async componentDidMount(){
      this.setState(this.props)
      this.getDiff();
      if(this.props.course.liveStream){
        this.getDiff2();
        this.getRoomSize();
      }
      switch(this.props.user.userType.type) {
        case 'Asociado': 
          this.setState({precio: this.props.course.costo.asociados});
          break;
        case 'Apoyo Mutuo': 
          this.setState({precio: this.props.course.costo.apoyoMutuo});
          break;
        case 'Estudiante': 
          this.setState({precio: this.props.course.costo.estudiantes});
          break;
        case 'Público en General': 
          this.setState({precio: this.props.course.costo.publicoEnGeneral});
          break;
        default: 
          this.setState({precio: this.props.course.costo.publicoEnGeneral});
          break;
      }
    }

    getRoomSize = async () => {
      try {
          const res = await axios.get(`/api/courses/${this.props.course._id}/room`);
          this.setState({roomSize: res.data.roomSize});
      } catch (err) {
          alert('Ocurrió un error al obtener el curso, favor de intentarlo más tarde');
          console.log(err);
      }
  }

    getDiff = () => {
      const now = moment(Date.now());
      const localLocale = moment(this.props.course.dateRelease);
      const diff = localLocale.diff(now);
      if(diff <= 300000){
        this.setState({diff: true});
      }    
      moment.locale('es-us');
      localLocale.locale(false);
      const fecha = localLocale.format('LLLL');
      this.setState({dateRelease: fecha});
    }

    getDiff2 = () => {
      const now = moment(Date.now());
      const localLocale = moment(this.props.course.dateRelease).add(this.props.course.duration/1000,'seconds');
      const diff2 = localLocale.diff(now);
      if(diff2 <= -1000){
        this.setState({diff2: true});
      } }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
          const negativeSign = amount < 0 ? "-" : "";
          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;
          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
          console.log(e)
      }
    };

    comexcolAmount = (amount) => {
      let result = Math.round((amount + 3.48)/0.95824 * 100) / 100
      if(Number(amount) === 0) {
        return 0;
      } else  {
        return result
      }
    }

    render() {
      let precio = <></>;
    precio = this.props.bought ? precio = <></> : this.state.course.cursoGratuito && this.state.course.etiqueta !== 'No' ? precio = <p className='my-0 h6 text-bold text-success'>{this.state.course.etiqueta}</p> : precio = <p className='my-0 h6 text-bold'>{`$ ${this.formatMoney(this.comexcolAmount(Number(this.state.precio)))}`} MXN</p>

      let liveText = <></>;
      liveText = this.props.course.liveStream ? liveText = <p className="text-danger my-0 py-0 h6"><i className="fa fa-video-camera" aria-hidden="true"></i> En Vivo</p> : liveText = <></>;
      
      let dateRelease = <></>;
      dateRelease = this.props.course.liveStream ? <><hr className='my-1'></hr><div className='text-center h6 m-0'>{String(this.state.dateRelease).split(',')[1]}</div></> : <></>;
      
      let completed = <></>;
      completed = this.props.completed ? <><hr className='my-1'></hr><div className='text-center h6 text-success m-0'>Cumplió ✔</div></> : <></>;
      
      let puntosNec = <></>;
      puntosNec = this.props.course.puntosNec && (this.props.user.userType.type === 'Asociado' || this.props.user.userType.type === 'Apoyo Mutuo') ? <><hr className='my-1'></hr><div className='text-center h6 m-0 text-bold'>{this.props.course.puntosNec} puntos NEC</div></> : <></>

      let link;
      if(!this.props.bought){
        link=            
          <Link to={{
            pathname: '/compraCurso',
            state: { 
              courseID: this.props.course._id,
              userID: this.props.user.id,
              precio: this.state.precio,
              userType: this.props.user.userType.type,
              roomSize: this.state.roomSize,}}}
            className="btn btn-comp"> 
              Detalles <i className="fas fa-info-circle"></i>
          </Link>
        } else {
          if(this.props.course.liveStream){
            let classNames = (this.state.diff && !this.state.diff2) ? 'btn btn-sec' : 'btn btn-sec disabled'; // Tiempo previo o tiempo posterior al curso
            link=              
                <div className='d-flex' style={{justifyContent: 'space-around'}}>
                  <Link 
                    to={{ pathname: '/course', state: { id: this.props.course._id, userID: this.state.user.id, userName: this.state.user.name}}}
                    className={classNames}> 
                      Ver Transmisión 
                  </Link>

                  <Link 
                    className='btn btn-sec' style={{paddingLeft: '15px', paddingRight: '15px', borderRadius: '200px'}}
                    to={{ pathname: '/infoCurso', state: { courseID: this.props.course._id, userID: this.props.user.id, userType: this.props.user.userType.type, precio: this.state.precio}}}> 
                      <i className='fas fa-info'></i>
                  </Link>
                </div>
            completed = this.props.completed ? <><hr className='my-1'></hr><div className='text-center h6 text-success m-0'>Cumplió ✔</div></> : <></>;
          }
          else{
            let classNames = !this.props.replays ? 'btn btn-sec' : 'btn btn-sec disabled';
            link=              
                <div className='d-flex' style={{justifyContent: 'space-around'}} >
                  <Link to={{
                    pathname: '/course',
                    state: { id: this.props.course._id, userID: this.state.user.id, userName: this.state.user.name }}}
                    className={classNames}> 
                      Ver Video 
                  </Link>
                  <Link 
                    className='btn btn-sec' style={{paddingLeft: '15px', paddingRight: '15px', borderRadius: '200px'}}
                    to={{ pathname: '/infoCurso', state: {  courseID: this.props.course._id, userID: this.props.user.id, userType: this.props.user.userType.type, precio: this.state.precio}}}> 
                      <i className='fas fa-info'></i>
                  </Link>
                </div>
          }
      }

        return (   

          <div className="card h-100 card-block d-flex border-primary hover-card">
            <img className="card-img-top img img-fluid p-2" alt="Card-img-top"
              src={process.env.PUBLIC_URL +'/images/'+this.props.course.image}/>
            <div className="d-flex card-body align-items-center justify-content-center my-0 p-2">
              <div className="text-center">
                <h5 className="card-title py-0 my-0 text-bold"> {this.props.course.title}</h5>
              </div>
            </div>
            {this.props.bought ? <></> : <hr className='my-1'></hr>}
            <div className='d-flex px-2 text-center' style={{justifyContent: 'space-around'}}>
              {precio}{liveText}
            </div>
            {dateRelease}
            {completed}
            {puntosNec}
            {this.state.roomSize >= 150 && !this.props.bought ? <div><hr className='my-1'></hr><p className='text-danger m-0 text-center h6'>Sala llena</p></div> : ''}
            <div className="card-footer text-center mt-1">
              {link}
            </div>
          </div>
        )
    }
}

export default Miniature;