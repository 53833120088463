import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function Professor(props) {
    const [pass, setPass] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [renderInfo, setRenderInfo] = useState(<></>);
    const [renderButttons, setRenderButtons] = useState(<></>);
    const [msg, setMsg] = useState(<></>);
    
    useEffect(() =>{
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/home");
        }
    })

    useEffect(() => {
        getUser();
    }, [])

    const getUser = async() => {
        try {
            const res = await axios.get(`/api/users/5f21d99293dd5d762deda44d`)
            if(res.status === 200) {
                setUserInfo(res.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if(userInfo !== null) {
            setRenderInfo(  <div>
                                <p className='h5'>Correo Electrónico: <span className='text-bold'>{userInfo.email}</span></p>
                                <p className='h5'>Rol: <span className='text-bold'>Profesor</span></p>
                            </div>)
            if(pass !== '') {
                setRenderButtons(   <div className='mt-3 d-flex'>
                    <form onSubmit={handleSubmit}>
                        <Button variant='comp' type='submit'>Guardar &nbsp; <i className="far fa-check-circle"></i></Button>
                    </form>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant='err' onClick={() => setPass('')}>Cancelar &nbsp; <i className="far fa-times-circle"></i></Button>
                </div>)
            } else {
                setRenderButtons(<></>)
            }
        }
    }, [userInfo, pass])

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`/api/users/5f21d99293dd5d762deda44d/professor`, {'pass' : pass})
            if(res.status === 200) {
                setMsg(<p className='h6 text-bold text-info mt-3'>{res.data.msg}</p>);
            }
        } catch (err) {
            console.log(err);
            setMsg(<p className='h6 text-bold text-info mt-3'>Ocurrió un error, favor de intentarlo mas tarde</p>);
        }
    }
    
    const generatePassword = () => {
        var length = 10,
            charset = "abcdejkmnñovwxyzABCDEKLMNÑPQRXYZ0123456789.-_@#/%$!",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    return(
        <div className='container' style={{marginTop: '60px'}}>
            <Link className='btn btn-info' to={{ pathname: '/admin' }}>
                    Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
            </Link>
            <h2 className='color-prim text-bold text-center mb-2 mt-0'>Administrar cuenta de Profesor</h2>
            <hr></hr>
            {renderInfo}
            <hr></hr>
            <p className='h5'>Presione el botón para generar una nueva contraseña</p>
            <button className='btn btn-sec my-2' onClick={() => setPass(generatePassword())}> Generar <i className='fas fa-key'></i></button>
            <p className='text-bold h2 mt-2'>{pass}</p>
            {renderButttons}
            {msg}
            <hr></hr>
            <p className='text-bold h6 italic my-3'>Guarde bien la contraseña, una vez que salga de esta pagina ya no podra verla de nuevo, si la olvida tendrá que generar una nueva.</p>
        </div>)
}

Professor.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(Professor);