import React, { Component } from 'react';
import axios from 'axios';
import { Container, Form, Button} from 'react-bootstrap';

class newPass extends Component {
    constructor(props){
        super(props);
        this.state = {
            dml: '',
            dmc: '',
            password: '',
            password2: '',
            pwr: true,
            success: false,
            message: ''
        }
    }

    componentDidMount = () => {
        if(String(this.props.location.search).indexOf('drs') !== -1) {
            this.setState({dmc: String(this.props.location.search).substring(String(this.props.location.search).indexOf('drs'), String(this.props.location.search).indexOf('&ders')).split('=')[1] });
        }
        if(String(this.props.location.search).indexOf('ders') !== -1){
            this.setState({dml: String(this.props.location.search).substring(String(this.props.location.search).indexOf('ders')).split('=')[1]});
        }
    }

    onChange = e => {
        this.setState({[e.target.id]: e.target.value});
    }

    onSubmit = async(e) => {
        e.preventDefault();
        if(this.state.password !== this.state.password2){
            this.setState({pwr: !this.state.pwr});
        } else {
            await axios.put('/api/users/resetPass', {dmc: this.state.dmc, dml: this.state.dml, password: this.state.password})
            .then(res => {
                if(res.status === 200){
                    this.setState({success: true, pwr: !this.state.pwr, message: res.data.res});
                    setTimeout(
                        async function() {
                            this.props.history.push('/login');
                        }.bind(this),
                        5000
                    );
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }

    render() {
        let cla = this.state.pwr ? '' : 'is-invalid';
        let content = <></>;
        if(this.state.success){
            content =   <>
                            <h4 className='text-bold'>...</h4>
                            <h5>{this.state.message}</h5>
                        </>
        } else {
            content =   <>
                            <h4 className='text-bold'>Reestablecer constraseña</h4>
                            <h5>Introduce una nueva contraseña</h5>
                            <hr></hr>
                            <Form onSubmit={this.onSubmit}>
                                <Form.Row>
                                    <Form.Group as={'Col'} className='col-md-9 col-lg-8 col-xl-6'>
                                    <Form.Label>Nueva contraseña</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="******" 
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        id="password"
                                        required
                                        className={cla}
                                    />
                                    <Form.Control.Feedback type={'invalid'}>Las contraseñas deben coincidir</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={'Col'} className='col-md-9 col-lg-8 col-xl-6'>
                                    <Form.Label>Confirmar nueva contraseña</Form.Label>
                                    <Form.Control 
                                        onChange={this.onChange}
                                        value={this.state.password2}
                                        id="password2"
                                        type="password"
                                        placeholder='******'
                                        required 
                                        className={cla}
                                    />
                                    <Form.Control.Feedback type={'invalid'}>Las contraseñas deben coincidir</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Button variant="comp" type="submit">
                                Cambiar mi contraseña &nbsp; <i className="fas fa-lock"></i>
                                </Button>
                            </Form>
            
                        </>
        }

        return(
            <Container style={{marginTop: '60px'}}>
                {content}
            </Container>
        )
    }
}

export default newPass;