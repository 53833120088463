import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Form, Button, Row, Col, Accordion, Card } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import * as moment from 'moment';
let socket = '';

function AdminChat (props) {
    
    const [messages, setMessages] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [online, setOnline] = useState(0);
    const [userList, setUserList] = useState([]);
    const [msgFinal, setMsgFinal] = useState([]);
    const [clave, setClave] = useState(1);
    const [claveForm, setClaveForm] = useState(1);
    const [nms, setNms] = useState(false);
    const [chatTitle, setChatTitle] = useState('');
    const [cursos,setCursos] = useState([]);

    const getNodeMediaServer = async() => {
        try {
            const res = await axios.get('/nmsStatus');
            if(res.status === 200){
                setNms(res.data.status);
            }
        } catch(err) {}
    }

    const setNodeMediaServer = async() => {
        try {
            const res = await axios.post('/setNms', {'value': !nms});
            if(res.status === 200){
                setNms(res.data.status);
            }
        } catch(err) {}
    }

    useEffect(() =>{
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/home");
        }
    })

    useEffect(() => {
        getNodeMediaServer();
        try {
            socket = io('/adminChat');
        } catch (err) {}
        document.body.style.backgroundColor = "#0e0e10";
        return () => {
            try {
                socket.close();
            } catch (err) {}
            document.body.style.backgroundColor = "rgba(247, 247, 255)";
        }
    }, [])

    useEffect(() => {
        try {
            socket.on('msg', (msg) => {
                setMessages(msg);
            })
    
            socket.on('users', (usrs) => {
                setOnline(usrs);
            })
    
            socket.on('online', (users) => {
                setUserList(users);
            })
    
            socket.on('offline', (users) => {
                setUserList(users);
            })
    
            socket.on('msgFinal', (msg) => {
                setMsgFinal(msg);
            })
    
            socket.on('delete', (msg) => {
                setMsgFinal(msg);
            })
        } catch (err) {}
    }, [socket]);

    const sendMessage = () => {
        if(mensaje !== '' && mensaje !== '\n') {
            socket.emit('msg', {user: 'Administración', message: mensaje});
        }
        setMensaje('');
    }

    const keyDownHandler = (e) => {
        if(e.wich === 13 || e.keyCode === 13){
            sendMessage();
        }
    }

    const acceptMessage = (e) => {
        let mensaje = messages.find(d => d.key == Number(e.target.name));
        try {
            socket.emit('msgFinal', mensaje);
        } catch (err) {}
    }

    const deleteMessage = (e) => {
        let mensaje = messages.find(d => d.key == Number(e.target.name));
        try {
            socket.emit('delete', mensaje);
        } catch (err) {}
    }

    const deleteMsgFinal = (e) => {
        let mensaje = msgFinal.find(d => d.key == Number(e.target.name));
        try {
            socket.emit('deleteMsgFinal', mensaje);
        } catch (err) {}
    }

    const deleteChat = async() => {
        try {
            const res = await axios.get('/api/courses/deleteChat');
            if(res.status === 200) {
                window.location.reload(true);
            }
        } catch(err) {
            alert('Ocurrió un error al eliminar los mensajes del chat, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    const saveChat = async() => {
        // console.log(`l${chatTitle.trim()}l`)
        try {
            if(chatTitle !== '' && "Seleccione el curso") {
                const res = await axios.post('/api/courses/saveOnlineChat', {'title': chatTitle.trim()});
                if(res.status === 200) {
                    alert('El chat ha sido guardado exitosamente');
                    window.location.reload(true);
                }
            }
        } catch(err) {
            alert('Ocurrió un error al guardar el chat, no se encontró el curso, favor de intentarlo más tarde');
            console.log(err);
            setChatTitle('');
        }
    }

    const handleClaveChange = (e) => {
        e.preventDefault();
        setClave(claveForm);
    }

    const getDate = (date) => {
        return moment(date).format('LT');
    }

    /**
     * get courses
     */
    const getcourses = async () =>{
        try {
            const res = await axios.get('/api/courses');
            const result = res.data
            setCursos(result.filter(i => i.status === true))
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getcourses()
        // console.log(cursos)        

    },[cursos])

    return(
        <div style={{marginTop: '60px'}}>
            <div className='d-flex align-items-center'>
                <Link className='btn btn-info ml-2' to={{ pathname: '/admin' }}>
                    Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
                </Link> &nbsp;&nbsp;
                <Button variant='warning' onClick={deleteChat}>
                    <b>Limpiar todos los mensajes   </b>
                </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Form.Control placeholder='Titulo del curso' as="select" style={{width: '300px'}} type='text' value={chatTitle} onChange={(e) => setChatTitle(e.target.value)}>
                    <option>Seleccione el curso</option>
                    {cursos.map(i => (
                        <option key={i._id}>{i.title}</option>
                    ))}
                </Form.Control> &nbsp;&nbsp;
                <Button variant='comp' onClick={saveChat}>Guardar chat en el curso</Button>
            </div>
            <Row className='px-2'>
                <Col lg='6' className='py-md-2 py-0'>
                    <Row>
                        <Col xs='12' md='6' lg='12'>
                            <div style={{backgroundColor: '#18181b', borderRadius: '10px'}} className='p-2 p-lg-3'>
                            <h4 className='text-light'>Todas las preguntas: ({online !== 1 ? `${online} personas en línea` : `${online} persona en línea`})</h4>
                            {/* <p>{props.user}</p> */}
                            <Form onSubmit={(e) => e.preventDefault()} className='d-flex align-items-center'>
                                <TextareaAutosize 
                                    className='form-control'
                                    placeholder="Escribe algo ..." 
                                    onChange={(e) => setMensaje(e.target.value)}
                                    value={mensaje}
                                    // onKeyDown={keyDownHandler}
                                    onKeyUp={keyDownHandler}
                                    style={{backgroundColor: '#000', color: '#fff'}}
                                />
                                <Button variant="comp" onClick={sendMessage} className='ml-1 py-1' style={{minWidth: '100px'}}>
                                    Enviar <i className="fas fa-paper-plane"> </i>
                                </Button>
                            </Form>
                                <div className='mt-3'>
                                    <Scrollbars style={{height: 300}} renderThumbVertical={({ style, ...props }) => 
                                            <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}
                                            renderThumbHorizontal={({ style, ...props }) => 
                                            <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}>
                                        <ul style={{textAlign: "left"}} className='pl-4'>
                                            { messages.map((msg) => (
                                                <li key={msg.key}>
                                                    <Row>
                                                        <Col xs='12'>
                                                            <span className='color-secondary-light text-bold' style={{fontSize: '0.85em'}}>{msg.user}: <span className='text-bold' style={{color: '#b8bfc1'}}>({getDate(msg.key)})</span></span>
                                                        </Col>
                                                        <Col>
                                                            <span className='color-comp-light'>{msg.message}</span>
                                                        </Col>
                                                        <Col xs = '3'>
                                                            <Button name={msg.key} onClick={acceptMessage} className='btn-success p-1 my-1'>Validar ✔</Button> &nbsp;
                                                            <Button name={msg.key} onClick={deleteMessage} className='btn-danger p-1 my-1'>Eliminar ✘</Button>
                                                        </Col>
                                                    </Row>
                                                    <hr style={{borderColor: '#FFFFFF33'}} className='m-2'></hr>
                                                </li>
                                            ))}
                                        </ul>
                                    </Scrollbars>
                                </div>
                            </div>
                            <hr style={{borderColor: '#FFFFFFAA'}} className='d-none d-lg-block'></hr>
                            <hr style={{borderColor: '#FFFFFFAA'}} className='d-block d-md-none'></hr>
                        </Col>
                    
                        <Col xs='12' md='6' lg='12'>
                            <div style={{backgroundColor: '#18181b', borderRadius: '10px'}} className='p-2 p-lg-3'>
                                <h4 className='text-light'>Preguntas para el expositor:</h4>
                                <div className='mt-3'>
                                    <Scrollbars style={{height: 300}} renderThumbVertical={({ style, ...props }) => 
                                            <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}
                                            renderThumbHorizontal={({ style, ...props }) => 
                                            <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}>
                                        <ul style={{textAlign: "left"}} className='pl-4'>
                                            { msgFinal.slice(0).reverse().map((msg) => (
                                                <li key={msg.key}>
                                                    <Row>
                                                        <Col xs='12'>
                                                            <span className='color-secondary-light text-bold' style={{fontSize: '0.85em'}}>{msg.user}: <span className='text-bold' style={{color: '#b8bfc1'}}>({getDate(msg.key)})</span></span>
                                                        </Col>
                                                        <Col>
                                                            <span className='color-comp-light'>{msg.message}</span>
                                                        </Col>
                                                        <Col xs = '3'>
                                                            <Button name={msg.key} onClick={deleteMsgFinal} className='btn-danger p-1 my-1'>Eliminar ✘</Button>
                                                        </Col>
                                                    </Row>
                                                    <hr style={{borderColor: '#FFFFFF33'}} className='m-2'></hr>
                                                </li>
                                            ))}
                                        </ul>
                                    </Scrollbars>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg='6' className='py-md-0 py-2 mt-2'>
                    <hr style={{borderColor: '#FFFFFFAA'}} className='d-block d-lg-none'></hr>
                    <ReactPlayer
                        className='react-player videoPlayer'
                        url = {`${String(process.env.REACT_APP_URL_STREAMING)}/live/${clave}/index.m3u8`}
                        playing = {true}
                        heigh = 'auto'
                        width = '100%'
                        muted={false}
                        loop={false}
                        controls={true}
                    />
                    <StreamingStatus />
                    
                    {/* <Form onSubmit={handleClaveChange} className='ml-2'>
                        <Form.Row>
                            <Form.Group className='d-flex'>
                                <Form.Control
                                    style={{width: '100px'}} 
                                    type='text'
                                    value={claveForm}
                                    placeholder='Clave de transmisión'
                                    onChange={(e) => setClaveForm(e.target.value)}
                                    >
                                </Form.Control>
                                &nbsp;
                                <Button type='submit' variant='sec' style={{width: '260px'}}>Actualizar clave de transmisión</Button>
                            </Form.Group>
                        </Form.Row>
                    </Form> */}
                    <Button variant='comp' onClick={() => window.location.reload(true)}>Actualizar página</Button>
                    <Accordion>
                        <Card style={{borderRadius:'10px'}} className='bg-dark text-light my-3'>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Lista de usuarios en línea:
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <ul>
                                        {userList.map(user => (
                                            <li key={user.id}>{user.user}</li>
                                        ))}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <div className='d-flex align-items-center my-2'>
                        {nms ? <p className='h6 text-success text-bold'>El servidor esta activo para transmisión en vivo</p> : <p className='text-danger h6 text-bold'>El servidor esta deshabilitado para transmisión en vivo</p>}
                        &nbsp;&nbsp;
                        {nms ? <Button variant='danger' onClick={setNodeMediaServer}>Deshabilitar</Button> : <Button variant='success' onClick={setNodeMediaServer}>Habilitar</Button>}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

let streamingSocket;

function StreamingStatus () {
    const [live, setLive] = useState(false);
    const [render, setRender] = useState(<></>);

    useEffect(() => {
        streamingSocket = io('/streaming');
        return () => {
            streamingSocket.close();
        }
    }, [])

    useEffect(() => {
        streamingSocket.on('live', (live) => {
            setLive(live.live);
        })
    }, [streamingSocket])

    useEffect(() => {
        if(live){
            setRender(<div className='text-light h6 text-bold'>Transmisión en curso: &nbsp;<i className='fas fa-circle text-success' style={{transform: 'scale(1.4)'}}></i></div>)
        } else {
            setRender(<div className='text-light h6 text-bold'>Transmisión en curso: &nbsp;<i className='fas fa-circle text-danger' style={{transform: 'scale(1.4)'}}></i></div>)
        }
    }, [live])

    return(
        <div className='my-1'>
            {render}
        </div>
    )
}

AdminChat.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(AdminChat);