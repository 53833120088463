import React, {useEffect, useState} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Form, Button, Row, Col } from 'react-bootstrap';
import * as moment from 'moment';
import * as locale from 'moment/locale/es-us'
import axios from 'axios';

export default function OfflineChat(props) {

    const [messages, setMessages] = useState([]);
    const [render, setRender] = useState(<></>);

    const getMessages = async () => {
        try {
            const res = await axios.get(`/api/courses/${props.id}/getOnlineChat`);
            if(res.status === 200) {
                setMessages(res.data.chat);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getMessages();
    }, [])

    useEffect(() => {
        if(messages.length > 0) {
            setRender(
                <div style={{backgroundColor: '#18181b', borderRadius: '10px'}} className='p-2 p-lg-3'>
                    <h4>Preguntas y comentarios del curso: </h4>
                    <div className='mt-3'>
                        <Scrollbars style={{height: 500}} renderThumbVertical={({ style, ...props }) => 
                                <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}
                                renderThumbHorizontal={({ style, ...props }) => 
                                <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}>
                            <ul style={{textAlign: "left"}} className='pl-4'>
                                { messages.map((msg) => (
                                    <li key={msg.key}>
                                        <Row>
                                            <Col xs='12'>
                                                <span className='color-secondary-light text-bold' style={{fontSize: '0.85em'}}>{msg.user}:  <span className='text-bold' style={{color: '#b8bfc1'}}>({getDate(msg.key)})</span></span>
                                            </Col>
                                            <Col>
                                                <span className='color-comp-light'>{msg.message}</span>
                                            </Col>
                                        </Row>
                                        <hr style={{borderColor: '#FFFFFF33'}} className='m-2'></hr>
                                    </li>
                                ))}
                            </ul>
                        </Scrollbars>
                    </div>
                </div>
            )
        } else {
            setRender(
                <div style={{backgroundColor: '#18181b', borderRadius: '10px'}} className='p-2 p-lg-3'>
                    <h4>No hay preguntas ni comentarios en este curso</h4>
                </div>
            )
        }
    }, [messages])

    const getDate = (date) => {
        const localLocale = moment(date);
        moment.locale('es-us');
        localLocale.locale(false);
        const fecha = localLocale.format('LLLL');
        return fecha.split(',')[1].substring(1);
    }

    return(
        <>
            {render}
        </>
    )
}