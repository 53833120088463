import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import * as moment from 'moment';
let socket = '';

export default function StreamingSocket(props) {
    const [live, setLive] = useState(false);
    const [end, setEnd] = useState(false);

    useEffect(() => {
        socket = io('/streaming');
        courseEnd();
        return () => {
            socket.close();
        }
    }, [])

    useEffect(() => {
        socket.on('live', (live) => {
            courseEnd();
            console.log(live.live, 1);
            setLive(live.live)
            props.live(live.live);
        })
    }, [socket])

    const courseEnd = () => {
        const ahora = new Date()
        const a = moment(props.dateRelease).add(props.duration, 'ms');
        const b = moment(ahora)
        const diff = a.diff(b)
        if(diff <= 0) {
            setEnd(true);
        } else {
            setEnd(false);
        }
    }

    return(
        <div className='my-1'>
            {end ? <p className='text-bold my-0'>El curso en vivo ha terminado</p> : live ? 
                    <p className='text-bold my-0 text-danger'>En vivo <i className="fa fa-video-camera"></i></p> : 
                    <p className='text-bold my-0'>Esperando al expositor ...</p>  }
        </div>
    )

}