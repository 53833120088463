import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import Pagination from 'rc-pagination/es';
import * as pagLocale from 'rc-pagination/es/locale/es_ES';
import 'rc-pagination/assets/index.css';
import { Link } from 'react-router-dom';

export function UserListAsociados(props) {

    const [asociados, setAsociados] = useState([{name: 'user', userType: {noIdentity: '0'}, noRegistro: '0', status: false, _id: 'drs'}]);
    const [index, setIndex] = useState(1);
    const [slice1, setSlice1] = useState(0);
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        setAsociados(props.userList)
    }, [])
    
    const handleChangePage = (page) => {
        setIndex(page);
        setSlice1(page*10 - 10);
    }

    const disableUser = async() => {
        try {
            const res = await axios.post(`/api/users/${currentUser._id}/status`, {'status': !currentUser.status});
            props.reloadPage(<Spinner animation="grow" variant='prim' />)
            if(res.status === 200) {
                const resp = await axios.get('/api/users/userList');
                if(resp.status === 200) {
                    setAsociados(resp.data.asociados);
                    props.reloadPage(<></>);
                }
            }
            
        } catch (err) {
            alert('Ocurrió un error al deshabilitar el curso, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    useEffect(() => {
        if(currentUser !== undefined) {
            disableUser();
        }
    },[currentUser])

    return(
        <>
            <div>
                <h3 className='text-bold my-2 color-prim'>Asociados: </h3>
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={asociados.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                {asociados.slice(slice1, slice1 + 10).map(user => (
                    <li
                        key={user._id}
                        style={{background: user.status ? '' : '#f8523e55'}}
                        className="list-group-item list-group-item-action"
                        >
                            <Row>
                                <Col md='8'>
                                    <p className='h5 text-bold' style={{color: 'black'}}>{user.name}</p>
                                    <hr className='my-1'></hr>
                                    <p className='h6 my-1'>No. Asociado: <b>{user.userType.noIdentity}</b> &nbsp; Estatus: <b className={user.status ? 'text-success' : 'text-danger'}>{user.status ? 'Activo' : 'Suspendido'}</b></p>
                                    <p className='h6 my-0'>No. Registro: {user.noRegistro}</p>
                                </Col>
                                <Col md='4'>
                                    <Link className='btn btn-comp my-2' to={{ pathname: '/admin/usuarios/detalles', state: {id: user._id} }} >
                                        Detalles &nbsp; <i className="far fa-id-card"></i>
                                    </Link> &nbsp;
                                    <Button variant='secondary' onClick={() => setCurrentUser(user)}>
                                        {user.status ? 'Suspender cuenta' : 'Habilitar cuenta'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                                    </Button>
                                </Col>
                            </Row>
                    </li>
                ))}
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={asociados.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                <hr></hr>
            </div>
        </>
    )
}

export function UserListApoyoMutuo(props) {

    const [apoyoMutuo, setApoyoMutuo] = useState(Array);
    const [index, setIndex] = useState(1);
    const [slice1, setSlice1] = useState(0);
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        setApoyoMutuo(props.userList)
    }, [])
    
    const handleChangePage = (page) => {
        setIndex(page);
        setSlice1(page*10 - 10);
    }

    const disableUser = async() => {
        try {
            const res = await axios.post(`/api/users/${currentUser._id}/status`, {'status': !currentUser.status});
            props.reloadPage(<Spinner animation="grow" variant='prim' />)
            if(res.status === 200) {
                const resp = await axios.get('/api/users/userList');
                if(resp.status === 200) {
                    setApoyoMutuo(resp.data.apoyoMutuo);
                    props.reloadPage(<></>);
                }
            }
            
        } catch (err) {
            alert('Ocurrió un error al deshabilitar el curso, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    useEffect(() => {
        if(currentUser !== undefined) {
            disableUser();
        }
    },[currentUser])

    return(
        <>
            <div>
                <h3 className='text-bold my-2 color-prim'>Apoyo Mutuo: </h3>
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={apoyoMutuo.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                    {apoyoMutuo.slice(slice1, slice1 + 10).map(user => (
                        <li
                            style={{background: user.status ? '' : '#f8523e55'}}
                            className="list-group-item list-group-item-action"
                            key={user._id}>
                                <Row>
                                    <Col md='8'>
                                        <p className='h5 text-bold' style={{color: 'black'}}>{user.name}</p>
                                        <hr className='my-1'></hr>
                                        <p className='h6 my-1'>No. Asociado: <b>{user.userType.noIdentity}</b> &nbsp; Estatus: <b className={user.status ? 'text-success' : 'text-danger'}>{user.status ? 'Activo' : 'Suspendido'}</b></p>
                                        <p className='h6 my-0'>No. Registro: {user.noRegistro}</p>
                                    </Col>
                                    <Col md='4'>
                                        <Link className='btn btn-comp my-2' to={{ pathname: '/admin/usuarios/detalles', state: {id: user._id} }} >
                                            Detalles &nbsp; <i className="far fa-id-card"></i>
                                        </Link> &nbsp;
                                        <Button variant='secondary' onClick={() => setCurrentUser(user)}>
                                            {user.status ? 'Suspender cuenta' : 'Habilitar cuenta'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                                        </Button>
                                    </Col>
                                </Row>
                        </li>
                    ))}
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={apoyoMutuo.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                <hr></hr>
            </div>
        </>
    )
}

export function UserListEstudiantes(props) {

    const [estudiantes, setEstudiantes] = useState(Array);
    const [index, setIndex] = useState(1);
    const [slice1, setSlice1] = useState(0);
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        setEstudiantes(props.userList)
    }, [])
    
    const handleChangePage = (page) => {
        setIndex(page);
        setSlice1(page*10 - 10);
    }

    const disableUser = async() => {
        try {
            const res = await axios.post(`/api/users/${currentUser._id}/status`, {'status': !currentUser.status});
            props.reloadPage(<Spinner animation="grow" variant='prim' />)
            if(res.status === 200) {
                const resp = await axios.get('/api/users/userList');
                if(resp.status === 200) {
                    setEstudiantes(resp.data.estudiantes);
                    props.reloadPage(<></>);
                }
            }
            
        } catch (err) {
            alert('Ocurrió un error al deshabilitar el curso, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    useEffect(() => {
        if(currentUser !== undefined) {
            disableUser();
        }
    },[currentUser])

    return(
        <>
            <div>
                <h3 className='text-bold my-2 color-prim'>Estudiantes: </h3>
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={estudiantes.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                    {estudiantes.slice(slice1, slice1 + 10).map(user => (
                        <li
                            style={{background: user.status ? '' : '#f8523e55'}}
                            className="list-group-item list-group-item-action"
                            key={user._id}>
                                <Row>
                                    <Col md='8'>
                                        <p className='h5 text-bold' style={{color: 'black'}}>{user.name}</p>
                                        <hr className='my-1'></hr>
                                        <p className='h6 my-1'>No. Asociado: <b>{user.userType.noIdentity}</b> &nbsp; Estatus: <b className={user.status ? 'text-success' : 'text-danger'}>{user.status ? 'Activo' : 'Suspendido'}</b></p>
                                        <p className='h6 my-0'>No. Registro: {user.noRegistro}</p>
                                    </Col>
                                    <Col md='4'>
                                        <Link className='btn btn-comp my-2' to={{ pathname: '/admin/usuarios/detalles', state: {id: user._id} }} >
                                            Detalles &nbsp; <i className="far fa-id-card"></i>
                                        </Link> &nbsp;
                                        <Button variant='secondary' onClick={() => setCurrentUser(user)}>
                                            {user.status ? 'Suspender cuenta' : 'Habilitar cuenta'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                                        </Button>
                                    </Col>
                                </Row>
                        </li>
                    ))}
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={estudiantes.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                <hr></hr>
            </div>
        </>
    )
}

export function UserListPublicoEnGeneral(props) {

    const [publicoEnGeneral, setPublicoEnGeneral] = useState(Array);
    const [index, setIndex] = useState(1);
    const [slice1, setSlice1] = useState(0);
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        setPublicoEnGeneral(props.userList)
    }, [])
    
    const handleChangePage = (page) => {
        setIndex(page);
        setSlice1(page*10 - 10);
    }

    const disableUser = async() => {
        try {
            const res = await axios.post(`/api/users/${currentUser._id}/status`, {'status': !currentUser.status});
            props.reloadPage(<Spinner animation="grow" variant='prim' />)
            if(res.status === 200) {
                const resp = await axios.get('/api/users/userList');
                if(resp.status === 200) {
                    setPublicoEnGeneral(resp.data.publicoEnGeneral);
                    props.reloadPage(<></>);
                }
            }
            
        } catch (err) {
            alert('Ocurrió un error al deshabilitar el curso, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    useEffect(() => {
        if(currentUser !== undefined) {
            disableUser();
        }
    },[currentUser])

    return(
        <>
            <div>
                <h3 className='text-bold my-2 color-prim'>Público en General: </h3>
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={publicoEnGeneral.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                    {publicoEnGeneral.slice(slice1, slice1 + 10).map(user => (
                        <li
                            style={{background: user.status ? '' : '#f8523e55'}}
                            className="list-group-item list-group-item-action"
                            key={user._id}>
                                <Row>
                                    <Col md='8'>
                                        <p className='h5 text-bold' style={{color: 'black'}}>{user.name}</p>
                                        <hr className='my-1'></hr>
                                        <p className='h6 my-1'>No. Asociado: <b>{user.userType.noIdentity}</b> &nbsp; Estatus: <b className={user.status ? 'text-success' : 'text-danger'}>{user.status ? 'Activo' : 'Suspendido'}</b></p>
                                        <p className='h6 my-0'>No. Registro: {user.noRegistro}</p>
                                    </Col>
                                    <Col md='4'>
                                        <Link className='btn btn-comp my-2' to={{ pathname: '/admin/usuarios/detalles', state: {id: user._id} }} >
                                            Detalles &nbsp; <i className="far fa-id-card"></i>
                                        </Link> &nbsp;
                                        <Button variant='secondary' onClick={() => setCurrentUser(user)}>
                                            {user.status ? 'Suspender cuenta' : 'Habilitar cuenta'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                                        </Button>
                                    </Col>
                                </Row>
                        </li>
                    ))}
                <Pagination
                    className='my-2'
                    showTotal={(total, range) =>
                        `${range[0]} - ${range[1]} de ${total} elementos`
                    }
                    total={publicoEnGeneral.length}
                    prevIcon={<i className='fas fa-angle-left'></i>}
                    jumpPrevIcon={<i className='fas fa-angle-double-left'></i>}
                    nextIcon={<i className='fas fa-angle-right'></i>}
                    jumpNextIcon={<i className='fas fa-angle-double-right'></i>}
                    locale={pagLocale.default}
                    onChange={handleChangePage}
                    defaultCurrent={1}
                    current={index}
                    style={{fontSize: '1em'}}
                    pageSize={10}
                />
                <hr></hr>
            </div>
        </>
    )
}

export function UserListSearch(props) {

    // const [search, setSearch] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [render, setRender] = useState(<></>);

    useEffect(() => {
        if(props.userList !== undefined) {
            setRender(
                <div>
                    {props.userList[0].map(item => (
                        <li
                            style={{background: item.status ? '' : '#f8523e55'}}
                            className="list-group-item list-group-item-action"
                            key={item._id}>
                                <Row>
                                    <Col md='8'>
                                        <p className='h5 text-bold' style={{color: 'black'}}>{item.name}</p>
                                        <hr className='my-1'></hr>
                                        <p className='h6 my-1'>No. Asociado: <b>{item.userType.noIdentity}</b> &nbsp; Estatus: <b className={item.status ? 'text-success' : 'text-danger'}>{item.status ? 'Activo' : 'Suspendido'}</b></p>
                                        <p className='h6 my-0'>No. Registro: {item.noRegistro}</p>
                                    </Col>
                                    <Col md='4'>
                                        <Link className='btn btn-comp my-2' to={{ pathname: '/admin/usuarios/detalles', state: {id: item._id} }} >
                                            Detalles &nbsp; <i className="far fa-id-card"></i>
                                        </Link> &nbsp;
                                        <Button variant='secondary' onClick={() => setCurrentUser(item)}>
                                            {item.status ? 'Suspender cuenta' : 'Habilitar cuenta'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                                        </Button>
                                    </Col>
                                </Row>
                        </li>
                    ))}
                </div>)
        } else {
            setRender(<></>);
        }
    }, [props.userList])

    const disableUser = async() => {
        
        try {
            const res = await axios.post(`/api/users/${currentUser._id}/status`, {'status': !currentUser.status});
            props.reloadPage(<Spinner animation="grow" variant='prim' />)
            if(res.status === 200) {
                // const resp = await axios.get('/api/users/' + search._id);
                // if(resp.status === 200) {
                //     setSearch(resp.data);
                //     props.reloadPage(<></>);
                // }
                // setSearch(props.userList)
                props.reloadPage(<></>);
            }
            
        } catch (err) {
            alert('Ocurrió un error al deshabilitar al usuario, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    useEffect(() => {
        if(currentUser !== null ) {
            disableUser();
        }
    },[currentUser])

    return(
        <>
            <div>
                <h3 className='text-bold my-2 color-prim'>Resultado de la búsqueda: </h3>
                        {render}
                <hr></hr>
            </div>
        </>
    )
}