import React, { Component } from "react";
import { Link } from "react-router-dom";

class Landing extends Component {
  render() {
    return (
      <div style={{ marginTop: "60px" }} className="container">
        <div className="row text-center ">
          <div className="col-12 center-align mt-0 mt-md-3 mt-xl-5">
            <img src="./images/comexcol-complete.svg" alt='' style={{width: "25%"}}></img>
            <h4 className='mt-4'>
              Bienvenido a la plataforma de cursos en línea del <br/><b> COLEGIO MEXICANO DE CONTADORES PUBLICOS Y LICENCIADOS EN CONTADURIA, A.C.</b>
            </h4>
            <p className="flow-text grey-text text-darken-1">
              Inicia sesión o regístrate para ver la lista de cursos que tenemos disponibles.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mt-0 mt-md-2 mt-xl-3">
            <Link to="/login"className="btn btn-sec">
                Iniciar Sesión &nbsp; <i className="fas fa-sign-in-alt"></i>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to="/register" className="btn btn-comp">
                Registrarme &nbsp; <i className="fas fa-user-plus"></i>
            </Link>
          </div>
        </div>        
        <br></br>
      </div>
    );
  }
}

export default Landing;
