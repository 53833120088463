import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Form, Button, Row, Col } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { Scrollbars } from 'react-custom-scrollbars';
import * as moment from 'moment';
let socket = '';

export default function Chat (props) {
    const [messages, setMessages] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [online, setOnline] = useState(0);

    useEffect(() => {
        try {
            socket = io('/userChat');
            socket.emit('online', props);
        } catch(err) {}
        return () => {
            try {
                socket.emit('offline', props.user);
                socket.close();
            } catch(err) {}
        }
    }, [])

    useEffect(() => {
        
        if(socket.connected ){
            try {
                socket.emit('online', props);
            } catch (error) {}
        }
    },[socket.connected])
    
    useEffect(() => {
        try {
            socket.on('msg', (msg) => {
                // Para que el usuario solo pueda ver sus mensajes y los del Admin
                let d = [];
                msg.forEach(m => {
                    if(m.user === props.user || m.user === 'Administración') {
                        d.push(m);
                    }
                })
                setMessages(d);
            })
        } catch (err) {}

        try {
            socket.on('users', (usrs) => {
                setOnline(usrs);
            })
        } catch (err) {}
    }, [socket]);

    const sendMessage = () => {
        if(mensaje !== '' && mensaje !== '\n') {
            try {
                socket.emit('msg', {user: props.user, message: mensaje});
            } catch (err) {}
        }
        setMensaje('');
    }

    const keyDownHandler = (e) => {
        if(e.wich === 13 || e.keyCode === 13){
            sendMessage();
        }
    }

    const getDate = (date) => {
        return moment(date).format('LT');
    }

    return(
        <div style={{backgroundColor: '#18181b', borderRadius: '10px'}} className='p-2 p-lg-3'>
            <h4>Preguntas y comentarios: ({online !== 1 ? `${online} personas en línea` : `${online} persona en línea`})</h4>
            <p>{props.user}</p>
            <Form onSubmit={(e) => e.preventDefault()} className='d-flex align-items-center'>
                <TextareaAutosize 
                    className='form-control'
                    placeholder="Escribe algo ..." 
                    onChange={(e) => setMensaje(e.target.value)}
                    value={mensaje}
                    // onKeyDown={keyDownHandler}
                    onKeyUp={keyDownHandler}
                    style={{backgroundColor: '#000', color: '#fff'}}
                />
                <Button variant="comp" onClick={sendMessage} className='ml-1 py-1' style={{minWidth: '100px'}}>
                    Enviar <i className="fas fa-paper-plane"> </i>
                </Button>
            </Form>
            <div className='mt-3'>
                <Scrollbars style={{height: 500}} renderThumbVertical={({ style, ...props }) => 
                        <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}
                        renderThumbHorizontal={({ style, ...props }) => 
                        <div {...props} style={{...style, backgroundColor: '#f7f7ff88', borderRadius: '20px'}} />}>
                    <ul style={{textAlign: "left"}} className='pl-4'>
                        { messages.slice(0).reverse().map((msg) => (
                            <li key={msg.key}>
                                <Row>
                                    <Col xs='12'>
                                        <span className='color-secondary-light text-bold' style={{fontSize: '0.85em'}}>{msg.user}:  <span className='text-bold' style={{color: '#b8bfc1'}}>({getDate(msg.key)})</span></span>
                                    </Col>
                                    <Col>
                                        <span className='color-comp-light'>{msg.message}</span>
                                    </Col>
                                </Row>
                                <hr style={{borderColor: '#FFFFFF33'}} className='m-2'></hr>
                            </li>
                        ))}
                    </ul>
                </Scrollbars>
            </div>
        </div>
    )
}

