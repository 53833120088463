import React , {useEffect, useState} from 'react'
import axios from 'axios';
import { Button, ButtonGroup, Form,Col,Tab,Tabs,Container,Row,ListGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom';
import { Bar,Line, Pie,HorizontalBar } from 'react-chartjs-2'
import { BsGraphUp } from "react-icons/bs";
import { GiHistogram } from "react-icons/gi";

import PropTypes, { elementType } from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

/**
 * @path /admin/estadisticas
 * Shows the statistics of users and courses
 */
function Estadistica(props) {

    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(Date.now());
    const [courses,setCourses] = useState(null)
    const [data,setData] = useState({})
    const [render,setRender] = useState(<></>)
    const [insc,setInsc] = useState(null)
    const [dataCour,setDataCour] = useState(null)
    const [inscCom,setInscCom] = useState(null)
    const [inscNcom,setInscNcom] = useState(null)
    const [data2,setData2] = useState({})
    const [userType,setUserType] = useState(null)
    const [data3,setData3] = useState({})
    const [asociado,setAsociado] = useState(null)
    const [estudiante,setEstudiante] = useState(null)
    const [apoyo,setApoyo] = useState(null)
    const [publico,setPublico] = useState(null)
    const [data4,setData4] = useState({})
    const [free,setFree] = useState(null)
    const [nFree,setNFree] = useState(null)
    const [data5,setData5] = useState({})
    const [cursoF,setCursoF] = useState(null)
    const [numCF,setNumCF] = useState(null)
    const [cursoNF,setCursoNF] = useState(null)
    const [numCNF,setNumCNF] = useState(null)
    const [data6,setData6] = useState({})
    const [data7,setData7] = useState({})
    const [data8,setData8] = useState({})
    const [data9,setData9] = useState({})
    const [data10,setData10] = useState({})
    const [data11,setData11] = useState({})
    const [data12,setData12] = useState({})
    const [cursoE,setCursoE] = useState('Seleccione el curso')
    const [renderE,setRenderE] = useState(<></>)
    const [dataE1,setDataE1] = useState({})
    const [dataE2,setDataE2] = useState({})
    const [dataE3,setDataE3] = useState({})
    const [dataE4,setDataE4] = useState({})
    const [dataE5,setDataE5] = useState([])
    const [dataE6,setDataE6] = useState({})
    const [dataE7,setDataE7] = useState({})
    const [dataE8,setDataE8] = useState({})
    const [dataE9,setDataE9] = useState({})
    const [dataE10,setDataE10] = useState([])
    const [bandera,setBandera] = useState(false)
 
    /**
     * Admin permissions
     */
    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/home");
        }
    })
    
    /**
     * Obtain the total number of users and courses
     */
    useEffect(() => {
        const datos = async() => {
            try {
                const res = await axios.get('/api/courses/')
                const res2 = await axios.get('/api/users/')
                generateGraphP(res.data.length,res2.data.length)
            } catch (error) {
                console.log(error)
            }  
        }
        datos()
            
    },[])

    /**
     * The option configuration for bar graphs
     */
    const options = {title:{display:true,text: 'Demanda de cursos',position:'bottom'},
                    scales: {xAxes: [{gridLines: {offsetGridLines: true},ticks: {display:false,}}],
                            yAxes: [{ticks: {min:0}}]} }
    
    const options2 = {title:{display:true,text: 'Asistencia en cada curso',position:'bottom'},
                    scales: {xAxes: [{gridLines: {offsetGridLines: true},ticks: {display:false,}}],
                            yAxes: [{ticks: {min:0}}]} }    
    
    const options3 = {title:{display:true,text: 'Cantidad de alumnos por tipo de usuario (por curso)',position:'bottom'},
                    scales: {xAxes: [{gridLines: {offsetGridLines: true},ticks: {display:false,}}],
                            yAxes: [{ticks: {min:0}}]} } 
    
    const options4 = {title:{display:true,text: 'Usuarios inscritos en cursos gratis',position:'bottom'},
                    scales: {xAxes: [{gridLines: {offsetGridLines: true},ticks: {display:false,}}],
                            yAxes: [{ticks: {min:0}}]} } 

    const options5 = {title:{display:true,text: 'Usuarios inscritos en cursos con costo',position:'bottom'},
                    scales: {xAxes: [{gridLines: {offsetGridLines: true},ticks: {display:false,}}],
                            yAxes: [{ticks: {min:0}}]} } 

    const options6 = {title:{display:true,text: 'Usuarios inscritos en la primera sesión del curso',position:'bottom'},
                    scales: {xAxes: [{gridLines: {offsetGridLines: true},ticks: {display:false,}}],
                            yAxes: [{ticks: {min:0}}]} } 
    
    const options7 = {title:{display:true,text: 'Usuarios inscritos en la segunda sesión del curso',position:'bottom'},
                    scales: {xAxes: [{gridLines: {offsetGridLines: true},ticks: {display:false,}}],
                            yAxes: [{ticks: {min:0}}]} }
        
    /**
     * The option configuration for pie graphs
     */
    const optionsPie = {
        title: {
            display: true,
            text:'Cantidad de alumnos por tipo de usuario',
            position:'bottom',
            padding:30
        }
    }

    const optionsPie2 = {
        title: {
            display: true,
            text:'Cantidad de alumnos inscritos en cursos gratis y con costo',
            position:'bottom',
            padding:30
        }
    }

    const optionsPie3 = {
        title: {
            display: true,
            text:'Cantidad de alumnos inscritos por sesión',
            position:'bottom',
            padding:30
        }
    }

    const optionsPieE1 = {
        title: {
            display: true,
            text:'Calificación al desempeño del expositor',
            position:'bottom',
            padding:30
        }
    }
    const optionsPieE2 = {
        title: {
            display: true,
            text:'Opinión respecto a la dinámica del evento',
            position:'bottom',
            padding:30
        }
    }
    const optionsPieE3 = {
        title: {
            display: true,
            text:'Calificación del evento',
            position:'bottom',
            padding:30
        }
    }
    const optionsPieE4 = {
        title: {
            display: true,
            text:'Contenido del evento',
            position:'bottom',
            padding:30
        }
    }
    const optionsPieE6 = {
        title: {
            display: true,
            text:'Duración del evento',
            position:'bottom',
            padding:30
        }
    }
    const optionsPieE7 = {
        title: {
            display: true,
            text:'Experiencia en la plataforma',
            position:'bottom',
            padding:30
        }
    }
    const optionsPieE8 = {
        title: {
            display: true,
            text:'Acceso a los cursos',
            position:'bottom',
            padding:30
        }
    }
    const optionsPieE9 = {
        title: {
            display: true,
            text:'Facilidad en la navegación en la plataforma',
            position:'bottom',
            padding:30
        }
    }
    /**
     * The option configuration for principal graph
     */
    const optionsP = {maintainAspectRatio: false,
                    title:{display:true,text: 'Estado de la página',fontStyle:'bold',fontColor:'#000',padding:20,fontSize:15,},
                    legend:{display:false},
                    scales: {yAxes:[{stacked: true,gridLines:{borderDash:[8]}}],
                            xAxes:[{gridLines: {offsetGridLines: true},ticks: {min:0,max:400,stepSize:20}}] },}

    /**
     * executes getTitle and getInsc to obtain the courses data and users data
     */
    useEffect(() => {
        if(courses !== null){
            getTitle(courses)
            getInsc(courses)
        }
    },[courses])

    /**
     * executes methods to generate graphs
     */
    useEffect(() => {
        if(dataCour,insc,inscCom,inscNcom,userType !== null
            && asociado,estudiante,apoyo,publico,free,nFree,cursoF,cursoNF,numCF,numCNF !== null ){
            
            generateGraph1(dataCour,insc)
            generateGraph2(dataCour,inscCom,inscNcom)
            generateGraph3(userType)
            generateGraph4(dataCour,asociado,estudiante,apoyo,publico)
            generateGraph5(nFree,free)
            generateGraph6(cursoF,cursoNF,numCF,numCNF)
            generateGraph7(courses,insc)

        }
    },[dataCour,insc,inscCom,inscNcom,userType,asociado,estudiante,apoyo,publico,free,nFree,cursoF,cursoNF,numCF,numCNF])

    /**
     * get the titles of courses
     * @param courses 
     */
    const getTitle = courses => {
        
        let cursos = [], cursoG = [], cursoNG =[]
        courses.map(course => {
            cursos.push(course.title)
            
            if(course.cursoGratuito === true){
                cursoG.push(course.title)
            }else{
                cursoNG.push(course.title)
            }

        })
        
        setDataCour(cursos)
        //obtain free and fee courses 
        setCursoF(cursoG)
        setCursoNF(cursoNG)
        
    }

    /**
     * get details of users
     * @param courses 
     */
    const getInsc = async(courses) => {
        
        let inscritos = []
        let inscC = [], inscN = []   
        let uType = [0,0,0,0]  
        let alumType = []  
        let asoc = [], est = [], apoy = [], publ = []
        let gratis = 0, noGratis = 0
        let freeA = [] , noFreeA = []

        for await (let course of courses) {
            try {
                let com = 0, as = 0, es = 0, ap = 0, pu = 0
                
                const res = await axios.post(`/api/users/${course._id}/listaAlumnos`)
                inscritos.push((res.data).length)
                
                const alumnos = res.data
                
                if(course.cursoGratuito === true){
                    gratis = gratis + alumnos.length
                    freeA.push(alumnos.length)
                }else{
                    noGratis = noGratis + alumnos.length
                    noFreeA.push(alumnos.length)
                }

                alumnos.map(alumno => {
                    
                    const completed = alumno.courses.filter(x => x.id === course._id)[0].completed
                    if(completed){
                        com +=1
                    }
                    
                    if(!(alumType.find(x => x === alumno._id))){
                        alumType.push(alumno._id)
                        if('Asociado' === alumno.userType.type){
                            uType[0] +=1
                        }else if('Estudiante' === alumno.userType.type){
                            uType[1] +=1
                        }else if('Apoyo Mutuo' === alumno.userType.type){
                            uType[2] +=1
                        }else if('Público en General' === alumno.userType.type){
                            uType[3] +=1
                        }
                    }

                    if('Asociado' === alumno.userType.type){
                        as +=1
                    }else if('Estudiante' === alumno.userType.type){
                        es +=1
                    }else if('Apoyo Mutuo' === alumno.userType.type){
                        ap +=1
                    }else if('Público en General' === alumno.userType.type){
                        pu +=1
                    }
                })

                inscC.push(com)
                inscN.push(alumnos.length - com)

                asoc.push(as)
                est.push(es)
                apoy.push(ap)
                publ.push(pu)
                
            } catch (error) {
                
            }
        }
        
        setUserType(uType)
        setInscCom(inscC)
        setInscNcom(inscN)
        setInsc(inscritos)
        setAsociado(asoc)
        setEstudiante(est)
        setApoyo(apoy)
        setPublico(publ)
        setFree(gratis)
        setNFree(noGratis)
        setNumCF(freeA)
        setNumCNF(noFreeA)
    }

    /**
     * event to generate the courses data
     */
    const handleSubmit = async()=>{
        try {
            const res = await axios.post('/api/courses/coursesDate',{'fechaInicio': fechaInicio, 'fechaFin': fechaFin})
            if(res.status===200){
                setCourses(res.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    /**
     * Generate the graph "Demanda de cursos" 
     * @params cursos, inscritos 
     */
    const generateGraph1 = (cursos,inscritos) => {
        
        setData({
            labels:cursos,
            datasets: [
                {
                    label: 'Inscritos',
                    data: inscritos,
                    borderColor: 'rgba(54,15,77,1)',
                    borderWidth:1,
                    backgroundColor: 'rgba(54,15,77,0.7)',
                    hoverBackgroundColor: 'rgba(86,22,125,0.9)',
                    
                }
            ]
        }) 
    }

    /**
     * Generate the graph "Asistencia por curso" 
     * @params cursos,inscritosC,inscritosNc
     */
    const generateGraph2 = (cursos,inscritosC,inscritosNc) => {
        setData2 ({
            labels:cursos,
            datasets: [
                {
                    label: 'Alumnos con curso completado',
                    data: inscritosC,
                    borderColor: 'rgba(47,167,125,1)',
                    borderWidth: 1,
                    backgroundColor: 'rgba(47,167,125,0.7)',
                    hoverBackgroundColor: 'rgba(47,167,125,0.9)'
                },
                {
                    label: 'Alumnos con curso no completado',
                    data: inscritosNc,
                    borderColor: 'rgba(234,102,106,1)',
                    borderWidth: 1,
                    backgroundColor: 'rgba(234,102,106,0.7)',
                    hoverBackgroundColor: 'rgba(234,102,106,0.9)'
                }
            ]
        })
    }

    /**
     * Generate the graph "Cantidad de alumnos por tipo de usuario" 
     * @params users
     */
    const generateGraph3 = (users) => {
        
        setData3({
            labels: ['Asociado','Estudiante','Apoyo Mutuo','Público en General'],
            datasets: [
                {
                    label: 'Tipos de usuario',
                    data: users,
                    backgroundColor: ['rgba(0,63,92,0.7)',
                                    'rgba(80,80,141,0.7)',
                                    'rgba(188,80,144,0.7)',
                                    'rgba(255, 99, 97, 0.7)'],
                    hoverBackgroundColor:['rgba(0,63,92,0.9)',
                                        'rgba(80,80,141,0.9)',
                                        'rgba(188,80,144,0.9)',
                                        'rgba(255, 99, 97, 0.9)'],
                    borderColor: ['rgba(0,63,92, 1)',
                                'rgba(80,80,141,1)',
                                'rgba(188,80,144,1)',
                                'rgba(255, 99, 97, 1)'],
                    borderWidth:1
                }
            ]
        }) 
    }

    /**
     * Generate the graph "Cantidad de alumnos por tipo de usuario (por curso)" 
     * @params cursos,asociado,estudiante,apoyo,publico
     */
    const generateGraph4 = (cursos,asociado,estudiante,apoyo,publico) => {
        setData4 ({
            labels:cursos,
            datasets: [
                {
                    label: 'Asociado',
                    data: asociado,
                    borderColor: 'rgba(0,63,92, 1)',
                    backgroundColor: 'rgba(0,63,92,0.7)',
                    hoverBackgroundColor: 'rgba(0,63,92,0.9)',
                    borderWidth:1
                },
                {
                    label: 'Estudiante',
                    data: estudiante,
                    borderColor: 'rgba(80,80,141,1)',
                    backgroundColor: 'rgba(80,80,141,0.7)',
                    hoverBackgroundColor: 'rgba(80,80,141,0.9)',
                    borderWidth:1
                },
                {
                    label: 'Apoyo Mutuo',
                    data: apoyo,
                    borderColor: 'rgba(188,80,144,1)',
                    backgroundColor: 'rgba(188,80,144,0.7)',
                    hoverBackgroundColor: 'rgba(188,80,144,0.9)',
                    borderWidth:1
                },
                {
                    label: 'Público en General',
                    data: publico,
                    borderColor: 'rgba(255, 99, 97, 1)',
                    backgroundColor: 'rgba(255, 99, 97, 0.7)',
                    hoverBackgroundColor: 'rgba(255, 99, 97, 0.9)',
                    borderWidth:1
                }
            ]
        })
    }

    /**
     * Generate the graph "Cantidad de alumnos inscritos en cursos gratis y con costo" 
     * @params pagados,gratis
     */
    const generateGraph5 = (pagados,gratis) => {
        
        setData5({
            labels:['Cursos con costo','Cursos gratis'],
            datasets: [
                {
                    label: 'Inscritos',
                    data: [pagados,gratis],
                    borderColor: ['rgba(117,60,156, 1)',
                                'rgba(185,137,212,1)'],
                    backgroundColor: ['rgba(117,60,156, 0.7)',
                                    'rgba(185,137,212,0.7)'],
                    hoverBackgroundColor: ['rgba(117,60,156, 0.9)',
                                        'rgba(185,137,212,0.9)'],
                    borderWidth:1
                }
            ]
        }) 
    }

    /**
     * Generate the graphs "Usuarios inscritos en cursos gratis" and "Usuarios inscritos en cursos con costo"
     * @params cursoGratis,cursoNoGratis,numCursoGratis,numCursoNoGratis
     */
    const generateGraph6 = (cursoGratis,cursoNoGratis,numCursoGratis,numCursoNoGratis) => {
        setData6({
            labels: cursoGratis,
            datasets:[
                {
                    label: 'Cursos Gratis',
                    data : numCursoGratis,
                    borderColor: 'rgba(185,137,212,1)',
                    backgroundColor: 'rgba(185,137,212,0.7)',
                    hoverBackgroundColor: 'rgba(185,137,212,9)',
                    borderWidth:1
                }
            ]
        })
        setData7({
            labels: cursoNoGratis,
            datasets:[
                {
                    label: 'Cursos con costo',
                    data:numCursoNoGratis,
                    borderColor: 'rgba(117,60,156, 1)',
                    backgroundColor: 'rgba(117,60,156,0.7)',
                    hoverBackgroundColor: 'rgba(117,60,156, 0.9)',
                    borderWidth:1
                }
            ]
        })
    }

    /**
     * generate the graphs
     * "Cantidad de alumnos inscritos por sesión"
     * "Usuarios inscritos en la primera sesión del curso"
     * "Usuarios inscritos en la segunda sesión del curso"
     * @params cursos,inscritos 
     */
    const generateGraph7 = (cursos,inscritos) =>{
        let i=0, s1=0, s2=0 ,sesion1=[], sesion2=[], numS1=[], numS2=[],u1=[],uI=[]

        cursos.map(curso => {
            
            if(curso.sesion.flag === true){

                if(curso.sesion.number === 1){
                    sesion1.push(curso.title)
                    numS1.push(inscritos[i])
                    s1 = s1 + inscritos[i]
                }
                if(curso.sesion.number === 2){
                    sesion2.push(curso.title)
                    numS2.push(inscritos[i])
                    s2 = s2 + inscritos[i]
                }

            }else{
                u1.push(curso.title)
                uI.push(inscritos[i])
            }
            i++
        })

        //generate the graph "Cantidad de alumnos inscritos por sesión"
        setData8({
            labels: ['Primer sesión',"Segunda sesión"],
            datasets:[
                {
                    label: 'asistencia',
                    data:[s1,s2],
                    borderColor: ['rgba(66,118,89,1)',
                                'rgba(84,178,126,1'],
                    backgroundColor: ['rgba(66,118,89,0.7)',
                                    'rgba(84,178,126,0.7)'],
                    hoverBackgroundColor: ['rgba(66,118,89,0.9)',
                                        'rgba(84,178,126,0.9)'],
                    borderWidth:1
                }
            ]
        })

        //generate the graph "Usuarios inscritos en la primera sesión del curso"
        setData9({
            labels:sesion1,
            datasets:[
                {
                    label:'sesión 1',
                    data:numS1,
                    borderColor: 'rgba(66,118,89,1)',
                    backgroundColor: 'rgba(66,118,89,0.7)',
                    hoverBackgroundColor: 'rgba(66,118,89,0.9)',
                    borderWidth:1
                }
            ]
        })

        //generate the graph "Usuarios inscritos en la segunda sesión del curso"
        setData10({
            labels:sesion2,
            datasets:[
                {
                    label:'sesión 2',
                    data:numS2,
                    borderColor: 'rgba(84,178,126,1)',
                    backgroundColor: 'rgba(84,178,126,0.7)',
                    hoverBackgroundColor: 'rgba(84,178,126,0.9)',
                    borderWidth:1
                }
            ]
        })
    }
    
    /**
     * Generate the principal graph
     * @params cursos,usuarios 
     */
    const generateGraphP = (cursos,usuarios) => {
        setData11({
            labels:['Cursos','Usuarios registrados'],
            datasets: [
                {
                    barThickness: 6,
                    maxBarThickness: 6,
                    data: [cursos,usuarios],
                    borderColor: 'rgba(81,31,61,1)',
                    backgroundColor: 'rgba(81,31,61,0.9)'
                }
            ]
        })
    }

    useEffect(()=> {
        if(bandera === true){
            setRenderE(<div style={{paddingTop:"50px",margin:'auto'}}>
                <Col>
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">1. ¿Cómo califica el desempeño del expositor?</p>
                        </Row>
                        <Row>
                            <Pie data={dataE1} options={optionsPieE1}/>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">2. ¿Cuál es su opinión respecto de la dinámica del evento?</p>
                        </Row>
                        <Row>
                            <Pie data={dataE2} options={optionsPieE2}/>
                        </Row>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">3. ¿Cómo califica al evento?</p>
                        </Row>
                        <Row>
                            <Pie data={dataE3} options={optionsPieE3}/>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">4. ¿Le es útil el contenido del evento?</p>
                        </Row>
                        <Row>
                            <Pie data={dataE4} options={optionsPieE4}/>
                        </Row>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">5. ¿Por qué?</p>
                        </Row>
                        <Row>
                            <div style={{height:'300px',width:'90%'}}>
                                <ListGroup style={{width:'90%',height:'300px',overflowY:'auto'}}>
                                    {dataE5.map((i,key) => (
                                        <ListGroup.Item key={key}>{i}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">6. La duración del evento fue:</p>
                        </Row>
                        <Row>
                            <Pie data={dataE6} options={optionsPieE6}/>
                        </Row>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">7. ¿Cómo califica la experiencia en la plataforma?</p>
                        </Row>
                        <Row>
                            <Pie data={dataE7} options={optionsPieE7}/>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">8. El acceso a los cursos fue:</p>
                        </Row>
                        <Row>
                            <Pie data={dataE8} options={optionsPieE8}/>
                        </Row>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">9. La navegación dentro de la plataforma es:</p>
                        </Row>
                        <Row>
                            <Pie data={dataE9} options={optionsPieE9}/>
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <p className="text-bold">10. ¿Qué mejoraría de la plataforma?</p>
                        </Row>
                        <Row>
                            <div style={{height:'300px',width:'90%'}}>
                                <ListGroup style={{width:'90%',height:'300px',overflowY:'auto'}}>
                                    {dataE10.map((j,key) => (
                                        <ListGroup.Item key={key}>{j}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </Row>
                    </Col>
                </Row>
                </Col>

            </div>)
        }
    },[dataE1,dataE2,dataE3,dataE4,dataE5,dataE6,dataE7,dataE8,dataE9,dataE10,bandera])

    const encuesta = () => {

        const p1=[0,0,0,0], p2=[0,0,0,0], p3=[0,0,0,0], p4=[0,0], p5=[], p6=[0,0,0], p7=[0,0,0], p8=[0,0,0], p9=[0,0,0], p10=[]

        if(cursoE !== "Seleccione el curso"){
            const cursoSelect = courses.filter(cour => cour.title === cursoE)
            if(cursoSelect[0]) {
                if(cursoSelect[0].encuesta.length !== 0){
                    setBandera(true)
                    const usuario = cursoSelect[0].encuesta
    
                    usuario.map( i => {
                        // console.log(i.preguntas.uno)
    
                        // pregunta uno
                        if(i.preguntas.uno ==="Excelente"){
                            p1[0] += 1;
                        }
                        if(i.preguntas.uno === "Bueno"){
                            p1[1] += 1;
                        }
                        if(i.preguntas.uno === "Regular"){
                            p1[2] += 1;
                        }
                        if(i.preguntas.uno === "Malo"){
                            p1[3] += 1;
                        }
    
                        // pregunta dos
                        if(i.preguntas.dos ==="Excelente"){
                            p2[0] += 1;
                        }
                        if(i.preguntas.dos === "Bueno"){
                            p2[1] += 1;
                        }
                        if(i.preguntas.dos === "Regular"){
                            p2[2] += 1;
                        }
                        if(i.preguntas.dos === "Malo"){
                            p2[3] += 1;
                        }
    
                        // pregunta tres
                        if(i.preguntas.tres ==="Excelente"){
                            p3[0] += 1;
                        }
                        if(i.preguntas.tres === "Bueno"){
                            p3[1] += 1;
                        }
                        if(i.preguntas.tres === "Regular"){
                            p3[2] += 1;
                        }
                        if(i.preguntas.tres === "Malo"){
                            p3[3] += 1;
                        }
                        // pregunta cuatro
                        if(i.preguntas.cuatro ==="Si"){
                            p4[0] += 1;
                        }
                        if(i.preguntas.cuatro === "No"){
                            p4[1] += 1;
                        }
    
                        // pregunta cinco
                        p5.push(i.preguntas.cinco)
    
                        // pregunta seis
                        if(i.preguntas.seis ==="Suficiente"){
                            p6[0] += 1;
                        }
                        if(i.preguntas.seis === "Regular"){
                            p6[1] += 1;
                        }
                        if(i.preguntas.seis === "Insuficiente"){
                            p6[1] += 1;
                        }
    
                        // pregunta siete
                        if(i.preguntas.siete ==="Buena"){
                            p7[0] += 1;
                        }
                        if(i.preguntas.siete === "Regular"){
                            p7[1] += 1;
                        }
                        if(i.preguntas.siete === "Mala"){
                            p7[1] += 1;
                        }
    
                        // pregunta ocho
                        if(i.preguntas.ocho ==="Sencillo"){
                            p8[0] += 1;
                        }
                        if(i.preguntas.ocho === "Regular"){
                            p8[1] += 1;
                        }
                        if(i.preguntas.ocho === "Complicado"){
                            p8[1] += 1;
                        }
    
                        // pregunta nueve
                        if(i.preguntas.nueve ==="Intuitiva"){
                            p9[0] += 1;
                        }
                        if(i.preguntas.nueve === "Regular"){
                            p9[1] += 1;
                        }
                        if(i.preguntas.nueve === "Complicada"){
                            p9[1] += 1;
                        }
    
                        // pregunta diez
                        p10.push(i.preguntas.diez)
                    })
    
                    setDataE1({
                        labels: ['Excelente',"Bueno","Regular","Malo"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p1,
                                borderColor: ['rgba(0,63,92,1)', 'rgba(84,178,126,1)','rgba(188,80,144,1)',
                                            'rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)', 'rgba(84,178,126,0.7)','rgba(188,80,144,0.7)',
                                                'rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)', 'rgba(84,178,126,0.9)','rgba(188,80,144,0.9)',
                                                    'rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                    setDataE2({
                        labels: ['Excelente',"Bueno","Regular","Malo"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p2,
                                borderColor: ['rgba(0,63,92,1)', 'rgba(84,178,126,1)','rgba(188,80,144,1)',
                                            'rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)', 'rgba(84,178,126,0.7)','rgba(188,80,144,0.7)',
                                                'rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)', 'rgba(84,178,126,0.9)','rgba(188,80,144,0.9)',
                                                    'rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                    setDataE3({
                        labels: ['Excelente',"Bueno","Regular","Malo"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p3,
                                borderColor: ['rgba(0,63,92,1)', 'rgba(84,178,126,1)','rgba(188,80,144,1)',
                                            'rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)', 'rgba(84,178,126,0.7)','rgba(188,80,144,0.7)',
                                                'rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)', 'rgba(84,178,126,0.9)','rgba(188,80,144,0.9)',
                                                    'rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                    setDataE4({
                        labels: ['Si',"No"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p4,
                                borderColor: ['rgba(0,63,92,1)','rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)','rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)','rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                    setDataE5(p5)
                    setDataE10(p10)
                    setDataE6({
                        labels: ['Suficiente',"Regular","Insuficiente"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p6,
                                borderColor: ['rgba(0,63,92,1)', 'rgba(84,178,126,1)',
                                            'rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)', 'rgba(84,178,126,0.7)',
                                                'rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)', 'rgba(84,178,126,0.9)',
                                                    'rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                    setDataE7({
                        labels: ['Buena',"Regular","Mala"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p7,
                                borderColor: ['rgba(0,63,92,1)', 'rgba(84,178,126,1)',
                                            'rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)', 'rgba(84,178,126,0.7)',
                                                'rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)', 'rgba(84,178,126,0.9)',
                                                    'rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                    setDataE8({
                        labels: ['Sencillo',"Regular","Complicado"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p8,
                                borderColor: ['rgba(0,63,92,1)', 'rgba(84,178,126,1)',
                                            'rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)', 'rgba(84,178,126,0.7)',
                                                'rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)', 'rgba(84,178,126,0.9)',
                                                    'rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                    setDataE9({
                        labels: ['Intuitiva',"Poco intuitiva","Nada intuitiva"],
                        datasets:[
                            {
                                label: 'Calificación',
                                data:p9,
                                borderColor: ['rgba(0,63,92,1)', 'rgba(84,178,126,1)',
                                            'rgba(234,102,106,1)'],
                                backgroundColor: ['rgba(0,63,92,0.7)', 'rgba(84,178,126,0.7)',
                                                'rgba(234,102,106,0.7)'],
                                hoverBackgroundColor: ['rgba(0,63,92,0.9)', 'rgba(84,178,126,0.9)',
                                                    'rgba(234,102,106,0.9)'],
                                borderWidth:1
                            }
                        ]
                    })
                }else{
                    setRenderE(<div style={{paddingTop:"50px"}}>
                        <p>No se encontró ninguna encuesta</p>
                    </div>)
                }
            }else{
                setRenderE(<div style={{paddingTop:"50px"}}>
                    <p>No se encontró ninguna encuesta</p>
                </div>)
            }
            
            // setRenderE(<div style={{paddingTop:"50px"}}>
                
            // </div>)
        }

    }

   

    /**
     * Rendered of the graphs
     */
    useEffect(() => {
        if(courses !== null){
            setRender(
                <div className='pt-3'>
                    <Tabs defaultActiveKey="Demanda y asistencia">
                        <Tab eventKey="Demanda y asistencia" title="Demanda y Asistencia">
                            <br/><br/>
                            <Container>
                                <Row xs={1} md={2}>
                                    <Col>
                                        <Bar data={data} options={options} />
                                    </Col>
                                    <br/><br/>
                                    <Col>
                                        <Bar data={data2} options={options2} />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="Clasificación por tipo de usuario" title="Clasificación por tipo de usuario">
                            <br/><br/>
                            <Container>
                                <Row xs={1} md={2}>
                                    <Col>
                                        <Pie data={data3} options={optionsPie}/>
                                    </Col>
                                    <br/><br/>
                                    <Col>
                                        <Bar data={data4} options={options3}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="Cursos gratis y pagados" title="Cursos gratis y pagados">
                            <br/><br/>
                            <Container>
                                <Row xs={1} md={2}>
                                    <Col>
                                        <Pie data={data5} options={optionsPie2}/>
                                    </Col>
                                    <br/><br/>
                                    <Col>
                                        <Bar data={data6} options={options4}/><br/><br/>
                                        <Bar data={data7} options={options5}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="Asistencia por sesión" title="Asistencia por sesión">
                            <br/><br/>
                            <Container>
                                <Row xs={1} md={2}>
                                    <Col>
                                        <Pie data={data8} options={optionsPie3}/>
                                    </Col>
                                    <br/><br/>
                                    <Col>
                                        <Bar data={data9} options={options6}/><br/><br/>
                                        <Bar data={data10} options={options7}/>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="Encuestas" title="Encuestas">
                            <br/><br/>
                            <Container>
                                <Row style={{display:'flex',flexDirection:"row",justifyContent:"center"}}>
                                    <Form.Control as="select" style={{width: '300px'}} type='text' value={cursoE} onChange={(e) => setCursoE(e.target.value)}>
                                        <option>Seleccione el curso</option>
                                        {courses.map(i => (
                                            <option key={i._id}>{i.title}</option>
                                        ))}
                                    </Form.Control> &nbsp;&nbsp;
                                    
                                    <Button variant='comp' onClick={encuesta}>Mostrar encuesta</Button>
                                </Row>
                                <Row>
                                    {renderE}
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>
                </div>
            )
        }
    }, [data,data2,data3,data4,data5,data6,data7,data8,data9,data10,courses,cursoE,renderE])

    
    
    

    return (
        /******************************************* Display "Estadísticas" *******************************************/

        <div className='container' style={{marginTop: '60px'}}>
            <Link className='btn btn-info' to={{ pathname: '/admin' }}>
                Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
            </Link>
            <h2 className='color-prim text-bold text-center my-3'>Estadísticas</h2>
            <hr></hr>

            <div className="chart-container" style={{position:'relative',height:'30%'}} >
                <HorizontalBar data={data11} options={optionsP} responsive="true"/>
            </div>
            <br/>
            <hr/>

            {/* Form */}

            <Form className="form">
                <Form.Row className="form">
                    <Form.Group as={Col} md='3'>
                        <Form.Label className='text-bold'>Fecha inicial:</Form.Label>
                        <DatePicker className="form-control"                       
                            onChange={(date) => setFechaInicio(date)}
                            selected={fechaInicio}
                            dateFormat="MMMM d, yyyy">
                        </DatePicker>
                    </Form.Group>
                    <Form.Group as={Col} md='3'>
                        <Form.Label className='text-bold'>Fecha final:</Form.Label>
                        <DatePicker className="form-control"                       
                            onChange={(date) => setFechaFin(date)}
                            selected={fechaFin}
                            dateFormat="MMMM d, yyyy">
                        </DatePicker>
                    </Form.Group>
                </Form.Row>
                <div className='text-center'><Button className='px-4' variant='comp' onClick={handleSubmit}>Generar gráficas &nbsp; <BsGraphUp/></Button></div>
            </Form>
            <hr/>
            {render}
            <br/><br/>
        </div>
    )
}
/**
 * verify props
 */
Estadistica.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
/**
 * selecting the part of the data from the store 
 */
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(Estadistica);
