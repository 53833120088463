import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import WebCam from '../webcam/WebCam';
import axios from 'axios';
import * as moment from 'moment'
import { Toast, Accordion, Card, Button,Spinner } from 'react-bootstrap';
import Chat from './Chat';
import OfflineChat from './OfflineChat';
import StreamingSocket from './StreamingSocket';
import FilePreview from './FilePreview';
import Encuesta from './Encuesta';

// url = {this.state.course.videoURL}
// url = {'/courses/TutorialNomina.mp4'}
class course extends Component {
    constructor(props){
        super(props)
        this.player = React.createRef();
        this.state = {
            id:'',
            course:{
                _id:'',
                title:'Title',
                description:'',
                videoURL:'./courses/comexcol-video.m3u8',
                liveStream:false,
                dateRelease:new Date(),
                duration:600000   // Para el video en vivo
            },
            userID: '',
            userName: '',
            identical: '',
            confidence:'',
            error:'',
            webcamState:'',
            played: 0,
            seeking:0,
            duration:600000,  // Para el video regular
            difference:0, 
            wc: false,
            toastShow: false,
            //photoTime:[300000,1200000,2400000,3300000]

            // photoTime:[5000,0,0,0,0,-5000], 
            photoTime:[900000,0,0,0,0,-900000], 
            buffering: false,
            comexcolVideo: './courses/comexcol-video.m3u8',
            realVideo: '',
            streaming: false,
            mediaSupported: false,
            fileURL: '',
            prevFile: false,
            showEncuesta: false,
        }
    }

    getCourse = async() => {
        try {
            const res = await axios.get('/api/courses/' + this.props.location.state.id);
            if(res.status === 200) {
                this.setState({course: res.data});
                this.setState({realVideo: res.data.videoURL});
                // Registro del curso en linea dentro del usuario
                if(res.data.liveStream){
                    await axios.post('/api/users/' + this.state.userID + "/cursoOnline", {'curso': this.props.location.state.id, 'asistencia': true})
                    // console.log("Asistencia agregada!")
                }
            }
        } catch (err) {
            alert('Ocurrió un error al obtener el curso, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    setPhotoTimes = () => {
        const step = this.state.course.duration/5;
        // console.log("photo time?")
        this.setState(state => {
            const photoTime = state.photoTime.map((item, index) => {
                item = item + step*index;
        //      console.log(step*index)
                return item;
            });
            return {
                photoTime
            };
        });

    }

    componentDidMount(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        this.setState(this.props.location.state);
        this.getCourse();
        this.timeCounter(this.state.course.dateRelease);

        // Si es en vivo calcula el tiempo de las fotos
        setTimeout(
            function(){
                if(this.state.course.liveStream){ 
                    // console.log("Curso en vivo")
                    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                        console.log('getUserMedia supported');    
                        navigator.mediaDevices.getUserMedia( {video: true})
                        this.setState({mediaSupported: true})
                    } else {
                        this.setState({mediaSupported: false})
                    }

                    this.setPhotoTimes();
                    // console.log(this.state.photoTime);
                    setTimeout(
                        async function() {
                            // console.log("waiting for the time for the picture")
                            this.timeCounter(this.state.course.dateRelease);
                            //this.takePhotoAt(item-diff)
                            this.state.photoTime.map( item => (item-this.state.difference)>0&&this.state.difference!==0 ? this.takePhotoAt(item-this.state.difference): console.log("fuera de tiempo") );
                        // console.log(this.state.photoTime);
                        }.bind(this),1000)
                } 
                // Si es un video calcula la duracion del video   
                else{
                    // console.log("Curso regular")
                    setTimeout(
                        async function() {
                            // console.log("duration",this.state.duration)
                        }.bind(this),1000)
                }
            }.bind(this),1000)
    }

    takePhotoAt(timer){
        setTimeout(
            async function() {
                // console.log("trying to take a picture")
                if(this.state.mediaSupported) {
                    this.setState({wc: true, toastShow: true});
                    const notification = new Audio('/sounds/notification_sound.mp3');
                    notification.play();
                } else {
                    // this.setState({toastShow: true});
                    // this.setState({wc: true, toastShow: true});
                    // const notification = new Audio('/sounds/notification_sound.mp3');
                    // notification.play();
                }
            }.bind(this),
            timer
        );
    }

    timeCounter= (hora)=>{
        const ahora=new Date()
        const a=moment(hora)
        const b=moment(ahora)
        const diff=b.diff(a)

        this.setState({ difference: diff})       
    }

    handleSeekChange = e => {
        // this.setState({ played: parseFloat(e) });
        // console.log(this.state.played);
    }

    handleProgress = async (state) => {
        //   console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState({played: state.playedSeconds})
            if(state.playedSeconds>(this.state.duration-30)&&state.playedSeconds<(this.state.duration-29)){
                // console.log("Curso por terminar....")
                // console.log("duracion - 30 seg: ", (this.state.duration-30))
                try {
                    await axios.post('/api/users/'+this.state.userID+"/replay",{course:this.props.location.state.id});
                } catch(err) {
                    console.log(err);
                }
            }
        }
    }

      handleDuration = (duration) => {
        // console.log('onDuration', duration)
        this.setState({ duration })
      }
     
    webcamState = (dataFromWebCam) => {
        this.setState({webcamState: dataFromWebCam});
        this.setState({wc: dataFromWebCam, toastShow: false});
    }

    downloadFile = async () => {
        try {
            this.setState({ loading: true }, async () => {
            await axios({
                url: '/api/courses/downloadFile',
                method: 'GET',
                responseType: 'blob', // important
                params: {
                    fileName: this.state.course.archivoAdjunto
                }
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.classList.add('d-none', 'p-0', 'm-0');
                link.setAttribute('download', this.state.course.archivoAdjunto);
                document.body.appendChild(link);
                link.click();
                this.setState({ loading: false })
              });
            })
        } catch (err) {
            alert('Ocurrió un error al descargar el archivo del curso, intentelo más tarde');
        }
    }
    
    previewFile = async () => {
        // this.setState({prevFile: true, fileURL: `http://127.0.0.1:5000/files/${this.state.course.archivoAdjunto}` })
        this.setState({prevFile: true, fileURL: `${String(process.env.REACT_APP_URL_STREAMING)}/files/${this.state.course.archivoAdjunto}` })
    }

    handleVideoError = (err) => {
        if(err === 'hlsError') {
            if(this.state.streaming){
                this.setState(Object.assign(this.state.course, { videoURL : this.state.realVideo}))
            } else if(!this.state.course.liveStream) {
                this.setState(Object.assign(this.state.course, { videoURL : this.state.realVideo}))
            } else {
                this.setState(Object.assign(this.state.course, { videoURL : this.state.comexcolVideo}))
            }
        }
    }

    hanldeBufferingStart = () => {
        if(!this.state.buffering) {
            this.setState({buffering: true});
            // console.log('Buffering ...');
            setTimeout(async function() {
                if(this.state.buffering) {
                    // this.setState(Object.assign(this.state.course, { videoURL : this.state.comexcolVideo}))
                    this.setState(Object.assign(this.state.course, { videoURL : this.state.realVideo}))
                    // window.location.reload(true);
                }
            }.bind(this), 10000)
        }
    }

    live = (live) => {
        if(live) {
            this.setState({streaming: true})
            // this.setState(Object.assign(this.state.course, { videoURL : this.state.comexcolVideo}))
            setTimeout( function() {
                this.setState(Object.assign(this.state.course, { videoURL : this.state.realVideo}))
            }.bind(this), 5000)
        } else {
            this.setState({streaming: false})
            this.setState(Object.assign(this.state.course, { videoURL : this.state.comexcolVideo}))
        }
    }

    render() {

        let wevcan = this.state.wc && this.state.course.liveStream ? <><p>Tomando foto para validación ...</p><WebCam ref={this.webcamer} userID={this.state.userID} courseID={this.state.course._id} webcamState={this.webcamState}/></>
         : this.state.course.liveStream ? <p className='h6'>Cámara web en espera ...</p> : <></>

        if (this.state.course.videoURL === '/courses/video1.mp4') {
            return null;
        }
        
        let spin = this.state.loading ? <Spinner animation="border" size="sm" /> : <></>

        let actualFile = <></>
        if(this.state.course.archivoAdjunto !== '') {
            actualFile = <>
                            {/* <div className='d-flex'>
                                <p className='m-0'>Descarga material de apoyo: <a style={{textDecorationLine: 'underline', cursor: 'pointer', color: 'blue'}} onClick={this.downloadFile}>{this.state.course.archivoAdjunto}</a></p>
                                {spin}
                            </div> */}
                            <div className='d-flex'>
                            <Button className='my-2' variant='success' onClick={this.downloadFile}>Descargar archivo adjunto &nbsp; <i className="fas fa-download"></i> &nbsp;{spin}</Button>
                            &nbsp;&nbsp;
                            <Button className='my-2' onClick={this.previewFile}>Visualizar el material del curso &nbsp; <i className="fas fa-eye"></i> &nbsp;{spin}</Button>
                            </div>
                            </>
        }

        document.body.style.backgroundColor = "#0e0e10";
        let temarioCurso =  <Accordion>
                                <Card className='bg-dark mt-4' style={{borderRadius:'10px'}}>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            Temario del curso
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <p>{this.state.course.temario}</p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion> 

        let streamingSocket = <></>
        if(this.state.course.liveStream) {
            streamingSocket=    <div>
                                    <StreamingSocket live={this.live} dateRelease={this.state.course.dateRelease} duration={this.state.course.duration}/>
                                </div> 
        }       

        let onlineChat = <></>
        let videoClasses = 'col col-lg-8 col-12'
        let chatClasses = 'col col-lg-4 col-12 my-3 my-lg-1'
        if(this.state.prevFile) {
            videoClasses = 'col col-lg-6 col-12'
            chatClasses =  'col col-lg-6 col-12 my-3 my-lg-1'
            onlineChat =    <div>
                                <Button style={{position: 'absolute', top: '0px', left: '0px'}} variant='err' onClick={() => this.setState({prevFile: false})}>✘</Button>
                                <FilePreview fileURL={this.state.fileURL}/>
                            </div>
        } else if(this.state.course.liveStream) {
            onlineChat =    <div>
                                <Chat user={this.state.userName} id={this.state.userID} />
                            </div> 
        } else {
            onlineChat =    <div>
                                <OfflineChat id={this.props.location.state.id} />
                            </div>
        }

        let renderEncuesta = <></>
        renderEncuesta = <div>
            <Encuesta course={this.state.course} user={this.state.userID}/>
        </div>

        return (            
            <div>
                <div style={{maxWidth: '100%', overflowX: 'hidden', background: '#0e0e10', color: 'white'}}>
                    <div style={{marginTop: '60px'}} className='d-flex align-items-center'>
                        <button className='btn btn-info ml-3 ml-xl-5 my-1 my-md-1' onClick={() => this.props.history.goBack()}> Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i></button>
                        <div style={{position: 'absolute', right: '50px', top: '50px', zIndex: '100'}}>
                            <Toast style={{boxShadow: '0px 1px 15px 0px #555577'}} show={this.state.toastShow} autohide delay={10000} onClose={() => this.setState({toastShow: false})}>
                                <Toast.Header>
                                    <i className='fas fa-circle pr-2 text-success'></i>
                                    <strong className="mr-auto">COMEXCOL</strong>
                                    <small>Ahora</small>
                                </Toast.Header>
                                <Toast.Body className='text-dark'>
                                    <p className='h6'><b>¡Voltea a la cámara, vamos a tomar una foto!</b></p>
                                    <p className='my-0 h6 text-bold'>Espera ... </p>
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div>
                    <div>
                        <p className="h3 text-center text-bold my-0" >{this.state.course.title}</p>
                        <hr style={{borderColor: '#FFFFFFAA'}} className='mx-3 mx-xl-5 my-2'></hr>
                    </div>
                    <div className='row px-3 px-xl-5'>
                        {/* <div className='col col-lg-8 col-12'> */}
                        <div className={videoClasses} style={{transition: '300ms'}}>
                            <div>
                                <ReactPlayer
                                    ref={this.player}
                                    className='react-player videoPlayer'
                                    url = {this.state.course.videoURL}
                                    playing = {true}
                                    heigh = 'auto'
                                    width = '100%'
                                    muted={false}
                                    loop={this.state.course.videoURL === './courses/comexcol-video.m3u8' ? true : false}
                                    controls={true}
                                    onProgress={this.handleProgress}
                                    onDuration={this.handleDuration}
                                    config={{
                                        file: {
                                          hlsOptions: { liveBackBufferLength: 300 }
                                        },
                                      }}
                                      onError={this.handleVideoError}
                                      onBuffer={this.hanldeBufferingStart}
                                      onBufferEnd={() => this.setState({buffering: false})}
                                />
                                {streamingSocket}
                                <div>
                                    <div className='d-none d-lg-block'>
                                        {actualFile}
                                        {renderEncuesta}
                                        <hr style={{borderColor: '#FFFFFFAA'}}></hr>
                                        <p>Información Adicional</p>
                                        <p>Impartido por: <span className='text-bold'>{this.state.course.professor}</span></p>
                                        <p>Descripción: <span className='text-bold'>{this.state.course.description}</span></p>
                                        {temarioCurso}
                                        <input hidden
                                            value={this.state.difference}
                                            onChange={this.timeCounter}/>
                                        <input hidden
                                            value={this.state.played} 
                                            onChange={this.handleSeekChange}/>
                                        <input hidden
                                            value={this.state.duration} 
                                            onChange={this.handleDuration}/>
                                    </div>
                                </div>
                                {wevcan}
                            </div>
                        </div>
                        <div className={chatClasses}>
                        {/* <div className='col col-lg-4 col-12 my-3 my-lg-1'> */}
                            {onlineChat}
                        </div>
                    </div>
                    <div>
                        <div className='row px-3 px-xl-5 d-block d-lg-none'>
                            <div className='col-12'>
                                {actualFile}
                                {renderEncuesta}
                                <hr style={{borderColor: '#FFFFFFAA'}}></hr>
                                <p>Información Adicional</p>
                                <p>Impartido por: <span className='text-bold'>{this.state.course.professor}</span></p>
                                <p>Descripción: <span className='text-bold'>{this.state.course.description}</span></p>
                                {temarioCurso}
                                <input hidden
                                    value={this.state.difference}
                                    onChange={this.timeCounter}/>
                                <input hidden
                                    value={this.state.played} 
                                    onChange={this.handleSeekChange}/>
                                <input hidden
                                    value={this.state.duration} 
                                    onChange={this.handleDuration}/>
                            </div>
                        </div>
                    </div>
                        <div>
                            {/* <WebCam
                                ref={this.player}   
                                userID={this.state.userID}
                                isIdentical={this.isIdentical}
                                confidencee={this.confidencee}
                                webcamState={this.webcamState}
                                courseID={this.state.course._id}
                            /> */}
                        </div>
                    {/* <p className='text-center'>Is Identical?: {this.state.identical}</p>
                    <p className='text-center'>confidence: {this.state.confidence}</p> */}
                </div> 
                <br></br>
            </div>
        )
    }
}


export default course;
