import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import ReCAPTCHA from 'react-google-recaptcha';

import { Form, Row, Col, Button, Container, Dropdown, FormLabel, DropdownButton, Tooltip, OverlayTrigger } from 'react-bootstrap';

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      noRegistro: "", 
      password: "",
      password2: "",
      errors: {},
      userType: {},
      type: 'Público en General',
      noIdentity: '',
      captcha: false,
      captchaMensaje: ''
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/home");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    if(this.state.captcha) {
    // if(true) {

      let temp = this.state.noIdentity === '' && (this.state.type !== 'Asociado' || this.state.type !== 'Apoyo Mutuo') ? '0' : this.state.noIdentity
    
      const newUser = {
        name: this.state.name,
        email: this.state.email,
        noRegistro: this.state.noRegistro,
        password: this.state.password,
        password2: this.state.password2,
        userType: {
          type: this.state.type, 
          noIdentity: temp
        }, 
      };

      this.props.registerUser(newUser, this.props.history);
    } else {
      this.setState({captchaMensaje: 'Es necesario aprobar el CAPTCHA para registrarte'})
    }
  };

  onChangeCaptcha = (value) => {
    if(value !== null) {
      this.setState({captcha: true, captchaMensaje: ''});
    } else {
      this.setState({captchaMensaje: 'Es necesario aprobar el CAPTCHA para registrarte'})
    }
  }

  render() {
    const { errors } = this.state;
    let nombreerr = '';
    let emailerr = '';
    let noregerr = '';
    let passerr = '';
    let pass2err = '';
    let userTypeErr = '';
    if(errors.name || errors.email || errors.noRegistro || errors.password || errors.password2 || errors.userType){
      nombreerr = errors.name ? 'is-invalid' : '';
      emailerr = errors.email ? 'is-invalid' :  '';
      noregerr = errors.noRegistro ? 'is-invalid' :  '';
      passerr = errors.password ? 'is-invalid' : '';
      pass2err = errors.password2 ? 'is-invalid' : '';
      userTypeErr = errors.userType ? 'is-invalid' : '';
    }
    let noIdentity = <></>;
    if(this.state.type !== 'Público en General' && this.state.type !== 'Estudiante') {
      noIdentity =  
                  <Form.Row>
                    <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
                      <Form.Label className='text-bold'>Número de asociado</Form.Label>
                      <Form.Control
                        onChange={this.onChange}
                        value={this.state.noIdentity}
                        error={errors.userType}
                        id="noIdentity"
                        type="text"
                        placeholder={`123456`}
                        required 
                        className={userTypeErr} 
                      />
                      <Form.Control.Feedback type={'invalid'}>
                        {errors.userType}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
    }
    return (
      <Container style={{marginTop: '60px'}}>
        <Row xl={1}>
          <Col xl={12} className='d-flex'>
            <Link to="/">
               <p><i className="fas fa-arrow-left"></i>  Regresar</p>
            </Link>
          </Col>
          <Col xl={12}>
            <h3 style={{color: '#0f006b', fontWeight: '700'}} className='mb-3'>
              Registro
            </h3>
            <p>
              ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión aquí</Link>
            </p>
          </Col>
        </Row>
        <Form noValidate onSubmit={this.onSubmit}>
          <Form.Row>
            <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
              <Form.Label className='text-bold'>Nombre Completo</Form.Label>
              <Form.Control
                onChange={this.onChange}
                value={this.state.name}
                error={errors.name}
                id="name"
                type="text"
                placeholder={`Nombre Apellidos`}
                required 
                className={nombreerr} 
              />
              <Form.Control.Feedback type={'invalid'}>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
              <Form.Label className='text-bold'>Correo Electrónico</Form.Label>
              <Form.Control
                type="email" 
                placeholder="ejemplo@gmail.com"
                onChange={this.onChange}
                value={this.state.email}
                error={errors.email}
                id="email"
                required 
                className={emailerr} 
              />
              <Form.Control.Feedback type={'invalid'}>{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
              <FormLabel className='text-bold'>Tipo de usuario</FormLabel>
              <DropdownButton
                  drop={'right'}
                  variant='success'
                  title={this.state.type}
                  className='text-bold'
                  >
                  {/* <OverlayTrigger placement='right' 
                    overlay={
                    <Tooltip id={'Asociado'}>
                      Personas que cuentan con un número de asociado de COMEXCOL.
                    </Tooltip>}
                  > */}
                    <Dropdown.Item 
                        id='Asociado'
                        onClick={(e) => this.setState({ type: e.target.id})}
                    >Asociado</Dropdown.Item>
                  {/* </OverlayTrigger> */}

                  {/* <OverlayTrigger placement='right' 
                    overlay={
                    <Tooltip id={'Apoyo Mutuo'}>
                      Personas que toman cursos por parte de un despacho o contador que los apoya.
                    </Tooltip>}
                  > */}
                    <Dropdown.Item 
                        id='Apoyo Mutuo'
                        onClick={(e) => this.setState({ type: e.target.id})}
                    >Apoyo Mutuo</Dropdown.Item>
                  {/* </OverlayTrigger> */}

                  {/* <OverlayTrigger placement='right' 
                    overlay={
                    <Tooltip id={'Estudiante'}>
                      Estudiantes que gusten tomar los cursos que se imparten, es necesario comprobar que son estudiantes.
                    </Tooltip>}
                  > */}
                    <Dropdown.Item 
                        id='Estudiante'
                        onClick={(e) => this.setState({ type: e.target.id})}
                    >Estudiante</Dropdown.Item>
                  {/* </OverlayTrigger> */}

                  {/* <OverlayTrigger placement='right' 
                    overlay={
                    <Tooltip id={'Público en General'}>
                      Cualquier persona no asociada o estudiante que guste tomar los cursos que se imparten.
                    </Tooltip>}
                  > */}
                    <Dropdown.Item 
                        id='Público en General'
                        onClick={(e) => this.setState({ type: e.target.id})}
                    >Público en General</Dropdown.Item>
                  {/* </OverlayTrigger> */}
                  
              </DropdownButton>
              {this.state.type === 'Asociado' ? <p className='h6 text-info mt-3 mb-0'>La cuenta de asociado está sujeta a validación por COMEXCOL</p> : ''}
            </Form.Group>
          </Form.Row>
          {noIdentity}
          <Form.Row>
            <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
              <Form.Label className='text-bold'>Número de Registro</Form.Label>
              <Form.Control
                onChange={this.onChange}
                value={this.state.noRegistro}
                error={errors.noRegistro}
                id="noRegistro"
                type="text"
                placeholder={`RFC, IMSS, INFONAVIT, CDMX, GOBEDOMEX`}
                required 
                className={noregerr} 
              />
              <Form.Control.Feedback type={'invalid'}>{errors.noRegistro}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
              <Form.Label className='text-bold'>Contraseña</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="******" 
                onChange={this.onChange}
                value={this.state.password}
                error={errors.password}
                id="password"
                required 
                className={passerr} 
              />
              <Form.Control.Feedback type={'invalid'}>{errors.password}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className='col-md-9 col-lg-8 col-xl-6'>
              <Form.Label className='text-bold'>Confirmar contraseña</Form.Label>
              <Form.Control 
                onChange={this.onChange}
                value={this.state.password2}
                error={errors.password2}
                id="password2"
                type="password"
                placeholder='******'
                required 
                className={pass2err} 
              />
              <Form.Control.Feedback type={'invalid'}>{errors.password2}</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <p className='text-bold d-flex my-1 align-items-center'>Al registrarte aceptas nuestros &nbsp;<Link target="_blank" to="/terminosycondiciones">Términos y Condiciones</Link></p>
          <ReCAPTCHA
            sitekey={'6Ld8s68ZAAAAAFWq9swp-8xPrcNKIVN5DidD4PVD'}
            onChange={this.onChangeCaptcha}
          />
          {this.state.captchaMensaje !== '' ? <p className='text-danger my-1'>{this.state.captchaMensaje}</p> : ''}
          <Button variant="comp" type="submit" className='my-2'>
            Registrar &nbsp; <i className="fas fa-user-plus"></i>
          </Button>
        </Form>
        <br></br>
        <Row xl={1}>
          <Col xl={12}>
            <p className='text-bold'>
              Conoce nuestro <Link to="/avisoDePrivacidad" target="_blank">Aviso de Privacidad</Link>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));
