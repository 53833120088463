import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import axios from 'axios';
import ImageUploader from "react-images-upload";
import Miniature from './Miniature';
import { Nav, Container, Form, Row, Col, Button, FormLabel, DropdownButton, Dropdown, Toast, Spinner } from 'react-bootstrap';
import * as moment from 'moment';
import * as locale from 'moment/locale/es-us';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom';

class UserProfile extends Component {
    constructor(props){
        super(props)
        this.state = {
            courses: [],
            userCourses: [],
            userData: {},
            name: '',
            email: '',
            noRegistro: '',
            fechaDeNacimiento: new Date(),
            ocupacion: '',
            genero: '',
            direccion: '',
            nivelEstudios: '',
            foto: './images/generic_usr.png',
            tempPhoto: '',
            pictures: [],
            btn: '',
            btnimg: '',
            resImg: '',
            load: false,
            toastShow: false,
            tmpFecha: '',

            success: true,
            puntosN: 0,
        }
    }

    getUserData = async (id) => {
        try {
            const res = await axios.get('/api/users/'+ id);
            if(res.status === 200) {
                res.data.fechaDeNacimiento = new Date(res.data.fechaDeNacimiento)
                this.setState({userData: res.data});
                // this.getPuntos(res.data.courses);
                this.getDate(res.data.fechaDeNacimiento);
                const rp = await axios.get(`/api/users/${id}/getPuntosNec`);
                if(rp.status === 200){
                    this.setState({puntosN: rp.data.puntosNec});
                }
            }
        } catch (err) {
            alert('Ocurrió un error al obtener los datos del usuario, favor de intentarlo más tarde');
            console.log(err);
        }
        this.setState(Object.assign(this.state, this.state.userData));
        this.getCourses();
    }

    getDate = (f) => {
        const localLocale = moment(f);
        moment.locale('es-us');
        localLocale.locale(false);
        const fecha = localLocale.format('LL');
        // return fecha;
        this.setState({tmpFecha: fecha});
    }

    onDateChange = date => {
        this.setState({fechaDeNacimiento: date})
    }

    getCourses = async () => {
        try {
            const res = await axios.get('/api/courses');
            this.setState({courses:res.data});
            let arrCourses = [];
            this.state.courses.forEach(course => {
                this.state.userData.courses.forEach(userCourse => {
                    if(userCourse.id === course._id && course.status){
                        arrCourses.push(course);
                    }
                })
            });
            this.setState({userCourses: arrCourses});
        } catch (err) {
            alert('Ocurrió un error al obtener los cursos del usuario, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    getUserPhoto = async (id) => {
        const config = {
            responseType: 'blob'
        }

        try {
            const res = await axios.get('/api/users/' + id + '/foto', config);
            if(res.status === 200) {
                let urlImg = URL.createObjectURL(res.data);
                if(res.data.size === 0){
                    urlImg = './images/generic_usr.png';
                }
                this.setState({foto: urlImg});
            }
        } catch (err) {
            console.log(err);
        }
    }

    // getPuntos = async (courses) => {
    //     try {
    //         let contador = 0
    //         // const res = await axios.get(`/api/users/${id}/userDetails`);
    //         // if(res.status===200){
    //             courses.filter(course=>course.completed === true).map(async(course) =>{
    //                 try {
    //                     const infoCur = await axios.get('/api/courses/'+course.id);
    //                     this.setState({
    //                         puntosN: this.state.puntosN + infoCur.data.puntosNec
    //                     })
    //                 } catch (error) {
    //                     console.log("El curso ya no existe")
    //                 }
    //             })
    //             // console.log(contador)
    //         // }
           
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    async componentDidMount(){
        this.setState({user:this.props.auth.user});
        this.getUserData(this.props.auth.user.id);
        this.getUserPhoto(this.props.auth.user.id);
    }

    onClickBtn = (e) => {
        this.setState({btn: e.target.id});
    }

    onClickPhotoBtn = () => {
        this.setState({btnimg: 'editPhoto'});
    }

    edit = () => {
        this.setState({btn: 'edit'});
    }

    onChange = (e) => {
        this.setState({[e.target.id] : e.target.value});
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({success: false})
        let updated = {
            name: this.state.name,
            email: this.state.email,
            noRegistro: this.state.noRegistro,
            fechaDeNacimiento: this.state.fechaDeNacimiento,
            ocupacion: this.state.ocupacion,
            genero: this.state.genero,
            direccion: this.state.direccion,
            nivelEstudios: this.state.nivelEstudios,
        }

        axios.put('/api/users/'+ this.props.auth.user.id, updated)
                    .then((res) => {
                        if(res.status ===  200){
                            this.getUserData(this.props.auth.user.id);
                            this.setState({btn: 'info', toastShow: true, success: true});
                        }
                    })
                    .catch(err => {
                        alert('Ocurrió un error al actualizar los datos, favor de intentarlo más tarde');
                        console.log(err)
                    });
    }

    cancel = () => {
        this.getUserData(this.props.auth.user.id);
        this.setState({btn:'info', btnimg: '', resImg: ''});
    }

    cancelPhoto = () => {
        this.getUserPhoto(this.props.auth.user.id);
        this.setState({btn:'foto', btnimg: '', resImg: ''});
    }

    onDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            pictures: pictureFiles[0]
        });
        
        var blob = '';
        try {
            const base64Str = pictureDataURLs[0];
            const s = base64Str.split(',');
            blob = this.b64toBlob(s[1]);
            this.setState({resImg: 'Vista previa'});
            this.setState({foto: URL.createObjectURL(blob), tempPhoto: blob});
        } catch(error) {
            alert('Ocurrió un error al subir la foto, favor de intentarlo más tarde');
            this.setState({resImg: `Error: ${error}\nConsulte al administrador del sitio`});
        }
    }

    saveImage = async () => {
        const config = {
            headers: { 'content-type': 'application/octet-stream' }
          };
        if(this.state.tempPhoto !== ''){
            this.setState({load: true});
            try {
                const res = await axios.put('/api/users/'+ this.props.auth.user.id + '/foto', this.state.tempPhoto, config)
                if(res.status === 200){
                    this.setState({resImg: res.data.message, btnimg: ''});
                    setTimeout( async function() {
                        this.setState({resImg: ''});                       
                    }.bind(this), 5000)
                } else {
                    this.setState({resImg: res});
                    setTimeout( async function() {
                        this.setState({resImg: ''});                       
                    }.bind(this), 5000)
                }
            } catch (err) {
                alert('Ocurrió un error al subir la foto, favor de intentarlo más tarde');
                console.log(err);
            }
            this.setState({foto: URL.createObjectURL(this.state.tempPhoto), toastShow: true, load: false});
        } else {
            this.setState({resImg: 'No se ha seleccionado ninguna foto'});
        }
    }

    b64toBlob = (b64DataStr, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64DataStr);
        const byteArrays = [];
        
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
        
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
            }
        
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    render(){
        let inf;
        let tab1Active = 'tab-button-selected';
        let tab2Active = 'tab-button';
        let tab3Active = 'tab-button';
        let updateLoading = <></>
        if(!this.state.success) {
            updateLoading = <Spinner size='sm' animation="border" variant="light" />
        }
        if(this.state.btn === 'edit'){
            tab1Active = 'tab-button-selected';
            tab2Active = 'tab-button';
            tab3Active = 'tab-button';
            inf = <>
                    <h4 style={{color: 'rgba(15, 0, 107, 1)', fontWeight: 700}}>Actualizar Datos</h4>
                    <Form onSubmit={this.onSubmit}>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Nombre Completo:</FormLabel>
                                <Form.Control
                                    onChange={this.onChange}
                                    value={this.state.name}
                                    id="name"
                                    type="text"
                                    required
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Correo electrónico:</FormLabel>
                                <Form.Control
                                     onChange={this.onChange}
                                     value={this.state.email}
                                     id="email"
                                     type="email"
                                     required
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Número de registro:</FormLabel>
                                <Form.Control
                                    onChange={this.onChange}
                                    value={this.state.noRegistro}
                                    id="noRegistro"
                                    type="text"
                                    required
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Fecha de nacimiento:</FormLabel>
                                <br></br>
                                <DatePicker className="form-control"
                                    selected={this.state.fechaDeNacimiento}
                                    onChange={this.onDateChange}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                ></DatePicker>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Ocupación:</FormLabel>
                                {/* <Form.Control
                                    onChange={this.onChange}
                                    value={this.state.ocupacion}
                                    id="ocupacion"
                                    type="text"
                                >
                                </Form.Control> */}
                                <DropdownButton
                                    drop={'down'}
                                    variant='sec'
                                    title={this.state.ocupacion}>
                                    <Dropdown.Item 
                                        onClick={(e) => this.setState({ocupacion: 'Directores y Gerentes'})}
                                    >Directores y Gerentes
                                    </Dropdown.Item>
                                    <Dropdown.Item 
                                        onClick={(e) => this.setState({ocupacion: 'Profesión de las ciencias y de la ingeniería'})}
                                    >Profesión de las ciencias y de la ingeniería
                                    </Dropdown.Item>
                                    <Dropdown.Item 
                                        onClick={(e) => this.setState({ocupacion: 'Sector Salud'})}
                                    >Sector Salud
                                    </Dropdown.Item>
                                    <Dropdown.Item 
                                        onClick={(e) => this.setState({ocupacion: 'Sector Educativo'})}
                                    >Sector Educativo
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({ocupacion: 'Administración pública y empresas'})}
                                    >Administración pública y empresas
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({ocupacion: 'Tecnología de la información y las comunicaciones'})}
                                    >Tecnología de la información y las comunicaciones
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({ocupacion: 'Técnico o profesión nivel medio'})}
                                    >Técnico o profesión nivel medio
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({ocupacion: 'Personal de apoyo administrativo'})}
                                    >Personal de apoyo administrativo
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({ocupacion: 'Trabajadores de los servicios'})}
                                    >Trabajadores de los servicios
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({ocupacion: 'Vendedores de comercios y mercados'})}
                                    >Vendedores de comercios y mercados
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({ocupacion: 'Agricultura y trabajadores agropecuarios, forestales y pesqueros'})}
                                    >Agricultura y trabajadores agropecuarios, forestales y pesqueros
                                    </Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Género:</FormLabel>
                                <DropdownButton
                                    drop={'right'}
                                    variant='sec'
                                    title={this.state.genero}>
                                    <Dropdown.Item 
                                        onClick={(e) => this.setState({genero: 'Masculino'})}
                                    >Masculino
                                    </Dropdown.Item>
                                    <Dropdown.Item 
                                        onClick={(e) => this.setState({genero: 'Femenino'})}
                                    >Femenino</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => this.setState({genero: 'Prefiero no especificar'})}
                                    >Prefiero no especificar</Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Dirección:</FormLabel>
                                <Form.Control
                                    onChange={this.onChange}
                                    value={this.state.direccion}
                                    id="direccion"
                                    type="text"
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
                                <FormLabel className='text-bold'>Nivel de estudios</FormLabel>
                                <Form.Control
                                    onChange={this.onChange}
                                    value={this.state.nivelEstudios}
                                    id="nivelEstudios"
                                    type="text"
                                >
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Button variant='comp' type='submit'>Guardar &nbsp; <i className="far fa-check-circle"></i> {updateLoading}</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant='err' onClick={this.cancel}>Cancelar &nbsp; <i className="far fa-times-circle"></i></Button>
                    </Form>
                 </>;

        } else if(this.state.btn === 'foto'){
            tab1Active = 'tab-button';
            tab2Active = 'tab-button-selected';
            tab3Active = 'tab-button';
            let editPhoto = <></>;
            let editPhotobtn = <>
                                    <Col>
                                        <Button onClick={this.onClickPhotoBtn} variant='comp'>Editar Foto &nbsp; <i className="far fa-edit"></i></Button>
                                    </Col>
                                    <hr></hr>
                                </>;
            if(this.state.btnimg === 'editPhoto'){
                let loadBtn = <Button variant='comp' onClick={this.saveImage} style={{marginBottom: '10px'}}>Guardar foto &nbsp; <i className="far fa-check-circle"></i></Button>
                if(this.state.load){
                    loadBtn = <Button variant='comp' active disabled style={{marginBottom: '10px'}}>Guardar foto &nbsp; <Spinner animation="border" variant="light" as="span" size="sm" role="status" aria-hidden="true" /></Button>
                }
                editPhoto = <>
                                <Col xl={6} sm={12}>
                                    <p>Actualizar foto de perfil</p>
                                    <ImageUploader
                                        withIcon={true}
                                        singleImage={true}
                                        withPreview={false}
                                        buttonText="Escoge una foto"
                                        onChange={this.onDrop}
                                        imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                                        maxFileSize={5242880}
                                    />
                                    {loadBtn}
                                    &nbsp;&nbsp;&nbsp;
                                    <Button variant='err' onClick={this.cancelPhoto} style={{marginBottom: '10px'}}>Cancelar &nbsp; <i className="far fa-times-circle"></i></Button>
                                </Col>
                                <hr></hr>
                            </>
                editPhotobtn = <></>;
            }

            inf = <>
                    <Row>
                        {/* <Col xl={6} md={7} sm={12}>
                            <h4 style={{color: 'rgba(15, 0, 107, 1)', fontWeight: 700}}>Foto</h4>
                            <img src={this.state.foto} alt='' className="img" style={{width: '60%', height: 'auto', borderRadius: '10px', boxShadow: '2px 2px 10px 0px #555555'}}></img>
                            <br></br>
                            <p>{this.state.resImg}</p>
                        </Col>
                        <hr></hr>
                        {editPhotobtn}
                        <hr></hr>
                        {editPhoto} */}
                        <Col>
                            <h4 style={{color: 'rgba(15, 0, 107, 1)', fontWeight: 700}}>Foto</h4>
                            <hr></hr>
                            {editPhotobtn}
                            <img src={this.state.foto} alt='' className="img d-blck d-md-none" style={{width: '60%', height: 'auto', borderRadius: '10px', boxShadow: '2px 2px 10px 0px #555555'}}></img>
                            <p className='text-info d-blck d-md-none'>{this.state.resImg}</p>
                            {editPhoto}
                            <p className='h6 text-bold italic'>La foto de perfil es necesaria para validar el cumplimiento de un curso en vivo.</p>
                            
                        </Col>
                    </Row>
                  </>
        } else if(this.state.btn === 'cursos'){
            tab1Active = 'tab-button';
            tab2Active = 'tab-button';
            tab3Active = 'tab-button-selected';
            if(this.state.userData.status) {
                inf = <>
                        <div className='row'>
                        {this.state.userCourses.slice(0).reverse().map(course=>(
                            <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4 p-4 p-sm-2" key={course._id}>
                                <Miniature
                                    course={course}
                                    user={this.state.userData} 
                                    replays={this.state.userData.courses.find(x => x.id === course._id && x.replays >= 2)}
                                    bought={true}
                                    completed={this.state.userData.courses.find(x => x.id === course._id && x.completed)}
                                    >
                                </Miniature>
                            </div>
                        ))}
                        </div>
                    </>
            } else {
                inf =   <>
                            <p className='h4 text-danger'>Su cuenta está temporalmente suspendida o está sujeta a validación, contacte a un administrador para obtener más información.</p>
                            <p className='h5'> 
                                                Por medio de un correo electrónico
                                                <span className='text-bold'> logistica@comexcol.org.mx </span> y
                                                <span className='text-bold'> colegio@comexcol.org.mx; </span>
                                                
                                                o bien a los números del Colegio.
                                                
                                                <i>55-15-36-68, </i>
                                                <i>55-16-81-58</i>
                                            </p>
                        </>   
            }
        } else if (this.state.btn ==='info'){
            tab1Active = 'tab-button-selected';
            tab2Active = 'tab-button';
            tab3Active = 'tab-button';
            inf = <>
                    <h4 style={{color: 'rgba(15, 0, 107, 1)', fontWeight: 700}}>Datos</h4>
                    <p>Nombre Completo:<b> {this.state.userData.name}</b></p>
                    <p>Correo electrónico:<b> {this.state.userData.email}</b></p>
                    <p>Número de registro:<b> {this.state.userData.noRegistro}</b></p>
                    <p>Fecha de nacimiento:<b> {this.state.tmpFecha}</b></p>
                    <p>Ocupación:<b> {this.state.userData.ocupacion}</b></p>
                    <p>Género:<b> {this.state.userData.genero}</b></p>
                    <p>Dirección:<b> {this.state.userData.direccion}</b></p>
                    <p>Nivel de estudios:<b> {this.state.userData.nivelEstudios}</b></p>
                    <Button onClick={this.edit} variant='comp'>Editar &nbsp; <i className="fas fa-user-edit"></i></Button>
                 </>
        } else {
            tab1Active = 'tab-button-selected';
            tab2Active = 'tab-button';
            tab3Active = 'tab-button';
            inf = <>
                    <h4 style={{color: 'rgba(15, 0, 107, 1)', fontWeight: 700}}>Datos</h4>
                    <p>Nombre Completo:<b> {this.state.userData.name}</b></p>
                    <p>Correo electrónico:<b> {this.state.userData.email}</b></p>
                    <p>Número de registro:<b> {this.state.userData.noRegistro}</b></p>
                    <p>Fecha de nacimiento:<b> {this.state.tmpFecha}</b></p>
                    <p>Ocupación:<b> {this.state.userData.ocupacion}</b></p>
                    <p>Género:<b> {this.state.userData.genero}</b></p>
                    <p>Dirección:<b> {this.state.userData.direccion}</b></p>
                    <p>Nivel de estudios:<b> {this.state.userData.nivelEstudios}</b></p>
                    <Button onClick={this.edit} variant='comp'>Editar &nbsp; <i className="fas fa-user-edit"></i></Button>
                </>
        }
        let loading = <></>;
        if(this.state.load){
            loading = <Spinner animation="border" variant="info" />
        }
        document.body.style.backgroundColor = "rgba(247, 247, 255)";

        let puntos = <></>
        if(this.state.userData && this.state.userData.userType) {
            if(this.state.userData.userType.type === "Asociado" || this.state.userData.userType.type ==="Apoyo Mutuo"){
            puntos = <p className='h6'>Puntos NEC acomulados: <b>{this.state.puntosN}</b></p>
        }}

        let metaData = <></>
        if(this.state.userData && this.state.userData.userType) {
            metaData = <Row className='my-3'>
            <Col className=' text-center'>
                <p className='text-info'>{this.state.resImg}</p>
                <p className='text-bold h3' style={{color: '#000000'}}>{this.state.userData.name}</p>
                <p className='text-bold h6'>{this.state.userData.email}</p>
                <p className='text-bold h6'>{this.state.userData.userType.type} 
                {this.state.userData.userType.type === "Asociado" || this.state.userData.userType.type ==="Apoyo Mutuo" ? ` (${this.state.userData.userType.noIdentity})` : ''}</p>
                {puntos}
            </Col>
        </Row>
        }

        
        return(
            <Container style={{marginTop: '60px'}}>
                <Row>
                    <Col md='5' lg='4' className='align-items-center'>
                        <Row>
                            <Col>
                                <Link className='btn btn-info mb-3' to={{ pathname: '/home' }}>
                                    Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
                                </Link> 
                            </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-center'>
                                <img src={this.state.foto} alt='' className="img" style={{width: '250px', height: 'auto', borderRadius:'500px', boxShadow: '2px 2px 10px 0px #555555'}}></img>
                            </Col>
                        </Row>
                            {metaData}
                        <Row>
                            <Col>
                                <Toast 
                                    onClose={() => this.setState({toastShow: false})} 
                                    show={this.state.toastShow} 
                                    animation={true}
                                    delay={3000} 
                                    style={{
                                        position: 'absolute',
                                        top: '40px',
                                        left: 0,
                                    }}
                                    variant={'success'}
                                    autohide>
                                    <Toast.Header>
                                        <strong className="mr-auto">COMEXCOL&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                        <small>Ahora</small>
                                    </Toast.Header>
                                    <Toast.Body>Información actualizada correctamente</Toast.Body>
                                </Toast>
                            </Col>
                        </Row>
                    </Col>
                    <Col md='7' lg='8' className='my-3 my-md-0'>
                    <Row>
                        <Col>
                            <p className='text-bold h3 color-prim'> Perfil de usuario {loading}</p>
                        </Col>
                    </Row>
                    <Row className='mx-2 mx-sm-0'>
                        <Col sm='4' className={`d-flex align-items-center justify-content-center ${tab1Active}`}>
                            <button onClick={() => this.setState({btn: 'info'})} className='text-bold h5 tab-btn p-2 m-0'>Datos del usuario &nbsp; <i className="far fa-id-card"></i></button>
                        </Col>
                        <Col sm='4' className={`d-flex align-items-center justify-content-center ${tab2Active}`}>
                            <button onClick={() => this.setState({btn: 'foto'})} className='text-bold h5 tab-btn p-2 m-0'>Foto de perfil &nbsp;<i className="fas fa-portrait"></i></button>
                        </Col>
                        <Col sm='4' className={`d-flex align-items-center justify-content-center ${tab3Active}`}>
                            <button onClick={() => this.setState({btn: 'cursos'})} className='text-bold h5 tab-btn p-2 m-0'>Mis cursos &nbsp;<i className="far fa-file-video"></i></button>
                        </Col>
                    </Row>
                    <hr className='mt-0'></hr>
                    {inf}
                </Col>
                </Row>
                <br></br>
            </Container>
        )
    }
}

UserProfile.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(
    mapStateToProps,
    { logoutUser }
)(UserProfile);
