import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { ButtonGroup, Button, Spinner, Form, Dropdown, DropdownButton, Col, Row } from 'react-bootstrap';
import { UserListAsociados, UserListApoyoMutuo, UserListEstudiantes, UserListPublicoEnGeneral, UserListSearch } from './UserList';
import axios from 'axios';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

function AdminUsuarios(props) {

    const [userType, setUserType] = useState('Asociados');
    const [listView, setListView] = useState(<></>);
    const [userList, setUserList] = useState(Object); 
    const [success, setSuccess] = useState(false);
    const [loadIco, setLoadIco] = useState(<Spinner animation="grow" variant='prim' />)
    
    const getUsers = async () => {
        setSuccess(false);
        try {
            const res = await axios.get('/api/users/userList');
            if(res.status === 200) {
                setUserList(res.data);
                setSuccess(true);
                setLoadIco(<></>);
            }
        } catch (err) {
            alert('Ocurrió un error al obtener a los usuarios, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/home");
        }
        getUsers();
    }, [])

    const handleClick = (e) => {
        setUserType(e.target.id);
    }

    const reloadPage = (uType) => {
        setLoadIco(uType);
        getUsers();

    }

    // Prev State Hook
    const prevUserType = usePrevious(userType);

    const sResult = (result) => {
        setUserType('D');
        setListView(<UserListSearch userList={result} reloadPage={reloadPage} />);
    }

    useEffect(() => {
        if(success) {
            let d = userType === 'Asociados' ? setListView(<UserListAsociados userList={userList.asociados} reloadPage={reloadPage} />) : 
            userType === 'Apoyo Mutuo' ? setListView(<UserListApoyoMutuo userList={userList.apoyoMutuo} reloadPage={reloadPage} />) : 
            userType === 'Estudiantes' ? setListView(<UserListEstudiantes userList={userList.estudiantes} reloadPage={reloadPage} />) :
            userType === 'Público en General' ? setListView(<UserListPublicoEnGeneral userList={userList.publicoEnGeneral} reloadPage={reloadPage} />) : <></>
        }
    }, [userType, userList, success])

    return(
        <div className='container' style={{marginTop: '60px'}}>
            <Link className='btn btn-info' to={{ pathname: '/admin' }}>
                    Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
            </Link>
            <h2 className='color-prim text-bold text-center mb-2 mt-0'>Administrar Usuarios {loadIco}</h2>
            <hr></hr>
            <div className='text-center'>
                <ButtonGroup aria-label="Button group">
                    <Button onClick={handleClick} variant='sec' className={userType === 'Asociados' ? 'active' : ''} id='Asociados'>Asociados</Button>
                    <Button onClick={handleClick} variant="sec" className={userType === 'Apoyo Mutuo' ? 'active' : ''} id='Apoyo Mutuo'>Apoyo Mutuo</Button>
                    <Button onClick={handleClick} variant="sec" className={userType === 'Estudiantes' ? 'active' : ''} id='Estudiantes'>Estudiantes</Button>
                    <Button onClick={handleClick} variant="sec" className={userType === 'Público en General' ? 'active' : ''} id='Público en General'>Público en General</Button>
                </ButtonGroup>
                <hr></hr>
                <SearchUser sResult={sResult}/>
            </div>
            <hr></hr>
            {listView}
            <br></br>
        </div>
    )
}

AdminUsuarios.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(AdminUsuarios);

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

function SearchUser(props) {

    const [name, setName] = useState(String);
    const [userType, setUserType] = useState('Asociado');
    const [noRegistro, setNoRegistro] = useState(String);
    const [noIdentity, setNoIdentity] = useState(String);

    const handleSubmit = async(e) => {
        e.preventDefault()
        
        try {
            const res = await axios.post('/api/users/searchUser', {'userType': {'type': userType, 'noIdentity': noIdentity}, 'noRegistro': noRegistro, 'name': name})
            if(res.status === 200) {
                props.sResult([res.data]);
            } else {
                props.sResult(null);
            }
        } catch (err) {
            const resp = await axios.post('/api/users/userNames',{'name':name})
            if(resp.data.length > 0){
                props.sResult(resp.data)
            } else {
                setName('')
                setNoRegistro('')
                setUserType('Asociado')
                setNoIdentity('')
                props.sResult(null)
                alert('No se encontró el usuario especificado, verifique los datos e intente de nuevo');
            }
        }
    }
    
    return(<div className='my-2'>
        {/* <p className='h4'>Buscar usuario</p> */}
        <Form onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md='2'>
                <Form.Label className='text-bold'>Tipo de usuario:</Form.Label>
                <DropdownButton
                    drop={'right'}
                    variant='sec'
                    title={userType}>
                    <Dropdown.Item 
                        id='Asociado'
                        onClick={(e) => setUserType(e.target.id)}
                    >Asociado</Dropdown.Item>
                    <Dropdown.Item 
                        id='Apoyo Mutuo'
                        onClick={(e) => setUserType(e.target.id)}
                    >Apoyo Mutuo</Dropdown.Item>
                    <Dropdown.Item 
                        id='Estudiante'
                        onClick={(e) => setUserType(e.target.id)}
                    >Estudiante</Dropdown.Item>
                    <Dropdown.Item 
                        id='Público en General'
                        onClick={(e) => setUserType(e.target.id)}
                    >Público en General</Dropdown.Item>
                </DropdownButton>
                </Form.Group>
                <Form.Group as={Col} md='3'>
                    <Form.Label className='text-bold'>Nombre Completo</Form.Label>
                    <Form.Control
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        type="text"
                        placeholder={`Nombre Apellidos`}
                    />
                </Form.Group>
                <Form.Group as={Col} md='3'>
                    <Form.Label className='text-bold'>No. Registro</Form.Label>
                    <Form.Control
                        onChange={(e) => setNoRegistro(e.target.value)}
                        value={noRegistro}
                        type="text"
                        placeholder={`RFC, IMSS, INFONAVIT, CDMX, GOBEDOMEX`}
                    />
                </Form.Group>
                <Form.Group as={Col} md='3'>
                    <Form.Label className='text-bold'>No. Asociado</Form.Label>
                    <Form.Control
                        onChange={(e) => setNoIdentity(e.target.value)}
                        value={noIdentity}
                        type="text"
                        placeholder={`...`} 
                    />
                </Form.Group>
            </Form.Row>
            <div className='text-center'><Button className='px-4' variant='comp' type='submit'>Buscar Usuario &nbsp; <i className='fas fa-search'></i></Button></div>
        </Form>
    </div>)
}