import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Modal, Table, Row, Col } from 'react-bootstrap';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

// import CheckoutForm from './CheckoutForm';
import CheckoutForm from './hooks/CheckoutForm';
;
const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PUBLIC_KEY), {locale: 'es'} );

class Paywrap extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            btn: false
        }
    } 

    render() {
        let info = {
            userID: this.props.info.userID,
            courseID: this.props.info.courseID,
            costo: this.props.info.costo,
            title: this.props.curso,
            userType: this.props.info.userType
        }

        let checkout = <div className='px-3 text-center'>
            <p className='text-bold color-secondary-light h5'>Detalles de la compra</p>
            <Table striped bordered hover variant="dark">
                <tbody className='text-left'>
                    <tr>
                        <th style={{fontWeight: '400'}}>Descripción</th>
                        <th>Curso en línea COMEXCOL S.A.</th>
                    </tr>
                    <tr>
                        <th style={{fontWeight: '400'}}>Curso</th>
                        <th>{this.props.curso}</th>
                    </tr>
                    <tr>
                        <th style={{fontWeight: '400'}}>Total a pagar</th>
                        <th>{info.costo} MXN</th>
                    </tr>
                </tbody>
            </Table>
            <Button variant='comp' className='px-3' onClick={() => this.setState({btn: !this.state.btn})}>
                Pagar {info.costo} MXN
            </Button>
        </div>
        if(this.state.btn) {
            checkout =  <>
                            <Elements stripe={stripePromise}>
                                    <CheckoutForm info={info}/>
                            </Elements>
                        </>
        }

        return(
            <Modal
                {...this.props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onExit={() => this.setState({btn: false})}
                style={{padding: '0px 0px 0px 0px'}}
                >
                <Modal.Header closeButton style={{background: '#263238'}}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4 className='text-bold px-1' style={{color: '#f7f7ff'}}>Comprar curso - COMEXCOL</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0' style={{background: '#37474F'}}>
                     <SwitchTransition mode='out-in'>
                        <CSSTransition
                            key={this.state.btn}
                            addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                            }}
                            classNames="fade"
                        >
                            <div className='p-2' style={{borderRadius:' 8px', padding: '8px', color: '#f7f7ff'}}>
                                <Row className='d-flex justify-content-center'>
                                    <Col className='d-flex align-items-center text-center' xs={6} lg={4}>
                                        <img alt='comexcol-curso' className='img img-fluid' src='/images/comexcol-curso-template.svg' ></img>
                                    </Col>
                                    <Col xs={12} lg={8}>
                                        {checkout}
                                        <div className='d-flex align-items-center flex-column mt-3 px-3'>
                                            <p className='m-0 text-right' style={{color: '#6bffdb', fontSize: '0.9em'}}>Compra segura proporcionada por</p>
                                            <p className='m-0 p-0'><a target="_blank" rel="noopener noreferrer" href='https://stripe.com/mx'><i className="fab fa-stripe" style={{transform: 'scale(2)', color: '#FFFFFF'}}></i></a></p>
                                        </div>
                                    </Col>
                                </Row>
                                
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </Modal.Body>
                <Modal.Footer style={{background: '#263238'}}>
                    <Button className='px-3' variant='err' onClick={this.props.onHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default Paywrap;