import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { AiOutlinePhone } from "react-icons/ai";
import { RiMailSendLine } from "react-icons/ri";
import { FaTwitterSquare,FaFacebookSquare } from "react-icons/fa";

class Footer extends React.Component {
    render() {
        return(
            <Container 
                as={'footer'} 
                fluid 
                className='footer'
                >
                    <Container >
                        <Row className='align-items-center text-center'>
                            <Col className='my-3 text-center' sm={3}>
                                <p className='text-bold my-1 align-items-center color-comp linkFooter'><Link className='color-comp' target="_blank" to="/terminosycondiciones">Términos y Condiciones</Link></p>
                                <p className='text-bold my-1 align-items-center color-comp linkFooter'><Link className='color-comp' target="_blank" to="/avisoDePrivacidad">Aviso de Privacidad</Link></p>
                                <p className='text-bold my-1 align-items-center color-comp linkFooter'><Link className='color-comp' to="#">Preguntas Frecuentes</Link></p>
                            </Col>

                            <Col className='my-3 foot-color small' sm={6}>
                            <h4 className="text-bold" style={{color: '#FFFFFF'}}>Contacto</h4>
                            <AiOutlinePhone/> 55-55-15-36-68 &nbsp;&nbsp; 
                            <AiOutlinePhone/> 55-55-16-81-58<br/>
                            <RiMailSendLine/> colegio@comexcol.org.mx<br/>
                            <RiMailSendLine/> logistica@comexcol.org.mx&nbsp;&nbsp;
                            <hr className="hr"/>
                                <h6 style={{color: '#AAAAAA'}}>© 2020 Colegio Mexicano de Contadores Públicos y Licenciados en Contaduría, A.C.</h6>
                            </Col>

                            <Col className='my-3' sm={3}>
                                <a target='blank' className='color-comp linkFooter' href="https://www.facebook.com/COMEXCOL/"><FaFacebookSquare size={32}/></a>
                                <a target='blank' className='color-comp linkFooter' href="https://twitter.com/ComexcolAC"><FaTwitterSquare size={32}/></a>
                            </Col>
                        </Row>
                    </Container>
            </Container>

        )
    }
}

export default Footer;