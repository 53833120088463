import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Spinner, Row, Col, Button, Form, Accordion, Card, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import * as locale from 'moment/locale/es-us'
import DatePicker from 'react-datepicker'

//react-redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import DropdownItem from 'react-bootstrap/DropdownItem';

function UserInfo (props) {

    const [userInfo, setUserInfo] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loadIco, setLoadIco] = useState(<Spinner animation='grow' variant='prim' />);
    const [images, setImages] = useState(<></>);
    const [profilePhoto, setProfilePhoto] = useState(<Spinner animation='border' variant='prim' />);
    const [render, setRender] = useState(null);
    const [courses, setCourses] = useState(null);
    const [renderCourses, setRenderCourses] = useState(null);
    const [infoCourses, setInfoCourses] = useState(Array);
    const [loadCourses, setLoadCourses] = useState(<></>);
    const [loadPhotos, setLoadPhotos] = useState(<></>);
    const [disableUserRender, setDisableUserRender] = useState(<></>);
    const [emailVerificationRender, setEmailVerificationRender] = useState(<></>);
    const [freeCourses, setFreeCourses] = useState(0);
    const [noAsociado, setNoAsociado] = useState(String);
    const [courseInfoRender, setCourseInfoRender] = useState(<></>);
    const [resumen, setResumen] = useState(null);
    const [renderResumen, setRenderResumen] = useState(<></>);
    const [showFechas, setShowFechas] = useState(false);
    const [fechaInicio, setFechaInicio] = useState(Date.now());
    const [fechaFin, setFechaFin] = useState(Date.now());
    const [noCourses, setNoCourses] = useState(null);
    const [totalCost, setTotalCost] = useState(null);
    const [loadIcoT, setLoadIcoT] = useState(<></>)
    const [puntosN,setPuntosN] = useState(0)
    const [metadata, setMetadata] = useState(<></>);

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/home");
        }
    })

    const getUserInfo = async() => {
        try {
            const resPuntos = await axios.get(`/api/users/${props.location.state.id}/getPuntosNec`);
            if(resPuntos.status === 200) {
                const res = await axios.get(`/api/users/${props.location.state.id}/userDetails`)
                if(res.status === 200) {
                    setUserInfo(res.data);
                    if(res.data.courses.length > 0) {
                        setCourses(res.data.courses);
                        setFreeCourses(res.data.freeCourses);
                        setNoAsociado(res.data.userType.noIdentity);
                    }
                    setSuccess(true);
                    setLoadIco(<></>);
                    const resp = await axios.get(`/api/users/${props.location.state.id}/foto`, {responseType: 'blob'})
                    if(resp.status === 200) {
                        let urlImg = URL.createObjectURL(resp.data);
                        if(resp.data.size === 0){
                            urlImg = '/images/generic_usr.png';
                        }
                        setProfilePhoto(
                            <Row>
                                <Col className='d-flex justify-content-center'>
                                    <img style={{width: '250px', height: 'auto', borderRadius:'500px', boxShadow: '2px 2px 10px 0px #555555'}} src={urlImg} alt='profile-picture'></img>
                                </Col>
                            </Row>
                        );
                    }
                }
            }
        } catch (err) {
            alert('Ocurrió un error al obtener los datos del usuario, intentalo más tarde o contacta al administrador');
            console.log(err);
        }
    }

    useEffect(() => {
        getUserInfo();
    },[])

    useEffect(() => {
        if(userInfo !== null && success) {
            setRender(
                <UserInfoAndEdit userInfo={userInfo}/>
            )

            setMetadata(<Row className='my-3'>
                            <Col className=' text-center'>
                                <p className='text-bold h3' style={{color: '#000000'}}>{userInfo.name}</p>
                                <p className='text-bold h6'>{userInfo.email}</p>
                                <p className='text-bold h6'>{userInfo.userType.type} 
                                {userInfo.userType.type === "Asociado" || userInfo.userType.type ==="Apoyo Mutuo" ? ` (${userInfo.userType.noIdentity})` : ''}</p>
                            </Col>
                        </Row>)
            
            if(infoCourses !== null) {
                setLoadCourses(<></>)
                if(infoCourses.length === userInfo.courses.length) {
                    setLoadCourses(<></>)
                    setRenderCourses(<>
                        {userInfo.courses.slice(0).reverse().map(course => (
                            <li key={course.id} 
                                className="list-group-item list-group-item-action">
                                 <Row>
                                    <Col md='7'>
                                        <p className='h5 text-bold' style={{color: 'black'}}>{infoCourses.find(x => x._id === course.id).title}</p>
                                        <hr className='my-1'></hr>
                                        <p className='h6 text-bold'>{infoCourses.find(x => x._id === course.id).puntosNec} puntos NEC</p>
                                        <p className='h6 my-0'>Cumplimiento de participación: &nbsp;{course.completed ? 
                                           <span className='text-success'><i className='fas fa-circle' style={{transform: 'scale(1.4)'}}></i></span> : 
                                            <span className='text-danger'><i className='fas fa-circle' style={{transform: 'scale(1.4)'}}></i></span>}</p>
                                        
                                    </Col>
                                    <Col md='5'>
                                        <Button variant='secondary' className='my-2' onClick={() => getCoursePhotos(userInfo._id, course.id)}>
                                            Detalles y fotos &nbsp;<i className="fas fa-exclamation-circle"></i>
                                        </Button>
                                        <Button variant={course.completed ? 'danger' : 'success'} className='my-2' onClick={() => setCumplimiento(course.id, course.completed)}>
                                            Cambiar Cumplimiento &nbsp;<i className="fas fa-exclamation-circle"></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </li>
                        ))}
                    </>)
                }
            }
            
            setDisableUserRender(
                    <Col className='d-flex align-items-center justify-content-center'>
                        <Button style={{maxWidth:'200px'}} variant={userInfo.status ? 'danger' : 'success'} onClick={disableUser}>
                            {userInfo.status ? 'Suspender cuenta' : 'Habilitar\ncuenta'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                        </Button>
                    </Col>)

            setEmailVerificationRender(
                    <Col className='d-flex align-items-center justify-content-center'>
                        <Button style={{maxWidth:'200px'}} variant={userInfo.verification ? 'danger' : 'success'} onClick={emailVer}>
                            {userInfo.verification ? 'Quitar verificación de \ncorreo electrónico' : 'Verificar\ncorreo electrónico'} &nbsp;<i className="fas fa-exclamation-circle"></i>
                        </Button>
                    </Col>)
        }
    }, [userInfo, success, courses, infoCourses])

    const getCourses = async() => {
        setShowFechas(false)
        if(userInfo.courses.length > 0) {
            setLoadCourses(<Spinner animation='border' variant='prim' />)
            userInfo.courses.forEach(async(course) => {
                const res = await axios.get(`/api/courses/${course.id}`)
                if(res.status === 200) {
                    setInfoCourses(prev => [... prev, res.data]);
                }
            })
        }
    }

    const getCoursePhotos = async(userID, courseID) => {
        setLoadPhotos(<Spinner animation='border' variant='prim' />);
        try {
            const res = await axios.post(`/api/users/${userID}/getCoursePhotos`, {'courseID': courseID});
            if(res.status === 200) {
                if(res.data.photos) {
                    let temp = res.data.photos;
                    let photos = [];
                    temp.forEach(photo => {
                        if(photo !== '') {
                            photos.push(photo);
                        }
                    })
                    setLoadPhotos(<></>);
                    const d = setImages(
                        // <div>
                        //     {photos.map(image => (
                        //             <img width='200px' height='auto' key={ Math.random(0,1000000)} src={`data:image/jpeg; base64,${image}`} alt='...'></img>
                        //     ))}
                        // </div>
                        <Accordion>
                                <Card style={{borderRadius:'10px'}}>
                                    <Card.Header style={{marginBottom: '0px'}}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            <p className='h5 my-0'><span>Ver fotos &nbsp; <i className='fas fa-camera'></i></span></p>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            {photos.map(image => (
                                                <img width='200px' height='auto' key={ Math.random(0,1000000)} src={`data:image/jpeg; base64,${image}`} alt='...'></img>
                                            ))}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                    )
                } else {
                    const d = setImages(
                        <div>
                            <p>El usuario no tiene fotos de este curso</p>
                        </div>
                    )
                    setLoadPhotos(<></>);
                }
            }
        } catch (err) {
            alert('Ocurrió un error al obtener las fotos del usuario, favor de intentarlo más tarde');
            console.log(err);
        }

        const currentCourseInfo = infoCourses.find( d => d._id === courseID);
        const currentCourse = userInfo.courses.find( d => d.id === courseID);
        
        setCourseInfoRender(<Accordion>
                                <Card style={{borderRadius:'10px'}}>
                                    <Card.Header style={{marginBottom: '0px'}}>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            <p className='h5 my-0'>Detalles del curso: <span className='text-bold'>{currentCourseInfo.title}</span></p>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <p className='h6 my-0'>Título: <b>{currentCourseInfo.title}</b></p>
                                            <p className='h6 my-0'>Descripción: <b>{currentCourseInfo.description}</b></p>
                                            <p className='h6 my-0'>Impartido por: <b>{currentCourseInfo.professor}</b></p>
                                            <p className='h6 my-0'>Costo:</p>
                                            <ul>
                                                {
                                                    userInfo.userType.type === 'Asociados' ? <li> <p className='h6 my-0'>Asociados: <b>{`$ ${formatMoney(currentCourseInfo.costo.asociados)} MXN`}</b></p> </li> :
                                                    userInfo.userType.type === 'Apoyo Mutuo' ? <li> <p className='h6 my-0'>Apoyo Mutuo: <b>{`$ ${formatMoney(currentCourseInfo.costo.apoyoMutuo)} MXN`}</b></p> </li> :
                                                    userInfo.userType.type === 'Estudiante' ? <li> <p className='h6 my-0'>Estudiantes: <b>{`$ ${formatMoney(currentCourseInfo.costo.estudiantes)} MXN`}</b></p> </li> :
                                                    userInfo.userType.type === 'Público en General' ? <li> <p className='h6 my-0'>Público en General: <b>{`$ ${formatMoney(currentCourseInfo.costo.publicoEnGeneral)} MXN`}</b></p> </li> : <></>
                                                }
                                            </ul>
                                            <p className='h6 my-0'>Curso gratuito: <b>{currentCourse.freeCourse ? 'Si' : 'No'}</b></p>
                                            <p className='h6 my-0'>Fecha de compra: <b>{localDate(currentCourse.buyDate)}</b></p>
                                            <p className='h6 my-0'>Resultado de la prueba en línea: <b>{currentCourse.faceIDResult}</b></p>
                                            <p className='h6 my-0'>Cumplimiento de participación: <b>{currentCourse.completed ? 'Si' : 'No'}</b></p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>)
    }

    const disableUser = async() => {
        setLoadIco(<Spinner animation='grow' variant='prim' />);
        try {
            const res = await axios.post(`/api/users/${userInfo._id}/status`, {'status': !userInfo.status});
            if(res.status === 200) {
                window.location.reload(true);
            }
        } catch (err) {
            alert('Ocurrió un error al aplicar la acción al usuario, favor de intentarlo mas tarde');
            console.log(err);
            setLoadIco(<></>);
        }
    }

    const emailVer = async() => {
        setLoadIco(<Spinner animation='grow' variant='prim' />);
        try {
            const res = await axios.post(`/api/users/${userInfo._id}/verification`, {'verification': !userInfo.verification});
            if(res.status === 200) {
                window.location.reload(true);
            }
        } catch (err) {
            alert('Ocurrió un error al aplicar la acción al usuario, favor de intentarlo mas tarde');
            console.log(err);
            setLoadIco(<></>);
        }
    }

    const setCumplimiento = async(courseID, cumplimiento) => {
        setLoadIco(<Spinner animation='grow' variant='prim' />);
        try {
            const res = await axios.post(`/api/users/${userInfo._id}/setCumplimiento`, {'courseID': courseID, 'cumplimiento': !cumplimiento});
            if(res.status === 200) {
                window.location.reload(true);
            }
        } catch (err) {
            alert('Ocurrio un error al actualizar el curso, favor de intentarlo mas tarde');
            console.log(err);
            setLoadIco(<></>);
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoadIco(<Spinner animation='grow' variant='prim' />);
        try {
            const res = await axios.post(`/api/users/${userInfo._id}/freeCourse`, {'freeCourses': freeCourses})
            if(res.status === 200) {
                window.location.reload(true);
            }
        } catch (err) {
            alert('Ocurrió un error al asignar los cursos gratis, favor de intentarlo más tarde');
            console.log(err);
            setLoadIco(<></>);
        }
    }

    const submitNoAsociado = async(e) => {
        e.preventDefault();
        setLoadIco(<Spinner animation='grow' variant='prim' />);
        try {
            const res = await axios.post(`/api/users/${userInfo._id}/setNoAsociado`, {'noAsociado': noAsociado})
            if(res.status === 200) {
                window.location.reload(true);
            }
        } catch (err) {
            alert('Ocurrió un error al asignar el número de asociado, favor de intentarlo más tarde');
            console.log(err);
            setLoadIco(<></>);
        }
    }

    const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }

    const searchPays = async(e) => {
        e.preventDefault()
        setLoadIcoT(<Spinner style={{transform: 'scale(1.25)'}} animation="grow" variant='prim' />);
        try {
            const res = await axios.post(`/api/payment/${userInfo._id}/findUserPaymentsDate`, {'fechaInicio': fechaInicio, 'fechaFin': fechaFin})
            if(res.status === 200) {
                setResumen(res.data);
                mapeo(res.data)
                points(res.data,userInfo._id)
            }
        } catch (err) {
            console.log(err);
        } 
    }

    const localDate = (date) => {
        const localLocale = moment(date);
        moment.locale('es-us');
        localLocale.locale(false);
        const fecha = localLocale.format('LLLL');
        return fecha;
    }

    const mapeo = (info) => {
        let cont = 0
        setNoCourses(info.length)
        info.filter(item => item.freeCourse === false).map( item => (
            cont = cont + Number(item.precio)
        ))
        setTotalCost(cont)    
    }

    const points = async(infor,id) => {
        let count=0

        // const res = await axios.get(`/api/users/${id}/userDetails`);
        // const info = res.data.courses
        
        infor.map(item =>{
            const result = userInfo.courses.filter(i => i.id === item.course._id)
            if(result[0].completed=== true){
                count = count + item.puntosNec
            } 
        })
        setPuntosN(count)
    }

    useEffect(() => {
        if(resumen !== null) {
            setRenderResumen(<div>
                <p className=' h4 color-prim text-bold text-center'>Resumen</p>
                <Row className='d-flex justify-content-center my-2'>
                    <Col lg='6'>
                        <Card >
                            <Card.Header className='cardH text-center' >
                                <p className='h6'>{`${moment(fechaInicio).format('LL')} - ${moment(fechaFin).format('LL')}`}</p>
                            </Card.Header>
                            <Card.Body className='cardB text-center'>
                                <p className='my-0'>Total de Cursos: <span className='text-bold'>{noCourses}</span></p>
                                <p className='my-0'>Total pagado: <span className='text-bold'>{`$ ${formatMoney(totalCost)} MNX`}</span></p>
                                <p className='my-0'>Total de puntos NEC: <span className='text-bold'>{puntosN}</span></p>
                            </Card.Body>
                            <Card.Footer className='cardF'>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <p className=' h4 color-prim text-bold text-center my-3'>Lista de cursos</p>
                <p>Cursos gratuitos <span style={{ background: '#24a4d133', border: 'solid 1px black', borderRadius: '5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p>Cursos pagados <span style={{ background: '#ff825033', border: 'solid 1px black', borderRadius: '5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                {resumen.slice(0).reverse().map(pay => (
                    <li key={pay._id}
                        className="list-group-item list-group-item-action"
                        style={{color: 'black', background: pay.freeCourse ? '#24a4d133' : '#ff825033'}}
                     >
                        <Row>
                            <Col>
                                <p className='h5'>Titulo: <span className='text-bold'>{pay.course.title}</span></p>
                                <p className='h6'>RFC de compra: <span className='text-bold'>{pay.rfc}</span></p>
                                <p className='h6'>Tipo de usuario: <span className='text-bold'>{pay.userType}</span></p>
                                <p className='h6'>No. Asociado: <span className='text-bold'>{pay.user.userType.noIdentity}</span></p>
                            </Col>
                            <Col>
                                <p className='h5'>Cumplimiento: <span className='text-bold' style={userInfo.courses.find(d => d.id === pay.course._id).completed ? 
                                    {color: 'green'} : {color: 'red'}}>{userInfo.courses.find(d => d.id === pay.course._id).completed ? 'Si' : 'No'}</span></p>
                                <p className='h6'>Fecha de compra: <span className='text-bold'>{localDate(pay.date)}</span></p>
                                <p className='h6'>Precio: <span className='text-bold'>{`$ ${formatMoney(pay.precio)} MNX`}</span></p>
                                <p className='h6'>Curso gratis: <span className='text-bold'>{pay.freeCourse ? 'Si' : 'No'}</span></p>
                            </Col>
                        </Row>
                    </li>
                ))}
            </div>)
            setLoadIcoT(<> </>);
        }
    }, [resumen,noCourses, totalCost,puntosN])

    return(
        <div className='container' style={{marginTop: '60px'}}>
            <button className='btn btn-info' onClick={() => props.history.goBack()}> Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i></button>
            <div className='d-flex color-prim align-items-center justify-content-center'> <p className='h2 text-bold my-0'>Detalles del usuario&nbsp;</p>{loadIco}</div>
            <hr></hr>
            <Row>
                <Col className='align-items-center justify-content-center'>
                    {profilePhoto}
                    {metadata}
                </Col>
                <Col className='d-flex align-items-center'>
                    {render}
                </Col>
                <Col md='2' className='d-none d-lg-block'></Col>
            </Row>
            <hr></hr>
            <p className='h5 text-bold my-0'>Acciones rapidas: </p>
            <Row>
                {disableUserRender}
                {emailVerificationRender}
                <Col className='d-flex align-items-center justify-content-center'>
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group>
                                <Form.Label className='text-bold'>Cursos gratis:</Form.Label>
                                <Form.Control 
                                    id='freeCourses'
                                    type='text'
                                    value={freeCourses}
                                    onChange={(e) => setFreeCourses(e.target.value)}
                                    >
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='d-flex align-items-end'>
                                <Button variant='comp' type='submit' className='mx-2 px-3'>Asignar</Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Col>
                <Col>
                    <Form onSubmit={submitNoAsociado}>
                        <Form.Row>
                            <Form.Group>
                                <Form.Label className='text-bold'>No. Asociado:</Form.Label>
                                <Form.Control 
                                    id='noAsociado'
                                    type='text'
                                    value={noAsociado}
                                    onChange={(e) => setNoAsociado(e.target.value)}
                                    >
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='d-flex align-items-end'>
                                <Button variant='comp' type='submit' className='mx-2 px-3'>Asignar</Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <Button variant='sec' className='my-2' onClick={getCourses}>Ver cursos</Button> &nbsp;
                    <Button variant='comp' className='my-2' onClick={() => setShowFechas(true)}>Resumen por fecha</Button> &nbsp;
                </Col>
            </Row>
            <Row>
                <Col className='pt-2' md='7'>
                    {!showFechas ? loadCourses : ''}
                    {!showFechas ? renderCourses : ''}
                </Col>
                <Col className='pt-2' md='5'>
                    {loadPhotos}
                    {!showFechas ? courseInfoRender : ''}
                    {!showFechas ? images : ''}
                </Col>
            </Row>
            <Row>
                <Col>
                    {showFechas ? 
                    <Form onSubmit={searchPays}>
                        <Form.Row className="form">
                        <Form.Group as={Col} md='3'>
                            <Form.Label className='text-bold'>Fecha inicial:</Form.Label>
                            <DatePicker className="form-control"                       
                                onChange={(date) => setFechaInicio(date)}
                                selected={fechaInicio}
                                dateFormat="MMMM d, yyyy">
                            </DatePicker>
                        </Form.Group>
                        <Form.Group as={Col} md='3'>
                            <Form.Label className='text-bold'>Fecha final:</Form.Label>
                            <DatePicker className="form-control"                       
                                onChange={(date) => setFechaFin(date)}
                                selected={fechaFin}
                                dateFormat="MMMM d, yyyy">
                            </DatePicker>
                        </Form.Group>
                        </Form.Row>
                        <div className='text-center'><Button className='px-4' variant='comp' type='submit'>Buscar cursos del usuario &nbsp; <i className='fas fa-search'></i></Button></div>
                    </Form> : <></>    
                }
                <hr></hr>
                    <div className='text-center'>
                        {loadIcoT}
                    </div>
                    { showFechas ? renderResumen : ''}
                </Col>
            </Row>
            <br></br>
        </div>
    )
}

function UserInfoAndEdit (props) {

    const [name, setName] = useState(props.userInfo.name);
    const [email, setEmail] = useState(props.userInfo.email);
    const [userType, setUserType] = useState(props.userInfo.userType.type);
    const [noIdentity, setNoIdentity] = useState(props.userInfo.userType.noIdentity);
    const [status, setStatus] = useState(props.userInfo.status);
    const [noRegistro, setNoRegistro] = useState(props.userInfo.noRegistro);
    const [puntosNec, setPuntosNec] = useState(props.userInfo.puntosNec.total);
    const [freeCourses, setFreeCourses] = useState(props.userInfo.freeCourses);
    const [genero, setGenero] = useState(props.userInfo.genero);
    const [verification, setVerification] = useState(props.userInfo.verification);
    const [fechaDeNacimiento, setFechaDeNacimiento] = useState(new Date(props.userInfo.fechaDeNacimiento));
    const [direccion, setDireccion] = useState(props.userInfo.direccion);
    const [nivelEstudios, setNivelEstudios] = useState(props.userInfo.nivelEstudios);
    const [ocupacion, setOcupacion] = useState(props.userInfo.ocupacion);
    const [puntosNecAtrasado, setPuntosNecAtrasado] = useState(props.userInfo.puntosNec.atrasado);
    const [contrato, setContrato] = useState(props.userInfo.contrato);
    const [render, setRender] = useState(<></>);
    const [edit, setEdit] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        const body = {
            'name': name,
            'email': email,
            'noRegistro': noRegistro,
            'fechaDeNacimiento': fechaDeNacimiento,
            'ocupacion': ocupacion,
            'genero': genero,
            'direccion': direccion,
            'nivelEstudios': nivelEstudios,
            'userType': {
                'type': userType,
                'noIdentity': noIdentity
            },
            'puntosNecAtrasado': puntosNecAtrasado,
            'contrato': contrato
        }

        try {
            const res = await axios.post(`/api/users/${props.userInfo._id}/updateUserInfo`, body);
            if(res.status === 200) {
                window.location.reload(true);
            }
        } catch (err) {
            alert('Ocurrió un error al actualizar los datos del usuario, favor de intentarlo mas tarde');
        }
    }

    useEffect(() => {
        if(!edit) {
            setName(props.userInfo.name);
            setEmail(props.userInfo.email);
            setUserType(props.userInfo.userType.type);
            setNoIdentity(props.userInfo.userType.noIdentity);
            setStatus(props.userInfo.status);
            setNoRegistro(props.userInfo.noRegistro);
            setPuntosNec(props.userInfo.puntosNec.total);
            setFreeCourses(props.userInfo.freeCourses);
            setGenero(props.userInfo.genero);
            setVerification(props.userInfo.verification);
            setFechaDeNacimiento(new Date(props.userInfo.fechaDeNacimiento));
            setDireccion(props.userInfo.direccion);
            setNivelEstudios(props.userInfo.nivelEstudios);
            setOcupacion(props.userInfo.ocupacion);
            setPuntosNecAtrasado(props.userInfo.puntosNec.atrasado);
            setContrato(props.userInfo.contrato);
        }
    }, [edit])

    useEffect(() => {
        if(edit) {
            setRender(  <div className='my-2' style={{width: '100%'}}>
                            <Form onSubmit={handleSubmit}>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Nombre Completo:</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            id="name"
                                            type="text"
                                            required
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Correo electrónico:</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            id="email"
                                            type="email"
                                            required
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Tipo de usuario:</Form.Label>
                                        <DropdownButton
                                            drop={'right'}
                                            variant='success'
                                            title={userType}>
                                            <Dropdown.Item 
                                                onClick={() => setUserType('Asociado')}
                                            >Asociado</Dropdown.Item>
                                            <Dropdown.Item 
                                                onClick={() => setUserType('Apoyo Mutuo')}
                                            >Apoyo Mutuo</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setUserType('Estudiante')}
                                            >Estudiante</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setUserType('Público en General')}
                                            >Público en General</Dropdown.Item>
                                        </DropdownButton>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Número de Asociado:</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setNoIdentity(e.target.value)}
                                            value={noIdentity}
                                            id="noRegistro"
                                            type="text"
                                            required
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Puntos NEC atrasados:</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setPuntosNecAtrasado(e.target.value)}
                                            value={puntosNecAtrasado}
                                            id="noRegistro"
                                            type="text"
                                            required
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Forma de pago:</Form.Label>
                                        <DropdownButton
                                            drop={'right'}
                                            variant='comp'
                                            title={contrato}>
                                            <Dropdown.Item 
                                                onClick={() => setContrato('Convenio')}
                                            >Convenio</Dropdown.Item>
                                            <Dropdown.Item 
                                                onClick={() => setContrato('Anualidad')}
                                            >Anualidad</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setContrato('Ninguno')}
                                            >Ninguno</Dropdown.Item>
                                        </DropdownButton>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Número de registro:</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setNoRegistro(e.target.value)}
                                            value={noRegistro}
                                            id="noRegistro"
                                            type="text"
                                            required
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Fecha de nacimiento:</Form.Label>
                                        <br></br>
                                        <DatePicker className="form-control"
                                            selected={fechaDeNacimiento}
                                            onChange={(date) => setFechaDeNacimiento(date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        ></DatePicker>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Ocupación:</Form.Label>
                                        <DropdownButton
                                            drop={'down'}
                                            variant='sec'
                                            title={ocupacion}>
                                            <Dropdown.Item 
                                                onClick={() => setOcupacion('Directores y Gerentes')}
                                            >Directores y Gerentes
                                            </Dropdown.Item>
                                            <Dropdown.Item 
                                                onClick={() => setOcupacion('Profesión de las ciencias y de la ingeniería')}
                                            >Profesión de las ciencias y de la ingeniería
                                            </Dropdown.Item>
                                            <Dropdown.Item 
                                                onClick={() => setOcupacion('Sector Salud')}
                                            >Sector Salud
                                            </Dropdown.Item>
                                            <Dropdown.Item 
                                                onClick={() => setOcupacion('Sector Educativo')}
                                            >Sector Educativo
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setOcupacion('Administración pública y empresas')}
                                            >Administración pública y empresas
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setOcupacion('Tecnología de la información y las comunicaciones')}
                                            >Tecnología de la información y las comunicaciones
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setOcupacion('Técnico o profesión nivel medio')}
                                            >Técnico o profesión nivel medio
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setOcupacion('Personal de apoyo administrativo')}
                                            >Personal de apoyo administrativo
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setOcupacion('Trabajadores de los servicios')}
                                            >Trabajadores de los servicios
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setOcupacion('Vendedores de comercios y mercados')}
                                            >Vendedores de comercios y mercados
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setOcupacion('Agricultura y trabajadores agropecuarios, forestales y pesqueros')}
                                            >Agricultura y trabajadores agropecuarios, forestales y pesqueros
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Género:</Form.Label>
                                        <DropdownButton
                                            drop={'right'}
                                            variant='sec'
                                            title={genero}>
                                            <Dropdown.Item 
                                                onClick={() => setGenero('Masculino')}
                                            >Masculino
                                            </Dropdown.Item>
                                            <Dropdown.Item 
                                                onClick={() => setGenero('Femenino')}
                                            >Femenino</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setGenero('Prefiero no especificar')}
                                            >Prefiero no especificar</Dropdown.Item>
                                        </DropdownButton>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Dirección:</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setDireccion(e.target.value)}
                                            value={direccion}
                                            id="direccion"
                                            type="text"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className='form-admin'>
                                    <Form.Group>
                                        <Form.Label className='text-bold'>Nivel de estudios</Form.Label>
                                        <Form.Control
                                            onChange={(e) => setNivelEstudios(e.target.value)}
                                            value={nivelEstudios}
                                            id="nivelEstudios"
                                            type="text"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Button variant='comp' type='submit'>Guardar &nbsp; <i className="far fa-check-circle"></i></Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button variant='err' onClick={() => setEdit(false)}>Cancelar &nbsp; <i className="far fa-times-circle"></i></Button>
                            </Form>
                        </div>);
        } else {
            setRender(
                <div className='my-2'>
                    <p className='m05'>Nombre: <b> {props.userInfo.name}</b></p>
                    <p className='m05'>Correo elecrónico: <b>{props.userInfo.email}</b></p>
                    <p className='m05'>Tipo de usuario: <b>{props.userInfo.userType.type}</b></p>
                    <p className='m05'>No Asociado: <b>{props.userInfo.userType.noIdentity}</b></p>
                    <p className='m05'>Estatus: <b>{props.userInfo.status ? <span className='text-success'>Activo</span> : <span className='text-danger'>Suspendido</span>}</b></p>
                    <p className='m05'>Forma de Pago: <b>{props.userInfo.contrato}</b></p>
                    <p className='m05'><b>{props.userInfo.verification ? <span className='text-success'>Correo Verificado</span> : <span className='text-danger'>Correo no verificado</span>}</b></p>
                    <p className='m05'>No. Registro: <b>{props.userInfo.noRegistro}</b></p>
                    <p className='m05'>Puntos NEC acomulados: <b> {puntosNec}</b></p>
                    <p className='m05'>Cursos gratis: <b> {props.userInfo.freeCourses}</b></p>
                    <Button className='my-2' onClick={() => setEdit(true)} variant='comp'>Editar información&nbsp; <i className="fas fa-user-edit"></i></Button>
                    <ModalCourse userInfo={props.userInfo} />
                </div>
            )
        }
    }, [edit, name, email, userType, noIdentity, status, noRegistro, puntosNec, freeCourses, genero, verification, fechaDeNacimiento, direccion, nivelEstudios, ocupacion, puntosNecAtrasado, contrato])

    return(
        <div style={{width: '100%'}}>
            {render}
        </div>
    )

}

/**
 * @path /src/components/Admin
 * @param {*} props (user id)
 * Show Modal form for adding courses to an specific user
 */

function ModalCourse(props) {
    const [modalShow, setModalShow] = useState(false);
    const [courses, setCourses] = useState(null);
    const [render, setRender] = useState(<></>);
    const [currentCourse, setCurrentCourse] = useState({title: 'Selecciona un curso'});
    const [formaDePago, setFormaDePago] = useState('Forma de pago');
    const [rfc, setRfc] = useState('');
    const [success, setSuccess] = useState(true);

    const getCourses = async() => {
        try {
            const res = await axios.get('/api/courses');
            if(res.status === 200) {
                const cu = [];
                res.data.forEach(course => {
                    if(props.userInfo.courses.find(x => x.id === course._id)){
                        cu.push(course);
                    }
                })
                const diff = res.data.filter(x => !cu.includes(x));
                setCourses(diff);
            }
        } catch (err) { console.log(err) }
    } 

    useEffect(() => {
        if(modalShow && courses === null) {
            getCourses()
        }

        if(modalShow && courses !== null) {
            setRender(<>
                <div>
                    <p className='h6 text-bold'>Para agregar un curso al usuario seleccione el curso en la lista de abajo y proporcione la información solicitada</p>
                    <DropdownButton
                        drop={'right'}
                        variant='sec'
                        className='my-2'
                        title={currentCourse.title}>
                            {courses.map(course => (
                                <Dropdown.Item 
                                    key={course._id}
                                    onClick={() => setCurrentCourse(course)}
                                >{course.title}</Dropdown.Item>
                            ))}
                    </DropdownButton>
                    <DropdownButton
                        drop={'right'}
                        variant='sec'
                        className='mb-2'
                        title={formaDePago}>
                        <DropdownItem
                            onClick={() => setFormaDePago('Transferencia interbancaria')}
                        >
                            Transferencia interbancaria
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => setFormaDePago('Curso gratuito')}
                        >
                            Curso gratuito
                        </DropdownItem>
                    </DropdownButton>
                    <Form.Control
                        type='text'
                        placeholder='RFC de compra'
                        value={rfc}
                        onChange={(e) => setRfc(e.target.value)}
                    >
                    </Form.Control>
                    {success ? <Button variant='success' className='my-2' onClick={handleClick}>
                        Agregar ✔
                    </Button> : <Button variant='success' className='my-2' onClick={handleClick} disabled>
                        Agregar ✔
                    </Button>}
                </div>
            </>)
        }

    }, [modalShow, courses, formaDePago, rfc, currentCourse, success])

    const handleClick = async () => {
        setSuccess(false);
        try {
            const res = await axios.post(`/api/users/${props.userInfo._id}`, {'userID': props.userInfo._id, 'courseID': currentCourse._id, 'freeCourse': formaDePago === 'Curso gratuito' ? true : false});
            if(res.status === 200) {
                const precio = props.userInfo.userType.type === 'Asociado' ? currentCourse.costo.asociados : 
                props.userInfo.userType.type === 'Apoyo Mutuo' ? currentCourse.costo.apoyoMutuo :
                props.userInfo.userType.type === 'Estudiante' ? currentCourse.costo.estudiantes : currentCourse.costo.publicoEnGeneral;
                const re = await axios.post('/api/payment/buyNewCourse', {'userID': props.userInfo._id, 'courseID': currentCourse._id, 'freeCourse': formaDePago === 'Curso gratuito' ? true : false, 'precio': precio, rfc: rfc, 'userType': props.userInfo.userType.type, 'formaDePago': formaDePago === 'Curso gratuito' ? 'No Aplica' : formaDePago})
                if(re.status === 200) {
                    alert('Curso agregado')
                    setModalShow(false);
                    setSuccess(true);
                    window.location.reload(true);
                }
            }
        } catch (err) {
            setModalShow(false);
            alert('Ocurrió un error, favor de intentarlo más tarde');
            console.log(err);
        }
    }

    return(
        <div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Agregar curso al usuario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {render}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='err' onClick={() => setModalShow(false)}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <Button onClick={() => setModalShow(true)} variant='sec'>
                Agregar Curso &nbsp;<i className="fas fa-plus-circle"></i>
            </Button>
        </div>
    )
}

UserInfo.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(UserInfo);