import React, { useState, useEffect } from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap';
import axios from 'axios';

export default function Encuesta (props) {
    const [modalShow, setModalShow] = useState(false);
    const [render, setRender] = useState(<></>);
    const [success, setSuccess] = useState(false);
    const [axErr, setAxErr] = useState(false);
    const [loadIco, setLoadIco] = useState(<></>);
    const [uno, setUno] = useState('Excelente');
    const [dos, setDos] = useState('Excelente');
    const [tres, setTres] = useState('Excelente');
    const [cuatro, setCuatro] = useState('Si');
    const [cinco, setCinco] = useState('');
    const [seis, setSeis] = useState('Suficiente');
    const [siete, setSiete] = useState('Buena');
    const [ocho, setOcho] = useState('Sencillo');
    const [nueve, setNueve] = useState('Intuitiva');
    const [diez, setDiez] = useState('');

    useEffect(() => {
        document.getElementsByName('uno').forEach(d => {
            if(d.checked) {
                setUno(d.value);
            }
        })
        document.getElementsByName('dos').forEach(d => {
            if(d.checked) {
                setDos(d.value);
            }
        })
        document.getElementsByName('tres').forEach(d => {
            if(d.checked) {
                setTres(d.value)
            }
        })
        document.getElementsByName('cuatro').forEach(d => {
            if(d.checked) {
                setCuatro(d.value);
            }
        })
        document.getElementsByName('seis').forEach(d => {
            if(d.checked) {
                setSeis(d.value);
            }
        })
        document.getElementsByName('siete').forEach(d => {
            if(d.checked) {
                setSiete(d.value);
            }
        })
        document.getElementsByName('ocho').forEach(d => {
            if(d.checked) {
                setOcho(d.value);
            }
        })
        document.getElementsByName('nueve').forEach(d => {
            if(d.checked) {
                setNueve(d.value);
            }
        })
    }, [uno, dos, tres, cuatro, cinco, seis, siete, ocho, nueve])

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoadIco(<Spinner animation='border' variant='comp' />);
        const encuestaData = {
            user: {
                id: props.user,
            },
            preguntas: {
                'uno': uno,
                'dos': dos,
                'tres': tres,
                'cuatro': cuatro,
                'cinco': cinco,
                'seis': seis,
                'siete': siete,
                'ocho': ocho,
                'nueve': nueve,
                'diez': diez,
            }
        }

        try {
            const res = await axios.post(`/api/courses/${props.course._id}/setEncuesta`, encuestaData);
            if(res.status === 200) {
                setSuccess(true);
                setAxErr(false);
                setLoadIco(<></>);
                setTimeout(() => {
                    setModalShow(false);
                }, 5000);
            }
        } catch {
            setLoadIco(<></>);
            setAxErr(true);
        }
    }

    useEffect(() => {
        if(!success && !axErr) {
            setRender(
                <div style={{height: '400px', overflowY: 'scroll', overflowX: 'hidden'}}>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">1.- ¿Cómo califica el desempeño del expositor? </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r11" name="uno" value='Excelente' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r11">Excelente</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r12" name="uno" value='Bueno' />
                                    <label className="custom-control-label" htmlFor="r12">Bueno</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r13" name="uno" value='Regular' />
                                    <label className="custom-control-label" htmlFor="r13">Regular</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r14" name="uno" value='Malo' />
                                    <label className="custom-control-label" htmlFor="r14">Malo</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">2.- ¿Cuál es su opinión respecto de la dinámica del evento? </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r21" name="dos" value='Excelente' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r21">Excelente</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r22" name="dos" value='Bueno' />
                                    <label className="custom-control-label" htmlFor="r22">Bueno</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r23" name="dos" value='Regular' />
                                    <label className="custom-control-label" htmlFor="r23">Regular</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r24" name="dos" value='Malo' />
                                    <label className="custom-control-label" htmlFor="r24">Malo</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">3.- ¿Cómo califica al evento? </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r31" name="tres" value='Excelente' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r31">Excelente</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r32" name="tres" value='Bueno' />
                                    <label className="custom-control-label" htmlFor="r32">Bueno</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r33" name="tres" value='Regular' />
                                    <label className="custom-control-label" htmlFor="r33">Regular</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r34" name="tres" value='Malo' />
                                    <label className="custom-control-label" htmlFor="r34">Malo</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">4.- ¿Le es útil el contenido del evento? </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r41" name="cuatro" value='Si' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r41">Si</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r42" name="cuatro" value='No' />
                                    <label className="custom-control-label" htmlFor="r42">No</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='form-row'>
                            <div className='col-12 px-2'>
                                <label className='custom-control-label text-bold h5' htmlFor='cinco'>5.- ¿Por qué?</label>
                                <input onChange={(e) => setCinco(e.target.value)} type="text" className="form-control" id='cinco' name="cinco" placeholder="..." />
                            </div>
                        </div>
                        <br />
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">6.- La duración del evento fue: </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r61" name="seis" value='Suficiente' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r61">Suficiente</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r62" name="seis" value='Regular' />
                                    <label className="custom-control-label" htmlFor="r62">Regular</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r63" name="seis" value='Insuficiente' />
                                    <label className="custom-control-label" htmlFor="r63">Insuficiente</label>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">7.- ¿Cómo calificas la experiencia en la plataforma? </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r71" name="siete" value='Buena' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r71">Buena</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r72" name="siete" value='Regular' />
                                    <label className="custom-control-label" htmlFor="r72">Regular</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r73" name="siete" value='Mala' />
                                    <label className="custom-control-label" htmlFor="r73">Mala</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">8.- El acceso a los cursos fue: </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r81" name="ocho" value='Sencillo' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r81">Sencillo</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r82" name="ocho" value='Regular' />
                                    <label className="custom-control-label" htmlFor="r82">Regular</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r83" name="ocho" value='Complicado' />
                                    <label className="custom-control-label" htmlFor="r83">Complicado</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="form-row">
                            <div className='col-12 px-2'>
                                <label className="text-bold my-0 h5">9.- La navegación dentro de la plataforma es: </label>
                            </div>
                            <div className='d-flex text-center justify-content-around' style={{flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end'}}>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r91" name="nueve" value='Intuitiva' defaultChecked/>
                                    <label className="custom-control-label" htmlFor="r91">Intuitiva</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r92" name="nueve" value='Regular' />
                                    <label className="custom-control-label" htmlFor="r92">Poco intuitiva</label>
                                </div>
                                <div className="custom-control custom-radio d-flex align-items-center mx-2">
                                    <input type="radio" className="custom-control-input" id="r93" name="nueve" value='Complicada' />
                                    <label className="custom-control-label" htmlFor="r93">Nada intuitiva</label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='form-row'>
                            <div className='col-12 px-2'>
                                <label className='custom-control-label text-bold h5' htmlFor='diez'>10.- ¿Qué mejoraría de la plataforma?</label>
                                <input onChange={(e) => setDiez(e.target.value)} type="text" className="form-control" id='diez' name="diez" placeholder="..." />
                            </div>
                        </div>
                    </form>
                </div>
            )
        } else if (axErr) {
            setRender(<p className='h4 text-danger'>Ocurrió un error al realizar la encuesta, favor de intentarlo mas tarde</p>)
        } else if (success) {
            setRender(
                <div className='text-center'>
                    <p className='h3 text-success text-bold'>¡Gracias!</p>
                    <p className='h4 text-bold'>Tu opinión es muy importante para COMEXCOL.</p>
                </div>
            )
        }
    }, [success, axErr])

    return(
        <div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Evaluación del evento y de los expositores &nbsp; {loadIco}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    {render}
                </Modal.Body>
                <Modal.Footer>
                    {!success ? <>
                        <Button type='submit' onClick={handleSubmit} variant='success'>Enviar Encuesta &nbsp; <i className="fas fa-paper-plane"> </i></Button>
                        <Button variant='err' onClick={() => setModalShow(false)}>Cancelar ✘</Button>
                    </> : <Button variant='success' onClick={() => setModalShow(false)}>Aceptar ✔</Button>}
                </Modal.Footer>
            </Modal>
            <Button onClick={() => setModalShow(true)} variant='comp'>
                Evaluación del evento y de los expositores &nbsp;  ✔
            </Button>
        </div>
    )
}