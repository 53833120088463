import React, { Component } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ImageUploader from "react-images-upload";
import { Spinner, Button, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';

//react-redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

class crearCursos extends Component {
    constructor(props){
        super(props)
        this.state={
            courses:[],
            newCourse:{},
            users:[],
            userName:'',
            title:'',
            description:'',
            professor:'',
            videoURL:'',
            image:'',
            liveStream:false,
            dateRelease:new Date(),
            costo: '',
            duration:0,
            hrs:0,
            min:0,
            sec:0,
            pictures: [],
            foto: '',
            comentario:'',
            success: true,
            archivoAdjunto: '',
            file: '',
            fileName: '',
            files: [],
            fileMsg: '',
            fileError: false,
            temario: '',
            publicoEnGeneral: '',
            asociados: '',
            apoyoMutuo: '',
            estudiantes: '',
            puntosNec:5,
            stripePublicoEnGeneral: '',
            stripeAsociados: '',
            stripeApoyoMutuo: '',
            stripeEstudiantes: '',
            cursoGratuito: false,
            sesionNumber: 1,
            sesionFlag: false,
            etiqueta: 'No',
            exclusivo: false,
            sesion2disabled: false,    
        }
    }

    componentDidMount(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        if (this.props.auth.user.rol !=='Admin') {
            this.props.history.push("/home");
        } else {
            bsCustomFileInput.init();
            if(this.props.location.state) {
                this.getFiles();
                if(this.props.location.state.editing){
                    this.setState({
                        title: this.props.location.state.courseUpdate.title,
                        description: this.props.location.state.courseUpdate.description,
                        professor: this.props.location.state.courseUpdate.professor,
                        image:this.props.location.state.courseUpdate.image,
                        videoURL:this.props.location.state.courseUpdate.videoURL,
                        liveStream:this.props.location.state.courseUpdate.liveStream,
                        dateRelease:new Date(this.props.location.state.courseUpdate.dateRelease),
                        costo: {
                            apoyoMutuo: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.apoyoMutuo))}`,
                            publicoEnGeneral: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.publicoEnGeneral))}`,
                            estudiantes: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.estudiantes))}`,
                            asociados: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.asociados))}`,
                        },
                        duration:this.props.location.state.courseUpdate.duration,
                        comentario: this.props.location.state.courseUpdate.comentario,
                        archivoAdjunto: this.props.location.state.courseUpdate.archivoAdjunto,
                        fileName: this.props.location.state.courseUpdate.archivoAdjunto,
                        temario: this.props.location.state.courseUpdate.temario,
                        apoyoMutuo: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.apoyoMutuo))}`,
                        publicoEnGeneral: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.publicoEnGeneral))}`,
                        estudiantes: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.estudiantes))}`,
                        asociados: `$ ${this.formatMoney(Number(this.props.location.state.courseUpdate.costo.asociados))}`,
                        puntosNec: this.props.location.state.courseUpdate.puntosNec,
                        stripeApoyoMutuo: this.formatMoney(this.comexcolAmount(Number(this.props.location.state.courseUpdate.costo.apoyoMutuo))),
                        stripePublicoEnGeneral: this.formatMoney(this.comexcolAmount(Number(this.props.location.state.courseUpdate.costo.publicoEnGeneral))),
                        stripeEstudiantes: this.formatMoney(this.comexcolAmount(Number(this.props.location.state.courseUpdate.costo.estudiantes))),
                        stripeAsociados: this.formatMoney(this.comexcolAmount(Number(this.props.location.state.courseUpdate.costo.asociados))),
                        cursoGratuito: this.props.location.state.courseUpdate.cursoGratuito,
                        sesionNumber: this.props.location.state.courseUpdate.sesion.number,
                        sesionFlag: this.props.location.state.courseUpdate.sesion.flag,
                        etiqueta: !this.props.location.state.courseUpdate.cursoGratuito ? 'No' : this.props.location.state.courseUpdate.etiqueta,
                        exclusivo: this.props.location.state.courseUpdate.exclusivo === 'No' ? false : true,
                        sesion2disabled: this.props.location.state.courseUpdate.sesion.number === 2 || this.props.location.state.courseUpdate.cursoGratuito ? true : false,
                    })
                    this.secondsToDate(this.props.location.state.courseUpdate.duration)
                }
            } else {
                this.props.history.push("/admin/cursos");
            }
        }
    }

    getFiles = async () => {
        try {
            const res = await axios.get('/api/courses/files');
            this.setState({files: res.data});
        } catch (err) {
            alert('Ocurrió un error al obtener los archivos adjuntos, favor de intentarlo más tarde');
            console.log(err);
        }
    }  
 
    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({success: false});
        const newCourse = {
            title: this.state.title,
            description: this.state.description,
            professor: this.state.professor,
            image:this.state.image,
            videoURL: this.state.videoURL,
            liveStream:this.state.liveStream,
            dateRelease:this.state.dateRelease,
            costo: {
                apoyoMutuo: this.state.apoyoMutuo.replace(/[^0-9\.]+/g, ""),
                estudiantes: this.state.estudiantes.replace(/[^0-9\.]+/g, ""),
                asociados: this.state.asociados.replace(/[^0-9\.]+/g, ""),
                publicoEnGeneral: this.state.publicoEnGeneral.replace(/[^0-9\.]+/g, ""),
            },
            duration:this.state.duration,
            comentario: this.state.comentario,
            archivoAdjunto: this.state.fileName,
            temario: this.state.temario,
            puntosNec: this.state.puntosNec,
            cursoGratuito: this.state.cursoGratuito,
            sesion: { number: this.state.sesionNumber, flag: this.state.sesionFlag },
            etiqueta: this.state.etiqueta,
            exclusivo: this.state.exclusivo ? 'Si' : 'No',
        }

        if(this.state.image === '' && !this.state.foto) {
            newCourse.image = 'comexcol-complete.svg';
        }

        if(this.props.location.state.editing){  
            const editCourse = {
                title: this.state.title,
                description: this.state.description,
                professor: this.state.professor,
                image:this.state.image,
                videoURL: this.state.videoURL,
                liveStream:this.state.liveStream,
                dateRelease:this.state.dateRelease,
                costo: {
                    apoyoMutuo: this.state.apoyoMutuo.replace(/[^0-9\.]+/g, ""),
                    estudiantes: this.state.estudiantes.replace(/[^0-9\.]+/g, ""),
                    asociados: this.state.asociados.replace(/[^0-9\.]+/g, ""),
                    publicoEnGeneral: this.state.publicoEnGeneral.replace(/[^0-9\.]+/g, ""),
                },
                duration:this.state.duration,
                comentario: this.state.comentario,
                archivoAdjunto: this.state.fileName,
                temario: this.state.temario,
                puntosNec: this.state.puntosNec,
                cursoGratuito: this.state.cursoGratuito,
                sesion: { number: this.state.sesionNumber, flag: this.state.sesionFlag },
                etiqueta: this.state.etiqueta,
                exclusivo: this.state.exclusivo ? 'Si' : 'No',
            }   

            if(this.state.image === '' && !this.state.foto) {
                editCourse.image = 'comexcol-complete.svg';
            }

            try {
                let res = await axios.put('/api/courses/' + this.props.location.state.courseUpdate._id, editCourse);
                if(res.status === 200){
                    if(this.state.fileName !== ''){
                        try{
                            const form = new FormData();
                            form.append('file', this.state.file);
                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }
                            const res = await axios.post('/api/courses/' + this.props.location.state.courseUpdate._id, form, config);
                            if(res.status === 200) {
                                if(this.state.foto) {
                                    // Para guardar las imagenes
                                    const form = new FormData();
                                    const config2 = {
                                        headers: {
                                            'content-type': 'multipart/form-data'
                                        }
                                    }
                                    
                                    try {
                                        form.append('image', this.state.foto, this.state.image);
                                    } catch (err) {
                                        console.log(err);
                                    }
                        
                                    await axios.post('/api/courses/courseImage', form, config2)
                                    .then(res => {
                                        console.log(res);
                                        window.location.href='/admin/cursos';
                                        this.setState({success: true});
                                    }).catch(err => {
                                        console.log(err);
                                        window.location.href='/admin/cursos';
                                        this.setState({success: true});
                                    });
                                } else {
                                    window.location.href='/admin/cursos';
                                    this.setState({success: true});
                                }
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    } else {
                        if(this.state.foto) {
                            // Para guardar las imagenes
                            const form = new FormData();
                            const config2 = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }
                            
                            try {
                                form.append('image', this.state.foto, this.state.image);
                            } catch (err) {
                                console.log(err);
                            }
                
                            await axios.post('/api/courses/courseImage', form, config2)
                            .then(res => {
                                console.log(res);
                                window.location.href='/admin/cursos';
                                this.setState({success: true});
                            }).catch(err => {
                                console.log(err);
                                window.location.href='/admin/cursos';
                                this.setState({success: true});
                            });
                        } else {
                            window.location.href='/admin/cursos';
                            this.setState({success: true});
                        }
                    }
                }
            } catch(err) {
                alert('Ocurrió un error al actualizar el curso, favor de intentarlo más tarde');
                console.log(err)
            }
        } else {
            try {
                let res = await axios.post('/api/courses', newCourse);
                if(res.status === 200){
                    if(this.state.fileName !== ''){
                        try{
                            const form = new FormData();
                            form.append('file', this.state.file);
                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }
                            const res = await axios.post('/api/courses/d04', form, config);
                            if(res.status === 200) {
                                if(this.state.foto) {
                                    // Para guardar las imagenes
                                    const form = new FormData();
                                    const config2 = {
                                        headers: {
                                            'content-type': 'multipart/form-data'
                                        }
                                    }
                                    
                                    try {
                                        form.append('image', this.state.foto, this.state.image);
                                    } catch (err) {
                                        console.log(err);
                                    }
                        
                                    await axios.post('/api/courses/courseImage', form, config2)
                                    .then(res => {
                                        console.log(res);
                                        window.location.href='/admin/cursos';
                                        this.setState({success: true});
                                    }).catch(err => {
                                        console.log(err);
                                        window.location.href='/admin/cursos';
                                        this.setState({success: true});
                                    });
                                } else {
                                    window.location.href='/admin/cursos';
                                    this.setState({success: true});
                                }
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    } else {
                        if(this.state.foto) {
                            // Para guardar las imagenes
                            const form = new FormData();
                            const config2 = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            }
                            
                            try {
                                form.append('image', this.state.foto, this.state.image);
                            } catch (err) {
                                console.log(err);
                            }
                
                            await axios.post('/api/courses/courseImage', form, config2)
                            .then(res => {
                                console.log(res);
                                window.location.href='/admin/cursos';
                                this.setState({success: true});
                            }).catch(err => {
                                console.log(err);
                                window.location.href='/admin/cursos';
                                this.setState({success: true});
                            });
                        } else {
                            window.location.href='/admin/cursos';
                            this.setState({success: true});
                        }
                    }
                } 
            } catch(err) {
                alert('Ocurrió un error al crear el curso, favor de intentarlo más tarde');
                console.log(err)
            }
        }
    }

    onCheckChange= e => {
        this.setState({liveStream: e.target.checked})
        if(e.target.checked ) {
            this.setState({videoURL: 'https://cursoscomexcol.com/live/1/index.m3u8'});
            // this.setState({videoURL: `${String(process.env.REACT_APP_URL_STREAMING)}/live/1/index.m3u8`});
        } else if(!e.target.checked) {
            this.setState({videoURL: 'https://www.comexcol.mx/cursos/video/...'});
            // this.setState({videoURL: `${String(process.env.REACT_APP_URL_STREAMING)}/video/...`});
        }
    }

    onCheckChangeGratis = (e) => {
        this.setState({cursoGratuito: e.target.checked});
        if(e.target.checked) {
            this.setState({
                apoyoMutuo: `$ ${this.formatMoney(Number(0))}`,
                publicoEnGeneral: `$ ${this.formatMoney(Number(0))}`,
                estudiantes: `$ ${this.formatMoney(Number(0))}`,
                asociados: `$ ${this.formatMoney(Number(0))}`,
            });
            if(this.state.etiqueta === 'No' || this.state.etiqueta !== 'Platica Informativa' || this.state.etiqueta !== 'Platica informativa') {
                this.setState({etiqueta: 'Curso gratuito', sesion2disabled: true});
            }
        } else {
            this.setState({etiqueta: 'No', sesion2disabled: false});
        }
    }

    onInputChange= e =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onDurationChange= e =>{
        this.setState({[e.target.name]:e.target.value})
        setTimeout(function(){
            var seconds=0
            var seconds=parseInt(this.state.hrs)*3600+parseInt(this.state.min)*60+parseInt(this.state.sec)
            var seconds=seconds*1000
            this.setState({duration:seconds})
        }.bind(this),100)
    }

    secondsToDate(milisec){
        var sec=milisec/1000;
        var h=Math.floor(sec/3600)
        var m=Math.floor((sec-h*3600)/60)
        var s=sec-h*3600-m*60

        // console.log(h+":"+m+":"+s)
        this.setState({
            hrs:h,
            min:m,
            sec:s
        })
    }

    onDateChange = date => {
        this.setState({dateRelease:date})
    }

    b64toBlob = (b64DataStr, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64DataStr);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    onDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            pictures: pictureFiles[0]
        });
        
        var blob = '';
        try {
            const base64Str = pictureDataURLs[0];
            const s = base64Str.split(',');
            blob = this.b64toBlob(s[1]);
            this.setState({foto: blob, image: pictureFiles[0].name});
        } catch(error) {
            alert('Ocurrió un error al subir la foto del curso, favor de intentarlo más tarde');
            console.log(error);
        }
    }

    handleFile = (e) => {
        let file = e.target.files[0];
        if(!this.state.files.some(x => x === file.name)) {
            try {
                let reader = new FileReader();
    
                reader.readAsBinaryString(file);
                reader.onloadend = () => {
                    if (reader.readyState === 2) {
                        if (Number(file.size) > 26214400) {
                            this.setState({fileMsg: "El archivo excede los 25 Mb, intenta con otro.", fileError: true})
                        } else {
                            this.setState({fileMsg: file.name + ", tamaño de archivo: " + (file.size / (1000 * 1000)).toFixed(2) + "Mb", fileError: false})
                            this.setState({fileName: file.name, file: file});
                            // this.setState({file: file});
                        }
                      }
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            this.setState({fileMsg: 'Ya existe un archivo con el mismo nombre, cambie el nombre o seleccione otro archivo', fileError: true})
        }
    }

    downloadFile = async () => {
        try {
            if(this.state.files.some(x => x === this.state.archivoAdjunto)){
                axios({
                    url: '/api/courses/downloadFile',
                    method: 'GET',
                    responseType: 'blob', // important
                    params: {
                        fileName: this.state.archivoAdjunto
                    }
                  }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.classList.add('d-none', 'p-0', 'm-0');
                    link.setAttribute('download', this.state.archivoAdjunto);
                    document.body.appendChild(link);
                    link.click();
                  });
            }
        } catch (err) {
            alert('Ocurrió un error al descargar el archivo del curso, inténtelo más tarde');
        }
    }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            alert('Los precios deben contener sólo valores numéricos, sin letras ni símbolos');
            console.log(e)
        }
    };

    stripeFee = (amount) => {
        const stripeFee = Math.round((amount * 0.036 + 3) * 100) / 100;
        const stripeTax = Math.round((stripeFee * 0.16) * 100) / 100;
        return Math.round((amount - stripeFee - stripeTax) * 100) / 100;
    }

    comexcolAmount = (amount) => {
        const result = Math.round((amount + 3.48)/0.95824 * 100) / 100
        return result
    }

    onBlurHandler = (e) => {
        switch (e.target.name) {
            case 'publicoEnGeneral':
                this.setState({publicoEnGeneral: `$ ${this.formatMoney(Number(e.target.value.replace(/[^0-9\.]+/g, "")))}`});
                this.setState({stripePublicoEnGeneral: this.formatMoney(this.comexcolAmount(Number(e.target.value.replace(/[^0-9\.]+/g, ""))))});
                break;
            case 'asociados':
                this.setState({asociados: `$ ${this.formatMoney(Number(e.target.value.replace(/[^0-9\.]+/g, "")))}`});
                this.setState({stripeAsociados:this.formatMoney(this.comexcolAmount(Number(e.target.value.replace(/[^0-9\.]+/g, ""))))});
                break;
            case 'apoyoMutuo':
                this.setState({apoyoMutuo: `$ ${this.formatMoney(Number(e.target.value.replace(/[^0-9\.]+/g, "")))}`});
                this.setState({stripeApoyoMutuo: this.formatMoney(this.comexcolAmount(Number(e.target.value.replace(/[^0-9\.]+/g, ""))))});
                break;
            case 'estudiantes':
                this.setState({estudiantes: `$ ${this.formatMoney(Number(e.target.value.replace(/[^0-9\.]+/g, "")))}`});
                this.setState({stripeEstudiantes: this.formatMoney(this.comexcolAmount(Number(e.target.value.replace(/[^0-9\.]+/g, ""))))});
                break;
        }
    }

    render() {
        let title = <></>;
        try {
            if(this.props.location.state.editing){
                title = <h3 className='mt-3'><b>Editar curso</b></h3>
            } else {
                title = <h3 className='mt-3'><b>Crear curso</b></h3>
            }
        } catch (err){}

        let imgPreview = <div className="form-row">
                            <div className="form-group col-md-9 col-lg-8 col-xl-6">
                                <label className='text-bold' htmlFor="title">Imagen Actual:</label> <br></br>
                                <img alt='img-preview' className='img-imgfluid' style={{maxWidth: '400px', borderRadius: '15px'}} src={`${process.env.PUBLIC_URL}/images/comexcol-complete.svg`}></img>
                            </div>
                        </div>
        if(this.state.image !== '' && this.state.foto === '') {
            imgPreview = <div className="form-row">
                            <div className="form-group col-md-9 col-lg-8 col-xl-6">
                                <label className='text-bold' htmlFor="title">Imagen Actual:</label> <br></br>
                                <img alt='img-preview' className='img-imgfluid' style={{maxWidth: '400px', borderRadius: '15px'}} src={`${process.env.PUBLIC_URL}/images/${this.state.image}`}></img>
                            </div>
                        </div>
        }
        let spiner = <><Spinner animation="border" variant="light" size="sm" /></>
        let msgFile = <><p className={this.state.fileError ? 'text-danger' : 'text-info'} style={{paddingTop: '4px'}}>{this.state.fileMsg}</p></>
        let actualFile = <></>
        if(this.state.archivoAdjunto !== '' || ((this.state.fileName === this.state.archivoAdjunto ) && this.state.fileName !== '') ) {
            actualFile = <>
                            <div className='d-flex'>
                                <p className='m-0'>Descargar archivo actual: <a style={{textDecorationLine: 'underline', cursor: 'pointer', color: 'blue'}} onClick={this.downloadFile}>{this.state.archivoAdjunto}</a></p>
                            </div></>
        }

        let comexcolAmount = {
            publicoEnGeneral: <p className='h6 my-0'> + comisión de Stripe = <span className='text-bold'>$ {this.state.stripePublicoEnGeneral} MXN </span> (Precio al público)</p>,
            asociados: <p className='h6 my-0'> + comisión de Stripe = <span className='text-bold'>$ {this.state.stripeAsociados} MXN </span> (Precio al público)</p>,
            estudiantes: <p className='h6 my-0'> + comisión de Stripe = <span className='text-bold'>$ {this.state.stripeEstudiantes} MXN </span> (Precio al público)</p>,
            apoyoMutuo: <p className='h6 my-0'> + comisión de Stripe = <span className='text-bold'>$ {this.state.stripeApoyoMutuo} MXN </span> (Precio al público)</p>,
        } 

        let sesionRender = <></>;
        if(this.state.sesionFlag) {
            sesionRender = <Form.Row>
            <Form.Group className={'col-md-9 col-lg-8 col-xl-6'}>
              {/* <Form.Label className='text-bold'>Sesión :</Form.Label> */}
              <DropdownButton
                  drop={'right'}
                  variant='comp'
                  title={`Sesión ${this.state.sesionNumber}`}
                  className='text-bold'
                  disabled
                  >
                    <Dropdown.Item 
                        id='1'
                        onClick={(e) => this.setState({ sesionNumber: e.target.id})}
                    >Sesión 1
                    </Dropdown.Item>

                    <Dropdown.Item 
                        id='2'
                        onClick={(e) => this.setState({ sesionNumber: e.target.id})}
                    >Sesión 2
                    </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          </Form.Row>
        };

        let renderEtiqueta = <></>
        if(this.state.cursoGratuito) {
            renderEtiqueta = <div className="form-row">
                                <div className="form-group col-md-9 col-lg-8 col-xl-6">
                                    <label className='text-bold' htmlFor="etiqueta">Etiqueta del curso:</label>
                                    <input className='form-control' 
                                    required
                                    type="text" 
                                    name="etiqueta" 
                                    onChange={this.onInputChange}
                                    value={this.state.etiqueta}/>
                                </div>
                            </div>
        }

        return (
            <div className="container" style={{ marginTop: "60px" }}>
                <button className='btn btn-info' onClick={() => this.props.history.goBack()}> Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i></button>
                {title}
                <form onSubmit={this.onSubmit}>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label className='text-bold' htmlFor="title">Título del curso:</label>
                            <input className='form-control' 
                            required
                            type="text" 
                            name="title" 
                            onChange={this.onInputChange}
                            value={this.state.title}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" 
                                    name="sesionFlag" 
                                    className="custom-control-input" 
                                    id="sesionFlag" 
                                    onChange={(e) => this.setState({sesionFlag: e.target.checked})} 
                                    checked={this.state.sesionFlag}/>
                                <label className="custom-control-label text-bold" htmlFor="sesionFlag">&nbsp; Este curso será en dos sesiones</label>
                            </div>
                        </div>
                    </div>
                    {sesionRender}
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label className='text-bold' htmlFor='professor'>Impartido por:</label>
                            <input className='form-control' 
                            type="text" 
                            name="professor" 
                            onChange={this.onInputChange}
                            value={this.state.professor}
                            required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group" style={{marginLeft: '5px'}}>
                            <label className='text-bold' htmlFor='puntosNec'> Puntos NEC:</label>
                            <input className='form-control'
                            required
                            type="text"
                            name="puntosNec"
                            maxlength="3" size="3"
                            onChange={this.onInputChange}
                            value={this.state.puntosNec}
                            ></input>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6 mb-0">
                            <label className='text-bold' htmlFor='description'>Descripción del curso:</label>
                            <textarea className='form-control' 
                            required
                            name="description" 
                            rows="5" cols="50"
                            onChange={this.onInputChange}
                            value={this.state.description}
                            ></textarea>
                            <hr></hr>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" 
                                    name="cursoGratuito" 
                                    className="custom-control-input" 
                                    id="cursoGratuito" 
                                    onChange={this.onCheckChangeGratis} 
                                    checked={this.state.cursoGratuito}/>
                                <label className="custom-control-label text-bold" htmlFor="cursoGratuito">&nbsp; ¿Es un curso gratuito?</label>
                            </div>
                        </div>
                    </div>
                    {renderEtiqueta}
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" 
                                    name="exclusivo" 
                                    className="custom-control-input" 
                                    id="exclusivo" 
                                    onChange={(e) => this.setState({exclusivo: e.target.checked})} 
                                    checked={this.state.exclusivo}/>
                                <label className="custom-control-label text-bold" htmlFor="exclusivo">&nbsp; ¿Es un curso exclusivo para Asociados?</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6 my-0">
                            <hr></hr>
                        </div>
                    </div>
                    <p className='mb-1 text-bold'>Precio del curso: </p>
                    <label className='text-bold' htmlFor='asociados'>Asociados $:</label>
                    <div className="form-row">
                        <div className="form-group col-md-3 col-lg-3 col-xl-2">
                            <input className='form-control'
                            type='text'
                            name="asociados"
                            required
                            disabled={this.state.sesion2disabled}
                            onChange={this.onInputChange}
                            onBlur={this.onBlurHandler}
                            value={this.state.asociados}
                            ></input>
                        </div>
                        <div className='col col-md-7 col-lg-7 col-xl-7 mt-md-2'>
                            {comexcolAmount.asociados}
                        </div>
                        <div className='col-md-9 col-lg-8 col-xl-6 my-0'><hr className='mt-2 mt-md-0'></hr></div>
                    </div>
                    <label className='text-bold' htmlFor='apoyoMutuo'>Apoyo Mutuo $:</label>
                    <div className="form-row">
                        <div className="form-group col-md-3 col-lg-3 col-xl-2">
                            <input className='form-control' 
                            type='text'
                            name="apoyoMutuo"
                            required
                            disabled={this.state.sesion2disabled}
                            onChange={this.onInputChange}
                            onBlur={this.onBlurHandler}
                            value={this.state.apoyoMutuo}
                            ></input>
                        </div>
                        <div className='col col-md-7 col-lg-7 col-xl-7 mt-md-2'>
                            {comexcolAmount.apoyoMutuo}
                        </div>
                        <div className='col-md-9 col-lg-8 col-xl-6 my-0'><hr className='mt-2 mt-md-0'></hr></div>
                    </div>
                    <label className='text-bold' htmlFor='estudiantes'>Estudiantes $:</label>
                    <div className='form-row'>
                        <div className="form-group col-md-3 col-lg-3 col-xl-2">
                            <input className='form-control' 
                            type='text'
                            name="estudiantes"
                            required
                            disabled={this.state.sesion2disabled}
                            onChange={this.onInputChange}
                            onBlur={this.onBlurHandler}
                            value={this.state.estudiantes}
                            ></input>
                        </div>
                        <div className='col col-md-7 col-lg-7 col-xl-7 mt-md-2'>
                            {comexcolAmount.estudiantes}
                        </div>
                        <div className='col-md-9 col-lg-8 col-xl-6 my-0'><hr className='mt-2 mt-md-0'></hr></div>
                    </div>
                    <label className='text-bold' htmlFor='publicoEnGeneral'>Público en General $:</label>
                    <div className="form-row">
                        <div className="form-group col-md-3 col-lg-3 col-xl-2">
                            <input className='form-control' 
                            type='text'
                            name="publicoEnGeneral"
                            required
                            disabled={this.state.sesion2disabled}
                            onChange={this.onInputChange}
                            onBlur={this.onBlurHandler}
                            value={this.state.publicoEnGeneral}
                            ></input>
                        </div>
                        <div className='col col-md-7 col-lg-7 col-xl-7 mt-md-2'>
                            {comexcolAmount.publicoEnGeneral}
                        </div>
                        <div className='col-md-9 col-lg-8 col-xl-6 my-0'><hr className='mt-2 mt-md-0'></hr></div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <div className="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" 
                                    name="liveStream" 
                                    className="custom-control-input" 
                                    id="liveStream" 
                                    onChange={this.onCheckChange} 
                                    checked={this.state.liveStream}/>
                                <label className="custom-control-label text-bold" htmlFor="liveStream">&nbsp; ¿Es un streaming en Vivo?</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group py-2">
                        <label className='text-bold' htmlFor='description'>Fecha de emisión: &nbsp;</label>
                         <DatePicker className="form-control"                       
                            selected={this.state.dateRelease}
                            onChange={this.onDateChange}
                            showTimeSelect
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa">
                        </DatePicker>
                     </div>
                     <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label className='text-bold' htmlFor='duracion'>Duración del Curso: (Formato 00h:00m:00s) </label>
                            <div className="row d-flex align-items-center">
                                <div className="col-md-2">
                                    <input className='form-control' 
                                    type="number" 
                                    name="hrs" 
                                    onChange={this.onDurationChange}
                                    value={this.state.hrs}
                                    /> 
                                    </div>: <div className="col-md-2">
                                    <input className='form-control' 
                                    type="number" 
                                    name="min" 
                                    onChange={this.onDurationChange}
                                    value={this.state.min}
                                    /> 
                                    </div> :<div className="col-md-2">
                                    <input className='form-control' 
                                    type="number" 
                                    name="sec" 
                                    onChange={this.onDurationChange}
                                    value={this.state.sec}
                                    />
                                  </div>
                            </div>
                            <hr></hr>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label className='text-bold' htmlFor='videoURL'>URL del Video:</label>
                            <input className='form-control' 
                            type="text" 
                            name="videoURL" 
                            required
                            onChange={this.onInputChange}
                            value={this.state.videoURL}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label className='text-bold' htmlFor='comentario'>Comentario:</label>
                            <textarea className='form-control' 
                            name="comentario" 
                            rows="5" cols="50"
                            onChange={this.onInputChange}
                            value={this.state.comentario}
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label className='text-bold'>Archivo adjunto: </label>
                            <div className="custom-file">
                                <input onChange={this.handleFile} type="file" name='my-file' className="custom-file-input" id="my-file" aria-describedby="inputGroupFileAddon01" />
                                <label className="custom-file-label" htmlFor="my-file">Seleccione un archivo para el curso ...</label>
                            </div>
                            {msgFile}
                            {actualFile}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label className='text-bold' htmlFor='temario'>Temario:</label>
                            <textarea className='form-control' 
                            name="temario" 
                            rows="8" cols="50"
                            onChange={this.onInputChange}
                            value={this.state.temario}
                            ></textarea>
                        </div>
                    </div>
                    {/* <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                            <label htmlFor='image'>URL de la Imagen:</label>
                            <input className='form-control'
                            type="text"
                            name="image"
                            onChange={this.onInputChange}
                            value={this.state.image}
                            />
                        </div>
                    </div> */}
                    <div className="form-row">
                        <div className="form-group col-md-9 col-lg-8 col-xl-6">
                        <label className='text-bold' htmlFor='image'>Imagen del Curso: </label>
                            <ImageUploader
                                withIcon={true}
                                singleImage={true}
                                withPreview={true}
                                buttonText="Escoge una foto"
                                onChange={this.onDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                                maxFileSize={5242880}
                            />
                        </div>
                    </div>
                    {imgPreview}
                    <Button variant='comp' type='submit'>Guardar &nbsp; <i className="far fa-check-circle"></i> &nbsp;{this.state.success ? '' : spiner}</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant='err' onClick={() => this.props.history.push('/admin/cursos')}>Cancelar &nbsp; <i className="far fa-times-circle"></i></Button>
                </form>
                <br></br>
            </div>
        );
    }
}

crearCursos.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps,{ logoutUser })(crearCursos);
