import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Dropdown, DropdownButton, Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker'
import * as moment from 'moment';
import * as locale from 'moment/locale/es-us'
import PropTypes, { elementType } from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { get } from 'mongoose';

function Resumen (props) {

    const [userInfo, setUserInfo] = useState(null);
    const [info, setInfo] = useState(null);
    const [name, setName] = useState('');
    const [noRegistro, setNoRegistro] = useState('');
    const [userType, setUserType ] = useState('Asociado')
    const [noIdentity, setNoIdentity] = useState('');
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(Date.now());
    const [infoRender, setInfoRender] = useState(<></>);
    const [userInfo2, setUserInfo2] = useState(null);
    const [noCourses, setNoCourses] = useState(null);
    const [totalCost, setTotalCost] = useState(null);
    const [loadIco, setLoadIco] = useState(<></>);
    const [puntosN,setPuntosN] = useState(0)
    const [result, setResult] = useState(null)
    const [resRend, setResRend] = useState(<></>)
    const [state,setState] = useState(false)
    const [flag,setFlag] = useState(false)

    const getUserInfo2 = async(id) => {
        try {
            if(id) {
                const res = await axios.get(`/api/users/${id}/userDetails`);
                if(res.status === 200) {
                    setUserInfo2(res.data);
                }
            }
        } catch(err) {
            setLoadIco(<> </>);
        }
    }

    const points = async(infor,id) => {
        console.log(infor)
        let count=0

        const res = await axios.get(`/api/users/${id}/userDetails`);
        const info = res.data.courses
        
        infor.map(item =>{
            const result = info.filter(i => i.id === item.course._id)
            console.log(result[0].completed)
            if(result[0].completed=== true){
                count = count + item.puntosNec
            } 
        })
        setPuntosN(count)
    }

    const GetInfo = async(id) => {
        try {
            if(id) {
                const res = await axios.post(`/api/payment/${id}/findUserPaymentsDate`, {'fechaInicio': fechaInicio, 'fechaFin': fechaFin})
                if(res.status === 200) {
                    setInfo(res.data);
                    mapeo(res.data);
                    points(res.data,id)
                }
            }
        } catch (err) {
            setLoadIco(<> </>);
            console.log(err);
        } 
    }

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/home");
        }
    })

    useEffect(() => {
        if(userInfo !== null && userInfo._id) {
            GetInfo(userInfo._id);
        }
    }, [userInfo])

    const localDate = (date) => {
        const localLocale = moment(date);
        moment.locale('es-us');
        localLocale.locale(false);
        const fecha = localLocale.format('LLLL');
        return fecha;
    }

    useEffect(() => {
        if(userInfo !== null) {
            getUserInfo2(userInfo._id);
        }
    }, [userInfo])

    const mapeo = (info) => {
        let cont = 0
        
        setNoCourses(info.length)
        info.filter(item => item.freeCourse === false).map( item => (
            cont = cont + Number(item.precio)
        ))
        setTotalCost(cont)    
    }

    const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }
    

    useEffect(() => {
        if(info !== null && userInfo2 !== null && userInfo2._id) {
            setInfoRender(<div>
                <p className=' h4 color-prim text-bold text-center'>Resumen</p>
                {flag ? <Button className='btn btn-info mb-2' onClick={clic}>
                        <i className="fas fa-arrow-circle-left"></i>
                    </Button>:<>{setFlag(false)}</>}
                <Row className='d-flex justify-content-center my-2'>
                    <Col lg='6'>
                        <Card >
                                <Card.Header className='cardH text-center' >
                                    <p className='h5 text-bold'>{userInfo2.name}</p>
                                    <p className='h6 text-bold'>{userInfo2.email}</p>
                                    <p className='h6'>{`${moment(fechaInicio).format('LL')} - ${moment(fechaFin).format('LL')}`}</p>
                                </Card.Header>
                                <Card.Body className='cardB text-center'>
                                    <p className='my-0'>Tipo de usuario: <span className='text-bold'>{userInfo2.userType.type}</span></p>
                                    <p className='my-0'>No. Asociado: <span className='text-bold'>{userInfo2.userType.noIdentity}</span></p>
                                    <p className='my-0'>No. Registro: <span className='text-bold'>{userInfo2.noRegistro}</span></p>
                                    <p className='my-0'>Estatus: <span className='text-bold'>{userInfo2.status ? 'Activo' : 'Deshabilitado'}</span></p>
                                    <p className='my-0'>Total de Cursos: <span className='text-bold'>{noCourses}</span></p>
                                    <p className='my-0'>Total pagado: <span className='text-bold'>{`$ ${formatMoney(totalCost)} MNX`}</span></p>
                                    <p className='my-0'>Total de puntos NEC: <span className='text-bold'>{puntosN}</span></p>
                                </Card.Body>
                                <Card.Footer className='cardF'>
                                </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <p className=' h4 color-prim text-bold text-center my-3'>Lista de cursos</p>
                <p>Cursos gratuitos <span style={{ background: '#24a4d133', border: 'solid 1px black', borderRadius: '5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                <p>Cursos pagados <span style={{ background: '#ff825033', border: 'solid 1px black', borderRadius: '5px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                {info.slice(0).reverse().map(pay => (
                    <li key={pay._id} className="list-group-item list-group-item-action" style={{color: 'black', background: pay.freeCourse ? '#24a4d133' : '#ff825033'}}>
                        <Row>
                            <Col>
                                <p className='h5'>Titulo: <span className='text-bold'>{pay.course.title}</span></p>
                                <p className='h6'>puntosNec: <span className='text-bold'>{pay.puntosNec}</span></p>
                                <p className='h6'>RFC de compra: <span className='text-bold'>{pay.rfc}</span></p>
                                <p className='h6'>Tipo de usuario: <span className='text-bold'>{pay.userType}</span></p>
                                <p className='h6'>No. Asociado: <span className='text-bold'>{pay.user.userType.noIdentity}</span></p>
                            </Col>
                            <Col>
                            <p className='h5'>Cumplimiento: <span className='text-bold' style={userInfo2.courses.find(d => d.id === pay.course._id).completed ? 
                                    {color: 'green'} : {color: 'red'}}>{userInfo2.courses.find(d => d.id === pay.course._id).completed ? 'Si' : 'No'}</span></p>
                                <p className='h6'>Fecha de compra: <span className='text-bold'>{localDate(pay.date)}</span></p>
                                <p className='h6'>Precio: <span className='text-bold'>{`$ ${formatMoney(pay.precio)} MNX`}</span></p>
                                <p className='h6'>Curso gratis: <span className='text-bold'>{pay.freeCourse ? 'Si' : 'No'}</span></p>
                            </Col>
                        </Row>
                    </li>
                ))}
                <hr/>
            </div>)
            setLoadIco(<> </>);
        }
    }, [userInfo2, info, noCourses, totalCost,puntosN])

    const handleSubmit = async (e) => {
        setLoadIco(<Spinner style={{transform: 'scale(1.25)'}} animation="grow" variant='prim' />);
        e.preventDefault();
        try {
            // const res = await axios.post('/api/users/userNames',{'name':name})
            const res = await axios.post('/api/users/searchUser', {'userType': {'type': userType, 'noIdentity': noIdentity}, 'noRegistro': noRegistro, 'name': name})
            if(res.status === 200) {
                if(res.data.length > 0){
                    setResult(res.data);
                } else {
                    setResRend(<></>);
                    setName('');
                    setNoRegistro('');
                    setUserType('Asociado');
                    setNoIdentity('');
                    alert('No se encontró el usuario especificado, verifique los datos e intente de nuevo');
                }
            }
            // const res = await axios.post('/api/users/searchUser', {'userType': {'type': userType, 'noIdentity': noIdentity}, 'noRegistro': noRegistro, 'name': name})

            // if(res.status === 200) {
            //     setFlag(false)
            //     setState(true)
            //     setUserInfo(res.data);
            //     setResult(res.data);
            //     setLoadIco(<></>);
            // } else {
            //     setUserInfo(null);
            // }
        } catch (err) {
            console.log(err)
            setUserInfo(null);
            setLoadIco(<> </>);
            setState(false)
            // const resp = await axios.post('/api/users/userNames',{'name':name})
            // if(resp.data.length > 0){
            //     setResult(resp.data)
            // } else{
            //     setResRend(<></>)
            //     setName('')
            //     setNoRegistro('')
            //     setUserType('Asociado')
            //     setNoIdentity('')
            //     alert('No se encontró el usuario especificado, verifique los datos e intente de nuevo');
            // }
                
        }
    }

    const onSubmit = (user)=>{
        
        setInfo(null)
        setUserInfo2(null)
        setUserType(user.userType.type)
        setName(user.name)
        setNoRegistro(user.noRegistro)
        GetInfo(user._id)
        getUserInfo2(user._id)
        setState(true)
    }

    useEffect(() => {
        if(result !== null && state===false){
            setFlag(true)
            setResRend(
                <div>
                    <h3 className='text-bold my-2 color-prim'>Resultados: </h3>
                    {result.map(item => (
                        <li
                            style={{background: item.status ? '' : '#f8523e55'}}
                            className="list-group-item list-group-item-action"
                            key={item._id}>
                                <Row>
                                    <Col md='8'>
                                        <p className='h5 text-bold' style={{color: 'black'}}>{item.name}</p>
                                        <hr className='my-1'></hr>
                                        <p className='h6 my-2'>No. Asociado: <b>{item.userType.noIdentity}</b> &nbsp; Estatus: <b className={item.status ? 'text-success' : 'text-danger'}>{item.status ? 'Activo' : 'Suspendido'}</b></p>
                                        <p className='h6 my-1'>No. Registro: {item.noRegistro}</p>
                                        <p className='h6 my-0'>Tipo de usuario: {item.userType.type}</p>
                                    </Col>
                                    <Col md='4'>
                                        <Button id={item._id} className='btn btn-comp my-2' variant='comp' onClick={() => onSubmit(item)}>
                                            Seleccionar &nbsp; <i className="far fa-id-card"></i>
                                        </Button> &nbsp;
                                    </Col>
                                </Row>
                        </li>
                    ))}
                </div>
            )
            setLoadIco(<> </>);
        }
    }, [result,state])

    const clic = (e) =>{
        e.preventDefault()
        setLoadIco(<Spinner style={{transform: 'scale(1.25)'}} animation="grow" variant='prim' />);
        setState(false)
        setFlag(false)
        setInfoRender(<></>)
    }
    
    return(
        <div className='container' style={{marginTop: '60px'}}>

            <Link className='btn btn-info' to={{ pathname: '/admin' }}>
                Regresar &nbsp; <i className="fas fa-arrow-circle-left"></i>
            </Link>

            <h2 className='color-prim text-bold text-center my-3'>Reportes y Resumenes</h2>
            <hr></hr>

            <Form onSubmit={handleSubmit} className="form">
                <Form.Row className='d-flex justify-content-center'>
                    <Form.Group as={Col} md='2'>
                    <Form.Label className='text-bold'>Tipo de usuario:</Form.Label>
                    <DropdownButton
                        drop={'right'}
                        variant='sec'
                        title={userType}>
                        <Dropdown.Item 
                            id='Asociado'
                            onClick={(e) => setUserType(e.target.id)}
                        >Asociado</Dropdown.Item>
                        <Dropdown.Item 
                            id='Apoyo Mutuo'
                            onClick={(e) => setUserType(e.target.id)}
                        >Apoyo Mutuo</Dropdown.Item>
                        <Dropdown.Item 
                            id='Estudiante'
                            onClick={(e) => setUserType(e.target.id)}
                        >Estudiante</Dropdown.Item>
                        <Dropdown.Item 
                            id='Público en General'
                            onClick={(e) => setUserType(e.target.id)}
                        >Público en General</Dropdown.Item>
                    </DropdownButton>
                    </Form.Group>
                    <Form.Group as={Col} md='3'>
                        <Form.Label className='text-bold'>Nombre Completo:</Form.Label>
                        <Form.Control
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            type="text"
                            placeholder={`Nombre Apellidos`}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md='3'>
                        <Form.Label className='text-bold'>No. Registro:</Form.Label>
                        <Form.Control
                            onChange={(e) => setNoRegistro(e.target.value)}
                            value={noRegistro}
                            type="text"
                            placeholder={`RFC, IMSS, INFONAVIT, CDMX, GOBEDOMEX`}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md='3'>
                        <Form.Label className='text-bold'>No. Asociado:</Form.Label>
                        <Form.Control
                            onChange={(e) => setNoIdentity(e.target.value)}
                            value={noIdentity}
                            type="text"
                            placeholder={`...`} 
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row className="form">
                    <Form.Group as={Col} md='3'>
                        <Form.Label className='text-bold'>Fecha inicial:</Form.Label>
                        <DatePicker className="form-control"                       
                            onChange={(date) => setFechaInicio(date)}
                            selected={fechaInicio}
                            dateFormat="MMMM d, yyyy">
                        </DatePicker>
                    </Form.Group>
                    <Form.Group as={Col} md='3'>
                        <Form.Label className='text-bold'>Fecha final:</Form.Label>
                        <DatePicker className="form-control"                       
                            onChange={(date) => setFechaFin(date)}
                            selected={fechaFin}
                            dateFormat="MMMM d, yyyy">
                        </DatePicker>
                    </Form.Group>
                </Form.Row>
                <div className='text-center'><Button className='px-4' variant='comp' type='submit'>Buscar cursos del usuario &nbsp; <i className='fas fa-search'></i></Button></div>
            </Form>
            <hr/>
            <div className='d-flex justify-content-center'>
            {loadIco}
            </div>
            {state ? infoRender : resRend}
            <br></br>
        </div>
    )
}

Resumen.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(Resumen);